import AcquisitionGrid from "./AcquisitionGrid";
import AcquisitionEditForm from "./edit/AcquisitionEditForm";
import DepreciationGrid from "../Acquisitions/depreciations/DepreciationGrid";

export default {
    routeProps: {
        path: '/acquisitions',
        exact: true,
        component: AcquisitionGrid,
    },
    name: 'Anskaffningar',
    icon: 'pi pi-fw pi-briefcase',
    subModules: [{
        routeProps: {
            path: '/acquisitions/:id',
            exact: true,
            component: AcquisitionEditForm
        }
    }, {
        routeProps: {
            path: '/acquisitions/:id/depreciations',
            exact: true,
            component: DepreciationGrid
        }
    }],
};