import AcquisitiontypeGrid from "./AcquisitiontypeGrid";
import AcquisitiontypeEditForm from "./AcquisitiontypeEditForm";

export default {
    routeProps: {
        path: '/acquisitiontypes',
        exact: true,
        component: AcquisitiontypeGrid,
    },
    name: 'Anskaffningstyper',
    nameSingular: 'Anskaffningstyp',
    showHeadline: true,
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/acquisitiontypes/:id',
            exact: true,
            component: AcquisitiontypeEditForm,
        }
    }],
};