import React, {useState, useEffect, useContext} from 'react';
import {GridTemplates} from "../../GridTemplates";
import {DataTable} from "primereact/datatable";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Column} from "primereact/column";
import {ActiveFilter} from "../../components/filters/ActiveFilter";
import {InputText} from "primereact/inputtext";
import AppContext from "../../AppContext";
import {Dropdown} from "primereact/dropdown";
import SearchUtils from "../../utils/SearchUtils";
import NetUtils from "../../utils/NetUtils";
import ExtraMenu from "../../components/Grid/ExtraMenu";
import RouterUtils from "../../utils/RouterUtils";
import {Button} from "primereact/button";
import FinancingService from "./FinancingService";
import {dynamicSort} from "../../utils/SortUtils";
import {NumberUtils} from "../../utils/NumberUtils";

const FinancingGrid = props => {
    const NAME = 'FinancingGrid'
    const context = useContext(AppContext);
    const [filter, setFilter] = useState({
        active: context.getFilter(NAME, 'active', 1),
        search: context.getFilter(NAME, 'search', '')
    });
    const [list, setList] = useState([]);

    const matchActive = customer => filter.active === null || customer.active === filter.active;
    const matchSearch = customer => SearchUtils.isMatching(customer, filter.search);

    const filteredList = list.filter(c => matchActive(c) && matchSearch(c))

    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(filter);
    }

    const onRowSelect = model => RouterUtils.navigateTo(props, '/financing/' + model.id);


    const sumByField = field => filteredList.reduce((sum, a) => sum += !isNaN(a[field]) ? a[field] : 0, 0);
    const totalPaymentInstalment = sumByField('paymentInstalment');
    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={'Totalt (' + filteredList.length + ' st)'}
                    style={{textAlign: 'left'}}/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer={NumberUtils.formatCurrency(totalPaymentInstalment)} style={{textAlign: 'right'}}/>
        </Row>
    </ColumnGroup>;


    useEffect(() => {
        FinancingService.findByCompany(context.currentCompany.id).then(res => {

            res.forEach(f => {
                f.priceSek = f.price * f.startExchangeRate;
            })
            // "typeString", "financierString"
            res.sort(dynamicSort('financierString'))
            res.sort(dynamicSort('typeString'))
            setList(res)
        });
    }, [context.currentCompany.id])

    return <div className="Grid">

        <div className="filter">
            <div>
                <label className="label">Status</label>
                <Dropdown
                    value={filter.active}
                    options={ActiveFilter.statusList}
                    onChange={e => updateFilter('active', e.value)}
                    showClear={true}
                    placeholder='Alla'
                    style={{width: '90px'}}/>
            </div>

            <div style={{marginLeft: '50px'}}>
                <label className="label">&nbsp;</label>
                <Button label='Lägg till finansiering'
                        icon="pi pi-plus"
                        onClick={() => RouterUtils.navigateTo(props, '/financing/0')}/>
            </div>


            <div style={{flexGrow: 1, padding: 0}}></div>

            <div>
                <label className="label">Sök</label>
                <InputText className="search" type="search" defaultValue={filter.search}
                           onInput={(e) => updateFilter('search', e.target.value)}/>
            </div>

            <div style={{paddingRight: 0}}>
                <label className="label">&nbsp;</label>
                <ExtraMenu list={filteredList}
                           onExportToExcel={() => NetUtils.exportToExcel('Kunder', [
                               {field: 'typeString', label: 'Typ'},
                               {field: 'financierString', label: 'Finansiär'},
                               {field: 'number', label: 'Referens'},
                               {field: 'showInGraph', label: 'Visa i översikt'},
                               {field: 'paymentplanString', label: 'Betalningsplan'},
                               {field: 'acquisitionString', label: 'Anskaffning'},
                               {field: 'lastPaymentPeriodDate', label: 'Slutbetald'},
                               {field: 'priceSek', label: 'Belopp SEK'},
                               {field: 'startExchangeRate', label: 'Valutakurs'},
                               {field: 'dept', label: 'Restskuld'},
                               {field: 'paymentInstalment', label: 'Avbetalning'}
                           ], filteredList)}/>
            </div>
        </div>

        <DataTable className="dataTable"
                   value={filteredList}
                   resizableColumns={true}
                   columnResizeMode="expand"
                   paginator={false}
                   footerColumnGroup={footerGroup}
                   selectionMode="single"
                   onRowSelect={e => onRowSelect(e.data)}
                   scrollable={true} scrollHeight="calc(100vh - 240px)"
        >
            <Column field='typeString' header='Typ' sortable={true}
                    style={{width: '100px'}}/>
            <Column field='financierString' header='Finansiär' sortable={true}/>
            <Column field='number' header='Referens' sortable={true}/>
            <Column field='showInGraph' header='Visa i översikt' sortable={true} body={GridTemplates.boolean}
                    style={{width: '120px'}}/>
            <Column field='paymentplanString' header='Betalningsplan' sortable={true}
                    style={{width: '120px'}}/>
            <Column field='acquisitionString' header='Anskaffning' sortable={true}/>
            <Column field='lastPaymentPeriodDate' header='Slutbetald' sortable={true}
                    style={{width: '100px'}}/>
            <Column field='priceSek' header='Belopp SEK' sortable={true} body={GridTemplates.currency}
                    style={{width: '120px', textAlign: 'right'}}/>
            <Column field='startExchangeRate' header='Valutakurs' sortable={true}
                    body={GridTemplates.currencyTwoDecimals}
                    style={{width: '100px', textAlign: 'right'}}/>
            <Column field='dept' header='Restskuld' sortable={true} body={GridTemplates.currency}
                    style={{width: '120px', textAlign: 'right'}}/>
            <Column field='paymentInstalment' header='Avbetalning' sortable={true} body={GridTemplates.currency}
                    style={{width: '120px', textAlign: 'right'}}/>
        </DataTable>
    </div>
}

export default FinancingGrid;
