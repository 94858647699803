import React from 'react';
import {Dialog} from "primereact/dialog";
import {useForm, Controller} from "react-hook-form";
import ErrorText from "../../../components/EditForm/ErrorText";
import ValidateUtils from "../../../utils/ValidateUtils";
import OptionalDate from "../../../components/OptionalDate/OptionalDate";
import Financingtype from "../../Financing/Financingtype";

const AcquisitionfinancingEditFormDialog = props => {
    const model = props.model;
    const financingList = props.financingList;
    const disabled = false;

    const {register, errors, handleSubmit, formState, control, watch} = useForm({
        defaultValues: model
    });
    const {isSubmitting} = formState;

    const onSubmit = data => {
        data.financingtype = Number(data.financingtype);
        data.financing = Number(data.financing);
        Object.assign(model, data);
        props.onSave(model);
    }

    const watchFinancingtype = watch("financingtype", model.financingtype);

    const onRemove = () => props.onRemove(model);

    return <Dialog className='dialog'
                   header="Finansiering"
                   visible={props.visible}
                   onHide={props.onHide}>

        <div className="EditForm">
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="formItem">
                    <label htmlFor='financingtype'>Finansieringstyp</label>
                    <select name="financingtype"
                            ref={register()}
                            disabled={disabled}>
                        {Financingtype.list.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                    </select>
                </div>

                <div className="formItem">
                    <label htmlFor='financing'>Finansiering</label>
                    <select name="financing"
                            ref={register()}
                            disabled={disabled}
                            style={{width: '300px'}}>
                        <option value='0'>--- Ingen ---</option>
                        {financingList
                            .filter(f => f.type === Number(watchFinancingtype))
                            .map(c => <option key={c.id}
                                              value={c.id}>{c.financierString + ' - ' + c.number}</option>)}
                    </select>
                </div>

                <div className="formItem">
                    <label htmlFor='startDate'>Startdatum</label>
                    <input type="date" name="startDate"
                           ref={register({
                               pattern: ValidateUtils.datePattern()
                           })}
                           disabled={disabled}
                    />
                    <ErrorText errors={errors} name="startDate"/>
                </div>
                <div className="formItem">
                    <label htmlFor='endDate'>Slutdatum</label>
                    <Controller
                        as={<OptionalDate/>}
                        name="endDate"
                        control={control}
                        defaultValue={model.endDate}
                    />
                    <ErrorText errors={errors} name="endDate"/>
                </div>

                <div className="formItem">
                    <label htmlFor='note'>Anteckning</label>
                    <input name="note"
                           ref={register({
                               maxLength: ValidateUtils.maxLength(255)
                           })}
                           style={{width: '300px'}}
                           disabled={disabled}
                    />
                    <ErrorText errors={errors} name="note"/>
                </div>

                <div className="ui-dialog-buttonpane p-clearfix button-row">
                    <div className='left-buttons'></div>
                    <div className='divider'></div>

                    <div className='right-buttons'>
                        {!disabled && <input type='submit' value='Spara' disabled={isSubmitting} className='save-btn'/>}
                        {
                            !disabled && !model.newPrice &&
                            <input type='button' value='Ta bort' disabled={isSubmitting} className='remove-btn'
                                   onClick={e => onRemove()}/>
                        }
                        <input type='button' value='Avbryt' onClick={e => props.onHide()}/>
                    </div>
                </div>
            </form>
        </div>

    </Dialog>
}

export default AcquisitionfinancingEditFormDialog;
