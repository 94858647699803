export default {
    transformToArray: obj => {
        let result = !obj ? [] : (Array.isArray(obj) ? obj : [obj])
        return result;
    },

    sum: (arr, field) => arr.reduce((sum, item) => sum + item[field], 0),
    pushArray: (arr, items) => items.forEach(item => arr.push(item)),

    sortOnField: (array, field) => array.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0)),

    findById: (arr, id) => {
        const _id = Number(id);
        return arr.find(c => c.id === _id);
    },
    findByObject: (arr, obj) => {
        return arr.find(c => c === obj);
    },
}
