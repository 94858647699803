import React from 'react';
import {Checkbox} from 'primereact/checkbox';
import './OptionalDate.scss';
import moment from "moment";

const OptionalDate = props => {
    const checked = props.value.length !== 0;
    const date = props.value;

    const onCheckedChange = e => {
        if (e.target.checked) {
            props.onChange(moment().format('YYYY-MM-DD'));
        } else {
            props.onChange('');
        }
    }

    const onDateChange = e => {
        props.onChange(e.target.value);
    }

    return (
        <div className='OptionalDate'>
            <Checkbox onChange={onCheckedChange} checked={checked}
                      className="checkbox"></Checkbox>
            <input type="date" name="startDate"
                   defaultValue={date}
                   disabled={!checked}
                   onChange={onDateChange}
            />
        </div>
    )
}
export default OptionalDate;
