import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {DataTable} from "primereact/datatable";
import {Dialog} from "primereact/dialog";
import {Row} from "primereact/row";
import React from "react";
import ExtraMenu from "../../components/Grid/ExtraMenu";
import {GridTemplates} from "../../GridTemplates";
import ArrayUtils from "../../utils/ArrayUtils";
import NetUtils from "../../utils/NetUtils";
import {NumberUtils} from "../../utils/NumberUtils";

export default function AssetfinancingDistributionDetails(props) {
    const {
        yearMonth,
        model
    } = props

    const list = model.AcquisitionInfo

    console.log("model", model)

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={'Totalt (' + list.length + ' st)'}
                    style={{textAlign: 'left'}}/>
            <Column footer={NumberUtils.formatCurrency(ArrayUtils.sum(list, 'listPriceValue'))}
                    style={{textAlign: 'right'}}/>
            {/*<Column footer={NumberUtils.formatCurrency(ArrayUtils.sum(list, 'marketValue'))}*/}
            {/*        style={{textAlign: 'right'}}/>*/}
            {/*<Column footer=''/>*/}
            {/*<Column footer=''/>*/}
            {/*<Column footer={NumberUtils.formatCurrency(ArrayUtils.sum(list, 'bookkeepingValue'))}*/}
            {/*        style={{textAlign: 'right'}}/>*/}
            {/*<Column footer={NumberUtils.formatCurrency(ArrayUtils.sum(list, 'debt'))} style={{textAlign: 'right'}}/>*/}
            {/*<Column footer=''/>*/}
            {/*<Column footer={NumberUtils.formatCurrency(ArrayUtils.sum(list, 'overvalue'))}*/}
            {/*        style={{textAlign: 'right'}}/>*/}
        </Row>
    </ColumnGroup>;

    return <Dialog appendTo={document.getElementById('App')}
                   className='dialog'
                   header='Anskaffningar'
                   visible={true}
                   onHide={props.onHide}
                   style={{width: '800px'}}

    >
        <div className="Grid">

            <div className="filter">
                <div style={{flexGrow: 1, padding: 0}}></div>

                {/*<div>*/}
                {/*    <label className="label">Sök</label>*/}
                {/*    <InputText className="search" type="search" defaultValue={filter.search}*/}
                {/*               onInput={(e) => updateFilter('search', e.target.value)}/>*/}
                {/*</div>*/}

                <div style={{paddingRight: 0}}>
                    <label className="label">&nbsp;</label>
                    <ExtraMenu list={list}
                               onExportToExcel={() => NetUtils.exportToExcel('Anskaffningar-översikt-' + yearMonth, [
                                   {field: 'name', label: 'Namn'},
                                   {field: 'listPriceValue', label: 'Listpris'}
                               ], list)}
                    />
                </div>
            </div>

            <DataTable className="dataTable"
                       value={list}
                       resizableColumns={true}
                       columnResizeMode="expand"
                       paginator={false}
                       footerColumnGroup={footerGroup}
                       selectionMode="single"
                // onRowSelect={e => onRowSelect(e.data)}
                       scrollable={true} scrollHeight="calc(100vh - 400px)"
            >
                <Column field='name' header='Anskaffning'/>
                <Column field='listPriceValue' header='Listpris' body={GridTemplates.currency}
                        style={{textAlign: 'right', width: '110px'}}/>
                {/*<Column field='marketValue' header='Marknadsvärde' body={GridTemplates.currency}*/}
                {/*        style={{textAlign: 'right', width: '110px'}}/>*/}
                {/*<Column field='price' header='Anskaffningspris' body={GridTemplates.currency}*/}
                {/*        style={{textAlign: 'right', width: '110px'}}/>*/}

                {/*<Column field='depreciationValue' header='Ack.avskrivningar'*/}
                {/*        body={row => row.financingtypeString === "bought"*/}
                {/*            ? NumberUtils.formatCurrency(row.price - row.bookkeepingValue)*/}
                {/*            : ''}*/}
                {/*        style={{textAlign: 'right', width: '110px'}}/>*/}

                {/*<Column field='bookkeepingValue' header='Bokfört värde' body={GridTemplates.currency}*/}
                {/*        style={{textAlign: 'right', width: '110px'}}/>*/}
                {/*<Column field='debt' header='Restskuld' body={GridTemplates.currency}*/}
                {/*        style={{textAlign: 'right', width: '110px'}}/>*/}
                {/*<Column field='undepreciatedFirstLeasingRent' header='Oavskriven första leasingavgift'*/}
                {/*        body={GridTemplates.currency} style={{textAlign: 'right', width: '200px'}}/>*/}
                {/*<Column field='overvalue' header='Övervärde' body={GridTemplates.currency}*/}
                {/*        style={{textAlign: 'right', width: '110px'}}/>*/}

            </DataTable>
        </div>

        <div className="EditForm">
            <div className="button-row">
                <div className='left-buttons'></div>
                <div className='divider'></div>

                <div className='right-buttons'>
                    <Button label='Stäng' onClick={props.onHide}/>
                </div>
            </div>
        </div>
    </Dialog>
}