import NetUtils from "../../utils/NetUtils";
import ArrayUtils from "../../utils/ArrayUtils";

export default {
    findByCompany: async companyId => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findByCompany',
        manager: 'DivestmentMgr',
        schema: 'byggletasset',
        postData: '' + companyId
    }).then(res => {
        return ArrayUtils.transformToArray(res.list0.divestment);
    }),

    findById: async id => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findById',
        manager: 'DivestmentMgr',
        schema: 'byggletasset',
        postData: '' + id
    }).then(res => {
        return res.list0.divestment;
    }),

    update: async model => NetUtils.doPostWithParams('/asset/UpdateDivestment', model).then(_res => _res.divestment),


    findRowsByDivestmentId: async divestmentId => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findByDivestment',
        manager: 'DivestmentrowMgr',
        schema: 'byggletasset',
        postData: '' + divestmentId
    }).then(res => {
        return ArrayUtils.transformToArray(res.list0.divestmentrow);
    }),

    findFinancingByDivestmentId: async divestmentId => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findByDivestment',
        manager: 'DivestmentfinancingMgr',
        schema: 'byggletasset',
        postData: '' + divestmentId
    }).then(res => {
        return ArrayUtils.transformToArray(res.list0.divestmentfinancing);
    }),

    getDivestmentrows: async params => NetUtils.doGet('/asset/GetDivestmentrows', params).then(res => {
        return ArrayUtils.transformToArray(res.divestmentrow);
    }),
}