import NetUtils from "../../utils/NetUtils";
import ArrayUtils from "../../utils/ArrayUtils";

export default {
    findByFinancing: async financingId => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findByFinancing',
        manager: 'FinancingappendixMgr',
        schema: 'byggletasset',
        postData: '' + financingId
    }).then(res => {
        return ArrayUtils.transformToArray(res.list0.financingappendix);
    }),

    download: (contentId, filename) => NetUtils.downloadFile('/api/get-content', {id: contentId, filename: filename}),

    update: model => NetUtils.doPostWithParams('/asset/UpdateFinancing122121', model),
}