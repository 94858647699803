import React from 'react';
import {Dialog} from "primereact/dialog";
import {useForm} from "react-hook-form";
import ErrorText from "../../../components/EditForm/ErrorText";
import ValidateUtils from "../../../utils/ValidateUtils";
import moment from "moment";

const InterestRateEditFormDialog = props => {
    const model = props.model;
    const disabled = false;

    model.startDate = moment(model.startTime).format('yyyy-MM-DD');

    const {register, errors, handleSubmit, formState} = useForm({
        defaultValues: model
    });
    const {isSubmitting} = formState;

    const onSubmit = data => {
        data.startTime = moment(data.startDate).valueOf();
        Object.assign(model, data);
        props.onSave(model);
    }

    const onRemove = () => props.onRemove(model);

    return <Dialog className='dialog'
                   header="Listpris"
                   visible={props.visible}
                   onHide={props.onHide}>

        <div className="EditForm">
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="formItem">
                    <label htmlFor='name'>Startdatum</label>
                    <input type="date" name="startDate"
                           ref={register({
                               pattern: ValidateUtils.datePattern()
                           })}
                           disabled={disabled}
                    />
                    <ErrorText errors={errors} name="startDate"/>
                </div>

                <div className="formItem">
                    <label htmlFor='value'>Pris</label>
                    <input type='number' step='any' name="value"
                           ref={register({
                               pattern: ValidateUtils.numberPattern()
                           })}
                           style={{width: '100px'}}
                           disabled={disabled}
                    />
                    <ErrorText errors={errors} name="value"/>
                </div>

                <div className="ui-dialog-buttonpane p-clearfix button-row">
                    <div className='left-buttons'></div>
                    <div className='divider'></div>

                    <div className='right-buttons'>
                        {!disabled && <input type='submit' value='Spara' disabled={isSubmitting} className='save-btn'/>}
                        {
                            !disabled && !model.newPrice &&
                            <input type='button' value='Ta bort' disabled={isSubmitting} className='remove-btn'
                                   onClick={e => onRemove()}/>
                        }
                        <input type='button' value='Avbryt' onClick={e => props.onHide()}/>
                    </div>
                </div>
            </form>
        </div>

    </Dialog>
}

export default InterestRateEditFormDialog;
