import React, {Component} from 'react';
import {Dropdown} from "primereact/dropdown";

export class ActiveFilter extends Component {
    static statusList = [{
        value: 1,
        label: 'Aktiv',
        labelMultiple: 'Aktiva'
    }, {
        value: 0,
        label: "Inaktiv",
        labelMultiple: 'Inaktiva'
    }];

    list = ActiveFilter.statusList.slice();

    constructor(props, context) {
        super()
        if (props.showAll) {
            this.list = [{
                value: 'ALL',
                label: "Alla",
                labelMultiple: 'Alla'
            }].concat(this.list);
        }

        this.state = {
            value: props.value ? props.value : this.list[0].value
        }
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (this.props.value && this.props.value !== prevProps.value && this.props.value !== this.state.statusObj.value) {
    //         let s = this.list.find(s => s.value === this.props.value)
    //         this.setState({statusObj: s});
    //     }
    // }

    static gridTemplate(rowData, column) {
        let value = rowData[column.field];
        let s = ActiveFilter.statusList.find(s => s.value === value);
        return s ? s.label : 'x';
    }

    //
    static filterData(allData, status) {
        let filteredData = []
        if (allData) {
            filteredData = allData.filter(a => {
                switch (status) {
                    case 'ALL':
                        return true;
                    case 'ACTIVE':
                        return a.active;
                    case 'INACTIVE':
                        return !a.active;
                    default:
                        return false;
                }
            });
        }
        return filteredData;
    }

    render() {
        let optionLabel = this.props.multipleLabel ? 'labelMultiple' : 'label';

        return (
            <Dropdown
                value={this.state.value}
                options={this.list}
                optionLabel={optionLabel}
                onChange={e => {
                    this.props.onChange(e);
                    this.setState({value: e.value});
                }}
                required={true}/>
        );
    }
}