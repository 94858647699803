import React, {Component} from 'react';
import './Articletype.scss'
import {Dropdown} from "primereact/dropdown";


export class Articletype extends Component {
    static ASSET = 0;
    static MERCHANDISE = 1;

    static list = [{
        value: Articletype.ASSET,
        label: 'Mängdartikel',
        labelMultiple: 'Mängdartiklar'
    }, {
        value: Articletype.MERCHANDISE,
        label: "Förbrukningsvara",
        labelMultiple: 'Förbrukningsvaror'
    }];

    list = Articletype.list.slice();

    constructor(props, context) {
        super()

        if (props.showAll) {
            this.list = [{
                value: 0,
                label: "Alla",
                labelMultiple: 'Alla'
            }].concat(this.list);
        }

        this.state = {
            value: props.value ? props.value : this.list[0].value
        }
    }

    static gridTemplate(rowData, column) {
        let value = rowData[column.field];
        let s = Articletype.list.find(s => s.value === value);
        return s ? s.label : 'x';
    }

    render() {
        let optionLabel = this.props.multipleLabel ? 'labelMultiple' : 'label';

        return (
            <div className="Articletype">
                <Dropdown
                    value={this.state.value}
                    options={this.list}
                    optionLabel={optionLabel}
                    onChange={e => {
                        this.props.onChange(e);
                        this.setState({value: e.value});
                    }}
                    required={true}/>
            </div>
        );
    }
}