import React, {useContext, useState} from 'react';
import {Dialog} from "primereact/dialog";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import AppContext from "../../AppContext";
import SearchUtils from "../../utils/SearchUtils";
import {Articletype} from "./Articletype";
import {GridTemplates} from "../../GridTemplates";

const SelectArticlesDialog = props => {
    const NAME = 'SelectArticlesDialog';
    const context = useContext(AppContext);

    // const stockQuantityMap = props.stockQuantityMap;

    const [filter, setFilter] = useState({
        type: context.getFilter(NAME, 'type', null),
        category: context.getFilter(NAME, 'category', null),
        search: context.getFilter(NAME, 'search', ''),
        pageFirst: context.getFilter(NAME, 'pageFirst', 0),
        pageRows: context.getFilter(NAME, 'pageRows', 50)
    });

    const articlecategoryList = props.articlecategoryList;
    const rowList = props.rowList;

    const [list, setList] = useState(props.articleList
        .filter(a => a.active
            && !rowList.find(or => or.article === a.id)
        )
        .map(a => Object.assign({quantity: 0}, a)));

    const matchType = article => filter.type === null || article.type === filter.type;
    const matchCategory = a => {
        if (filter.category > 0 && !a.categoryIdList.find(id => id === filter.category)) {
            return false;
        }
        return true;
    };
    const matchSearch = article => SearchUtils.isMatching(article, filter.search);

    const filteredList = list.filter(a => matchType(a) && matchCategory(a) && matchSearch(a))

    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(filter);
    }

    const onQuantityChange = (article, value) => {
        // const stockQuantity = stockQuantityMap.get(article.id);
        // if (value > stockQuantity) {
        //     value = stockQuantity;
        // }
        article.quantity = Number(value);
        setList([...list]);
    }

    const onSave = () => props.onSave(list.filter(a => a.quantity > 0))

    return <Dialog className='dialog'
                   header='Lägg till artiklar'
                   visible={props.visible}
                   onHide={props.onHide}
                   style={{width: '900px'}}>
        <div className="Grid" style={{minHeight: '900px'}}>
            <div className="filter">
                <div>
                    <label className="label">Typ</label>
                    <Dropdown
                        value={filter.type}
                        options={Articletype.list}
                        optionLabel='labelMultiple'
                        onChange={e => updateFilter('type', e.value)}
                        showClear={true}
                        placeholder='Alla'
                        style={{width: '150px'}}/>
                </div>

                <div>
                    <label className="label">Kategori</label>
                    <Dropdown
                        value={filter.category}
                        options={articlecategoryList}
                        optionLabel='name'
                        optionValue='id'
                        onChange={e => updateFilter('category', e.value)}
                        showClear={true}
                        placeholder='Alla'
                        scrollHeight='250px'
                        style={{width: '150px'}}/>
                </div>

                <div style={{flexGrow: 1, padding: 0}}></div>

                <div>
                    <label className="label">Sök</label>
                    <InputText className="search" type="search" defaultValue={filter.search}
                               onInput={(e) => updateFilter('search', e.target.value)}/>
                </div>
            </div>

            <DataTable className="dataTable"
                       value={filteredList}
                       resizableColumns={true}
                       columnResizeMode="expand"
                       scrollable={true}
                       scrollHeight="calc(100vh - 380px)"

                       paginator={true} rows={filter.pageRows} first={filter.pageFirst}
                       onPage={e => {
                           updateFilter('pageFirst', e.first);
                           updateFilter('pageRows', e.rows);
                       }}
                       rowsPerPageOptions={[10, 50, 100, 300, 1000]}
            >
                <Column field='numberAndName' header='Article' sortable={true}
                        body={row => row.number + " - " + row.name}/>
                <Column field='description' header='Beskrivning' sortable={true}/>

                <Column field='quantity' header='Antal' sortable={true} body={GridTemplates.integer}
                        style={{width: '80px', textAlign: 'right'}}
                        editor={e => <InputText type="number" value={e.rowData.quantity !== 0 ? e.rowData.quantity : ''}
                                                onChange={e2 => onQuantityChange(e.rowData, e2.target.value)}
                                                style={{textAlign: 'right'}}/>}
                />
            </DataTable>

            <div style={{textAlign: 'right', paddingTop: '10px'}}>
                <input type='submit' value='Spara' className='save-btn' onClick={e => onSave()}/>
                <input type='button' value='Avbryt' onClick={e => props.onHide()}
                       style={{margin: '0'}}/>
            </div>
        </div>
    </Dialog>
}

export default SelectArticlesDialog;
