import download from "downloadjs";
import {errorAlert} from "../components/Overlay/Alert";

export const SERVER_URL = ''
// export const SERVER_URL = process.env.NODE_ENV !== "production" ? "http://localhost:9998" : "";

const doGet = async (url, params) => {
    if (params) {
        url += '?' + Object.keys(params)
            .filter(key => params[key] !== undefined && params[key] !== null)
            .map(key => key + '=' + params[key]).join('&');
    }

    let resp = await fetch(SERVER_URL + url, {
        method: 'GET', headers: {"Accept": "application/json; charset=utf-8"},
        mode: 'cors',
        credentials: 'include',
    })
    return await resp.ok ? resp.json() : handleError(resp);
}

const doPostWithParams = async (url, params) => {
    params.s = new Date().getTime();

    const formData = Object.keys(params)
        .filter(key => params[key] !== undefined && params[key] !== null)
        .map(key => key + '=' + params[key]).join('&');

    return await fetch(SERVER_URL + url, {
        method: 'POST', headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
            "Accept": "application/json; charset=utf-8"
        },
        mode: 'cors',
        credentials: 'include',
        body: formData
    }).then(async resp => {
        const json = resp.ok ? await resp.json() : handleError(resp)
        if (json.errorMsg) {
            errorAlert(json.errorMsg)
            return false
        } else {
            return json
        }
    })
}

const doPost = async (url, model) => {
    return await fetch(url, {
        method: 'POST', headers: {"Content-Type": "application/json; charset=utf-8"},
        body: model ? JSON.stringify(model) : undefined
    }).then(resp => resp.ok ? resp.json() : handleError(resp));
}


const doDelete = async (url, modelList) => await fetch(url, {
    method: 'DELETE', headers: {"Content-Type": "application/json; charset=utf-8"},
    body: JSON.stringify(modelList)
}).then(resp => resp.ok ? true : handleError(resp));


function b64EncodeUnicode(str) {
    if (!str) return '';

    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

const exportToExcel = (filename, columns, values) => {
    const COL_BREAK = ':';
    const ROW_BREAK = ';';

    let postData = columns.map(col => b64EncodeUnicode(col.label ? col.label : col.header)).join(COL_BREAK) + ROW_BREAK;
    postData += values.map(item => columns.map(col => b64EncodeUnicode(item[col.field])).join(COL_BREAK)).join(ROW_BREAK);

    const params = {
        fileName: filename,
        sheetName: filename,
        company: 10015,
        postData: postData
    }

    const formData = Object.keys(params)
        .filter(key => params[key] !== undefined && params[key] !== null)
        .map(key => key + '=' + params[key]).join('&');


    fetch(SERVER_URL + '/GetExcel', {
        method: 'POST', headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
            "Accept": "application/json; charset=utf-8"
        },
        mode: 'cors',
        credentials: 'include',
        body: formData
    }).then(res => res.blob()).then(blob => {
        download(blob, filename + '.xls');
    });
    ;
}


// const getFilename = response => {
//     const contentDisposition = response.headers.get("content-disposition");
//     return contentDisposition.substring('attachment; filename="'.length, contentDisposition.length - 1)
// }

const getContentType = response => response.headers.get("content-type");

const downloadFile = async (url, params) => {
    if (params) {
        url += '?' + Object.keys(params)
            .filter(key => params[key] !== undefined && params[key] !== null)
            .map(key => key + '=' + params[key]).join('&');

        return await fetch(SERVER_URL + url).then(res => res.blob()
            .then(blob => download(blob, params.filename, getContentType(res)))
        )
    } else {
        return await fetch(SERVER_URL + url).then(res => res.blob()
            .then(blob => download(blob, params.filename, getContentType(res))));
    }
}

// const downloadFile = async (url, params) => {
//     if (params) {
//         const formData = Object.keys(params)
//             .filter(key => params[key] !== undefined && params[key] !== null)
//             .map(key => key + '=' + params[key]).join('&');
//
//         return await fetch(SERVER_URL + url, {
//             method: 'POST', headers: {"Content-Type": "application/x-www-form-urlencoded; charset=utf-8"},
//             body: formData
//         }).then(res => res.blob()
//                 .then(blob => download(blob, params.filename, getContentType(res)))
//             // .then(blob => download(blob, getFilename(res), getContentType(res)))
//         )
//     } else {
//         return await fetch(SERVER_URL + url).then(res => res.blob()
//             .then(blob => download(blob, params.filename, getContentType(res))));
//         // .then(blob => download(blob, getFilename(res), getContentType(res))));
//     }
// }

const upload = async (files) => {
    var formData = new FormData();
    files.map(file => formData.append('file', file, file.name));

    return await fetch(SERVER_URL + '/api/update-content', {
        method: 'POST',
        body: formData,
    }).then(res => res.text())
        .then(res => {
            return Number(res);
        });
}

const handleError = resp => resp.text().then(xml => {
    alert("Okänt fel: " + resp.statusText);
    console.log('Felmeddelande: ', resp, xml)
    return false;
});

// const handleError = resp => {
//     console.log('Felmeddelande: ', resp.statusText)
//     return false;
// }


export default {
    doGet,
    doPost,
    doPostWithParams,
    doDelete,
    exportToExcel,
    downloadFile,
    upload
}

