import fetchIntercept from "fetch-intercept";
import {SERVER_URL} from "../utils/NetUtils";

export class SecurityService {

    static init = () => {
        fetchIntercept.register({
            request: (url, config) => SecurityService.generateRequestPromise(url, config),

            // Called when an error occured during another 'request' interceptor call
            requestError: error => Promise.reject(error),

            // Modify the response object
            response: response => response,

            // Handle an fetch error
            responseError: error => Promise.reject(error)
        });
        console.info("SECURITY: Requests are now secured!")
    }

    static generateRequestPromise = async (url, config) => {
        return this.generateReturnValue(url, config);
    };

    static generateReturnValue = (url, config) => {
        if (!config) config = {};
        if (!config.headers) config.headers = {};


        if (url.indexOf("/Login") === -1) {
            const uid = localStorage.getItem("userUid");
            const cert = localStorage.getItem("userCert");
            const company = localStorage.getItem("company");

            if (url.indexOf("?") === -1) {
                url += '?uid=' + uid + '&cert=' + cert + '&company=' + company;
            } else {
                url += '&uid=' + uid + '&cert=' + cert + '&company=' + company;
            }

        }

        return [url, config];
    }

    static sendNewPassword(username) {
        return fetch(SERVER_URL + '/api/users/send-new-password', {
            // return fetch(SERVER_URL + '/api/foo', {
            method: 'POST',
            headers: {"Content-Type": "application/json; charset=utf-8"},
            body: JSON.stringify({username: username})
        });
    }

    static changePassword(newPassword) {
        return fetch(SERVER_URL + '/api/users/change-password', {
            method: 'POST',
            headers: {"Content-Type": "application/json; charset=utf-8"},
            body: JSON.stringify({newPassword: newPassword})
        });
    }

    static signOut() {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("userUid");
        localStorage.removeItem("userCert");
        localStorage.removeItem("company");
        window.location = '/';
    }
}