import NetUtils from "../../utils/NetUtils";
import ArrayUtils from "../../utils/ArrayUtils";

export default {
    findByCompany: async companyId => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findByCompany',
        manager: 'FinancingMgr',
        schema: 'byggletasset',
        postData: '' + companyId
    }).then(res => {
        return ArrayUtils.transformToArray(res.list0.financing);
    }),

    findById: async id => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findById',
        manager: 'FinancingMgr',
        schema: 'byggletasset',
        postData: '' + id
    }).then(res => {
        return res.list0.financing;
    }),

    update: async model => NetUtils.doPostWithParams('/asset/UpdateFinancing', model).then(_res => _res.financing),


    getTypes: async () => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'getValues',
        manager: 'FinancingtypeMgr',
        schema: 'byggletasset',
        // postData: '' + companyId
    }).then(res => {
        return ArrayUtils.transformToArray(res.list0.financingtype);
    }),


    findPaymentsById: async id => NetUtils.doGet('/asset/GetFinancingPaymentPeriods', {financingId: id}).then(res => {
        return res.paymentPeriod;
    }),

    updatePayment: async model => NetUtils.doPostWithParams('/asset/UpdateFinancingpayment', model).then(_res => _res.financing),
}