import NetUtils from "../../utils/NetUtils";
import ArrayUtils from "../../utils/ArrayUtils";

export default {
    findByAcquisition: async acquisitionId => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findByAcquisition',
        manager: 'AcquisitionappendixMgr',
        schema: 'byggletasset',
        postData: '' + acquisitionId
    }).then(res => {
        return ArrayUtils.transformToArray(res.list0.acquisitionappendix);
    }),

    download: (contentId, filename) => NetUtils.downloadFile('/api/get-content', {id: contentId, filename: filename}),

    update: model => NetUtils.doPostWithParams('/asset/UpdateFinancing122121', model),
}