import React, {useRef} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FileUpload} from "primereact/fileupload";
import NetUtils from "../../../utils/NetUtils";
import AcquisitionappendixService from "../AcquisitionappendixService";

const AppendixGrid = props => {
    const value = props.value;
    const fileUploaderRef = useRef();

    const onDownloadClick = appendix => {
        AcquisitionappendixService.download(appendix.content, appendix.filename);
    }

    const onRemoveClick = appendix => {
        const index = value.indexOf(appendix);
        value.splice(index, 1)
        props.onChange([...value]);
    }

    const uploadFile = e => {
        NetUtils.upload(e.files).then(contentId => {
            const newList = [...value].concat({
                id: 0,
                content: contentId,
                filename: e.files[0].name,
                attach: 0,
                listOrder: value.length
            })
            props.onChange(newList);

            const fileUploader = fileUploaderRef.current;
            fileUploader.clear();
        })
    }


    return <div>
        <DataTable value={value} style={{width: '600px'}}>
            <Column field="download" header=""
                    style={{width: '50px'}}
                    body={row => <button className='icon-btn'
                                         onClick={e => onDownloadClick(row)}>
                        <i className="pi pi-download"></i>
                    </button>
                    }
            />

            <Column field="filename" header="Filnamn" className='alignLeft'
                    body={row => <div>
                        {row.filename}
                        {
                            row.id === 0 &&
                            <span style={{color: 'red'}}> (ej sparad)</span>
                        }
                    </div>
                    }/>

            <Column field="remove" header=""
                    style={{width: '50px'}}
                    body={row => <button className='icon-btn'
                                         onClick={e => onRemoveClick(row)}>
                        <i className="pi pi-times"></i>
                    </button>
                    }
            />
        </DataTable>

        <div style={{height: '5px'}}></div>
        <FileUpload ref={fileUploaderRef} name="files[]" customUpload={true} mode="basic"
                    uploadHandler={uploadFile} auto={true} multiple={false}
                    chooseLabel="Ladda upp..." uploadLabel="Ladda upp"
                    maxFileSize={20000000} />
    </div>
}

export default AppendixGrid;
