import React from 'react';
import ValidateUtils from "../../utils/ValidateUtils";
import ErrorText from "./ErrorText";

const FormItemText = props => {
    const {
        register,
        formState: {errors},
        disabled,
        forceDisabled,
        name,
        label,
        placeholder,
        onKeyUp,

        type,
        autoComplete,
        required,
        maxLength,
        number,
        integer,
        step,
        email,

        width
    } = props

    const validation = {}
    if (required) {
        validation.required = ValidateUtils.required()
    }
    if (maxLength) {
        validation.maxLength = ValidateUtils.maxLength(maxLength)
    }
    if (number) {
        validation.pattern = ValidateUtils.numberPattern()
    }
    if (integer) {
        validation.pattern = ValidateUtils.integerPattern()
    }
    if (email) {
        validation.pattern = ValidateUtils.email()
    }

    const style = {}
    if (width) {
        style.width = width + 'px'
    } else {
        style.width = '100%'
    }

    //{...register(name, validation)}

    return <div className="formItem">
        <label htmlFor={name}>{label}</label>
        <input
            type={type ? type : undefined}
            autoComplete={autoComplete ? autoComplete : undefined}
            name={name}
            ref={register(validation)}
            disabled={disabled || forceDisabled}
            style={style}
            placeholder={placeholder}
            onKeyUp={onKeyUp}
            step={step}
        />
        <ErrorText errors={errors} name={name}/>
    </div>
}
export default FormItemText;