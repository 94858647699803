import moment from "moment";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Row} from "primereact/row";
import React, {useContext, useState} from 'react';
import {withRouter} from 'react-router-dom';
import AppContext from "../../../AppContext";
import ExtraMenu from "../../../components/Grid/ExtraMenu";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import {GridTemplates} from "../../../GridTemplates";
import ArrayUtils from "../../../utils/ArrayUtils";
import DateUtils from "../../../utils/DateUtils";
import NetUtils from "../../../utils/NetUtils";
import {NumberUtils} from "../../../utils/NumberUtils";
import SearchUtils from "../../../utils/SearchUtils";
import useMountEffect from "../../../utils/useMountEffect";
import AcquisitionService from "../../Acquisitions/AcquisitionService";


const AcquisitionListPriceValueReport = props => {
    const NAME = 'AcquisitionListPriceValueReport'
    const context = useContext(AppContext);
    const [filter, setFilter] = useState({
        acquisition: context.getFilter(NAME, 'acquisition'),
        date: context.getFilter(NAME, 'date', moment().format("YYYY-MM-DD")),
        search: context.getFilter(NAME, 'search', '')
    });
    const [acquisitionList, setAcquisitionList] = useState()

    const [list, setList] = useState([]);

    const matchSearch = customer => SearchUtils.isMatching(customer, filter.search);
    const filteredList = list.filter(c => matchSearch(c))


    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(Object.assign({}, filter));
    }

    // const onRowSelect = model => RouterUtils.navigateTo(props, '/financing/' + model.id);
    const onRowSelect = model => {
    };

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={'Totalt (' + filteredList.length + ' st)'}
                    style={{textAlign: 'left'}}/>
            <Column footer={NumberUtils.format(ArrayUtils.sum(filteredList, 'currentFormalQuantity'))}
                    style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.format(ArrayUtils.sum(filteredList, 'listAPriceValue'))}
                    style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.format(ArrayUtils.sum(filteredList, 'listPriceValue'))}
                    style={{textAlign: 'right'}}/>
        </Row>
    </ColumnGroup>

    useMountEffect(() => {
        AcquisitionService.findByCompany(context.currentCompany.id)
            .then(_acquisitionList => {
                _acquisitionList = _acquisitionList.filter(item => item.active)
                ArrayUtils.sortOnField(_acquisitionList, 'name')
                _acquisitionList.forEach((item, index) => item.label = (index + 1) + ': ' + item.name + ' - ' + DateUtils.getDateString(item.acquisitionDate))
                setAcquisitionList(_acquisitionList)
            })
    })

    const loadData = async () => {
        if (!filter.acquisition || !filter.date) return
        showLoading()

        const params = {
            acquisition: filter.acquisition,
            date: filter.date
        }
        const _res = await NetUtils.doGet('/api/reports/acquisition-list-price-value', params)
        setList(_res)
        hideLoading()
    }

    const columns = [
        {field: 'articleNumberAndName', header: 'Artikel'},
        {
            field: 'currentFormalQuantity',
            header: 'Antal (efter avyttringar)',
            style: {width: '150px', textAlign: 'right'},
            body: GridTemplates.integer
        },
        {
            field: 'listAPriceValue',
            header: 'Listpris à-pris',
            style: {width: '150px', textAlign: 'right'},
            body: GridTemplates.integer
        },
        {
            field: 'listPriceValue',
            header: 'Listpris',
            style: {width: '150px', textAlign: 'right'},
            body: GridTemplates.integer
        }
    ]

    // String articleNumberAndName;
    // double currentFormalQuantity;
    // double listAPriceValue;
    // double listPriceValue;

    if (!acquisitionList) return <></>
    return <div className="Grid" style={{padding: '10px 0'}}>

        <div className="filter">
            <div>
                <label className="label">Anskaffning</label>
                <Dropdown
                    value={filter.acquisition}
                    options={acquisitionList}
                    optionLabel='label'
                    optionValue='id'
                    placeholder='Välj...'
                    onChange={e => updateFilter('acquisition', e.value)}
                    style={{width: '300px'}}
                />
            </div>

            <div>
                <label className="label">Datum</label>
                <input type="date" value={filter.date}
                       onChange={e => {
                           updateFilter('date', e.target.value)
                       }}/>
            </div>

            <div>
                <label className="label">&nbsp;</label>
                <input type="button" onClick={() => loadData()} value='Skapa rapport'/>
            </div>


            <div style={{flexGrow: 1, padding: 0}}></div>

            <div>
                <label className="label">Sök</label>
                <InputText className="search" type="search" defaultValue={filter.search}
                           onInput={(e) => updateFilter('search', e.target.value)}/>
            </div>

            <div style={{paddingRight: 0}}>
                <label className="label">&nbsp;</label>
                <ExtraMenu list={filteredList}
                           onExportToExcel={() => NetUtils.exportToExcel('Artiklar per år', columns, filteredList)}/>
            </div>
        </div>

        <DataTable className="dataTable"
                   value={filteredList}
                   resizableColumns={true}
                   columnResizeMode="expand"
                   paginator={false}
                   footerColumnGroup={footerGroup}
                   selectionMode="single"
                   onRowSelect={e => onRowSelect(e.data)}
                   scrollable={true} scrollHeight="calc(100vh - 310px)">
            {
                columns.map(c => <Column key={c.field}
                                         field={c.field}
                                         header={c.header}
                                         style={c.style}
                                         body={c.body}
                                         sortable={true}
                />)
            }
        </DataTable>

    </div>
}

export default withRouter(AcquisitionListPriceValueReport);
