import React from 'react';
import ReactDOM from "react-dom";
import './Loading.scss';
import Spinner from "../Spinner/Spinner";

const Dialog = props => {
    return <Spinner/>
}

let renderContainer;
let referenceCounter = 0;

export function showLoading() {
    referenceCounter += 1;
    // console.log("showLoading", referenceCounter)

    if (referenceCounter === 1) {
        renderContainer = document.createElement('div');
        document.body.appendChild(renderContainer);
        ReactDOM.render(<Dialog/>, renderContainer);
    }
}

export function hideLoading() {
    referenceCounter -= 1;
    // console.log("hideLoading", referenceCounter)

    if (referenceCounter === 0) {
        ReactDOM.unmountComponentAtNode(renderContainer);
        renderContainer.parentNode.removeChild(renderContainer);
    }
}