import React, {useContext, useState, useEffect} from 'react';
import {Dialog} from "primereact/dialog";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {InputText} from "primereact/inputtext";
import AppContext from "../../../AppContext";
import SearchUtils from "../../../utils/SearchUtils";
import {GridTemplates} from "../../../GridTemplates";
import DivestmentService from "../DivestmentService";
import AcquisitionService from "../../Acquisitions/AcquisitionService";
import moment from "moment";
import {dynamicSort} from "../../../utils/SortUtils";

const DivestmentAddFromAcquisitionsDialog = props => {
    const NAME = 'DivestmentSelectArticlesDialog';
    const context = useContext(AppContext);

    const [filter, setFilter] = useState({
        search: context.getFilter(NAME, 'search', ''),
        pageFirst: context.getFilter(NAME, 'pageFirst', 0),
        pageRows: context.getFilter(NAME, 'pageRows', 50)
    });

    const [acquisitionList, setAcquisitionList] = useState([]);
    const [selectedAcquisitions, setSelectedAcquisitions] = useState([]);

    const matchSearch = article => SearchUtils.isMatching(article, filter.search);
    const filteredList = acquisitionList.filter(a => matchSearch(a))

    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(filter);
    }

    const onSave = () => {
        const params = {
            postData: selectedAcquisitions.map(a => a.id).join(';'),
            fetchType: 'acquisitions'
        }
        DivestmentService.getDivestmentrows(params).then(_rows => {
            _rows.sort(dynamicSort('articleString'));
            _rows.sort(dynamicSort('acquisitionString'));
            props.onSave(_rows)
        })

    };


    useEffect(() => {
        AcquisitionService.findByCompany(context.currentCompany.id).then(_acquisitionList => {

            _acquisitionList = _acquisitionList.filter(a => a.active && !a.pledged);
            _acquisitionList.forEach(a => {
                a.acquisitionDateString = moment(a.acquisitionDate).format('YYYY-MM-DD');
                a.quantity = 0
            });
            _acquisitionList.sort(dynamicSort('name'));
            setAcquisitionList(_acquisitionList)
        });
    }, [context.currentCompany.id]);

    return <Dialog className='dialog'
                   header='Lägg till artiklar'
                   visible={props.visible}
                   onHide={props.onHide}
                   style={{width: '1600px'}}>
        <div className="Grid" style={{minHeight: '900px'}}>
            <div className="filter">
                <div style={{flexGrow: 1, padding: 0}}></div>

                <div>
                    <label className="label">Sök</label>
                    <InputText className="search" type="search" defaultValue={filter.search}
                               onInput={(e) => updateFilter('search', e.target.value)}/>
                </div>
            </div>

            <DataTable className="dataTable"
                       value={filteredList}
                       resizableColumns={true}
                       columnResizeMode="expand"
                       scrollable={true}
                       scrollHeight="calc(100vh - 380px)"

                       paginator={true} rows={filter.pageRows} first={filter.pageFirst}
                       onPage={e => {
                           updateFilter('pageFirst', e.first);
                           updateFilter('pageRows', e.rows);
                       }}
                       rowsPerPageOptions={[10, 50, 100, 300, 1000]}
                       selection={selectedAcquisitions} onSelectionChange={e => setSelectedAcquisitions(e.value)}
                       dataKey="id"
            >
                <Column field='name' header='Namn' sortable={true}/>
                <Column field='typeString' header='Typ' style={{width: '140px'}} sortable={true} className='nowrap'/>
                <Column field='supplierString' header='Leverantör' style={{width: '140px'}} sortable={true}
                        className='nowrap'/>
                <Column field='financingString' header='Finansiering' sortable={true}/>
                <Column field='acquisitionDateString' header='Datum' style={{width: '90px'}} sortable={true}/>
                <Column field='bookkeepingId' header='Bokförings-ID' sortable={true}/>

                <Column field='price' header='Pris' sortable={true} body={GridTemplates.integer}
                        style={{width: '110px', textAlign: 'right'}}/>
                <Column field='listPriceValue' header='Listpris' sortable={true} body={GridTemplates.integer}
                        style={{width: '110px', textAlign: 'right'}}/>
                <Column field='marketValue' header='Marknadsvärde' sortable={true} body={GridTemplates.integer}
                        style={{width: '130px', textAlign: 'right'}}/>
                <Column field='bookkeepingValue' header='Bokföringsvärde' sortable={true} body={GridTemplates.integer}
                        style={{width: '130px', textAlign: 'right'}}/>

                <Column selectionMode="multiple" headerStyle={{width: '45px'}}></Column>

                {/*<Column field='fetch' header='' sortable={true} body={GridTemplates.integer}*/}
                {/*        style={{width: '80px', textAlign: 'right'}}*/}
                {/*        editor={e => <InputText type="number" value={e.rowData.quantity !== 0 ? e.rowData.quantity : ''}*/}
                {/*                                onChange={e2 => onQuantityChange(e.rowData, e2.target.value)}*/}
                {/*                                style={{textAlign: 'right'}}/>}*/}
                {/*/>*/}
            </DataTable>

            <div style={{textAlign: 'right', paddingTop: '10px'}}>
                <input type='submit' value='Spara' className='save-btn' onClick={e => onSave()}/>
                <input type='button' value='Avbryt' onClick={e => props.onHide()}
                       style={{margin: '0'}}/>
            </div>
        </div>
    </Dialog>
}

export default DivestmentAddFromAcquisitionsDialog;
