import NetUtils from "../../utils/NetUtils";
import ArrayUtils from "../../utils/ArrayUtils";

export default {
    findByAcquisition: async divestmentId => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findByDivestment',
        manager: 'DivestmentappendixMgr',
        schema: 'byggletasset',
        postData: '' + divestmentId
    }).then(res => {
        return ArrayUtils.transformToArray(res.list0.divestmentappendix);
    }),

    download: (contentId, filename) => NetUtils.downloadFile('/api/get-content', {id: contentId, filename: filename}),
}