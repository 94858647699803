import React, {Component, useContext, useState} from 'react';
import {Button} from 'primereact/button';
import {Menu} from 'primereact/menu';
import {Link, withRouter} from "react-router-dom";
import {getModule, modules, settingModules} from "../../modules/modules";
import ChangePassword from "../ChangePassword/ChangePassword";
import CompanySwitch from "../CompanySwitch/CompanySwitch";

import './Header.scss';
import AppContext from '../../AppContext';
import MediaQuery from "react-responsive/src/Component";
import RouterUtils from "../../utils/RouterUtils";
import VersionUtils from "../../utils/VersionUtils";

class Header extends Component {
    static contextType = AppContext

    constructor() {
        super();
        this.state = {
            displayDialog: false,
        };
    }

    getCurrentTab() {
        let m = getModule(this.props.location.pathname);
        return m ? m.name : '';
    }

    isModuleAccessible = m => {
        return true
    }

    UserMenu = (onChangeRepo) => {
        const context = useContext(AppContext);
        const [displayDialog, setDisplayDialog] = useState(false);
        const [displayChangePasswordDialog, setDisplayChangePasswordDialog] = useState(false);

        let menu;
        const items = [
            {label: 'Byt företag', icon: 'pi pi-fw pi-refresh', command: () => setDisplayDialog(true)},
            {label: "Byt lösenord", icon: 'pi pi-fw pi-lock', command: () => setDisplayChangePasswordDialog(true)},
            {
                label: "Logga ut", icon: 'pi pi-fw pi-power-off', command: () => {
                    context.signOut();
                }
            }];

        return (
            <div>
                <Menu model={items} popup={true} ref={el => menu = el} style={{width: 'auto'}}/>
                <div className="Header-UserMenu" onClick={(event) => menu.toggle(event)}>
                    <div>
                        <span>{localStorage.getItem("userEmail")}</span>
                        <span>{context.currentCompany.name}</span>
                    </div>
                    <i className="pi pi-angle-down" style={{'fontSize': '1.3em'}}></i>
                </div>

                <CompanySwitch displayDialog={displayDialog} onHide={() => setDisplayDialog(false)}/>

                {
                    displayChangePasswordDialog &&
                    <ChangePassword displayDialog={displayChangePasswordDialog}
                                    onHide={() => setDisplayChangePasswordDialog(false)}/>
                }
            </div>
        )
    }

    SettingsMenu = () => {
        let menu;
        const settingModuleItems = this.getSettingModuleItems()

        if (settingModuleItems.length === 0) {
            return <div></div>
        }

        const items = [{
            label: 'Register',
            items: settingModuleItems
        }]

        return (
            <div>
                <Menu model={items} popup={true} ref={el => menu = el} style={{width: 'auto'}}/>
                <div className="Header-UserMenu" onClick={(event) => menu.toggle(event)}>
                    <i className="pi pi-cog" style={{'fontSize': '28px'}}></i>
                </div>
            </div>
        )
    }

    MobileMenu = () => {
        const [displayDialog, setDisplayDialog] = useState(false);
        let menu;

        const items = [{
            label: 'Sidor',
            items: this.getMmduleItems()
        }, {
            label: 'Register',
            items: this.getSettingModuleItems()
        }, {
            label: 'Användare',
            items: [
                {label: 'Byt depå', icon: 'pi pi-fw pi-refresh', command: () => setDisplayDialog(true)},
                {
                    label: "Logga ut", icon: 'pi pi-fw pi-power-off', command: () => {
                        this.context.signOut();
                    }
                }
            ]
        }

        ]

        return (
            <div>
                <Menu model={items} popup={true} ref={el => menu = el} style={{width: 'auto'}}/>

                <Button className="p-button-secondary"
                        icon="pi pi-bars"
                        onClick={(event) => menu.toggle(event)}/>

                <CompanySwitch displayDialog={displayDialog} onHide={() => setDisplayDialog(false)}/>
            </div>
        )
    }

    getMmduleItems = () => {
        var moduleItems = [];
        modules
            .filter(m => this.isModuleAccessible(m))
            .forEach(m => (
                m.name &&
                moduleItems.push({
                    label: m.name, icon: m.icon, command: () => {
                        // window.location.hash = m.routeProps.path;
                        // this.props.history.push(m.routeProps.path)
                    }
                })
            ))
        return moduleItems;
    }

    getSettingModuleItems = () => {
        var settingModuleItems = [];
        settingModules
            .filter(m => this.isModuleAccessible(m))
            .forEach(m => (
                settingModuleItems.push({
                    label: m.name, icon: m.icon, command: () => {
                        // window.location.hash = m.routeProps.path;
                        this.props.history.push(m.routeProps.path)
                    }
                })
            ));
        return settingModuleItems;
    }

    render() {
        return (
            <div className="Header">

                {
                    VersionUtils.newVersionAvailable &&
                    <div className='new-version'>
                        <div>
                            En ny version har släppts
                        </div>
                        <Button onClick={() => RouterUtils.reload()} label='Uppdatera nu!'/>

                    </div>
                }

                <MediaQuery minWidth={1200}>
                    <header className="desktop">
                        <img src={process.env.PUBLIC_URL + '/Nobius_Logistics_logo_cmyk.png'} alt="Tillgångar"/>
                        <ul className="nav">
                            {modules
                                .filter(m => this.isModuleAccessible(m))
                                .map(module => (
                                    module.name &&
                                    <li key={module.name}
                                        className={this.getCurrentTab() === module.name ? 'active' : ''}>
                                        <Link to={module.routeProps.path}>{module.name}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                        <this.UserMenu onChangeRepo={() => this.setState({displayDialog: true})}/>
                        <this.SettingsMenu/>
                    </header>
                </MediaQuery>

                {/*<MediaQuery maxWidth={1199}>*/}
                {/*    <div className="MobileHeader">*/}
                {/*        <Menu model={this.state.items} popup={true} ref={el => this.menu = el} style={{width: 'auto'}}/>*/}
                {/*        <header className="MobileHeader-Container">*/}
                {/*            <div className="Header-LeftBox">*/}
                {/*                <this.MobileMenu/>*/}
                {/*            </div>*/}
                {/*            <div className="MobileHeader-CenterBox">*/}
                {/*                /!*<img src="/logo-inv.png" alt="Agreement Depot"/>*!/*/}
                {/*                <div className="selectedCompany">{this.context.currentCompany.name}</div>*/}
                {/*            </div>*/}
                {/*            <div className="MobileHeader-RightBox"></div>*/}
                {/*        </header>*/}
                {/*    </div>*/}
                {/*</MediaQuery>*/}

                <CompanySwitch displayDialog={this.state.displayDialog}
                               model={this.state.selectedCar}
                               onHide={() => this.setState({displayDialog: false})}/>
            </div>
        )
    }
}

export default withRouter(Header)

