const CASH = 10000;
const LOAN = 10001;
const INSTALMENT_ID = 10002;
const LEASING_ID = 10003;
const RENT_BUY_ID = 10004;
const RENT_ID = 10005;
const INVENTORY_ADJUSTMENT_ID = 10006;

const list = [
    {id: INSTALMENT_ID, name: 'Avbetalning'},
    {id: RENT_ID, name: 'Hyra'},
    {id: RENT_BUY_ID, name: 'Hyrköp'},
    {id: CASH, name: 'Kontant'},
    {id: INVENTORY_ADJUSTMENT_ID, name: 'Lagerjustering'},
    {id: LEASING_ID, name: 'Leasing'},
    {id: LOAN, name: 'Lån'},
]

export default {
    CASH: CASH,
    LOAN: LOAN,
    INSTALMENT_ID: INSTALMENT_ID,
    LEASING_ID: LEASING_ID,
    RENT_BUY_ID: RENT_BUY_ID,
    RENT_ID: RENT_ID,
    INVENTORY_ADJUSTMENT_ID: INVENTORY_ADJUSTMENT_ID,

    list: list,
    getName: id => list.find(c => c.id === id)?.name || ''
}
