import NetUtils from "../../../utils/NetUtils";
import ArrayUtils from "../../../utils/ArrayUtils";

export default {
    findAccounts: async () => NetUtils.doGet('/asset/GetAcquisitionAccounts').then(res => {
        if (Object.keys(res).length === 0 && res.constructor === Object) {
            return [];
        } else {
            return ArrayUtils.transformToArray(res.accountNumber)
                .filter(a => a !== 0);
        }
    }),

    findByintervalAndAccount: async (startDate, endDate, accountNumber) => NetUtils.doPostWithParams('/asset/GetAcquisitionAccountDepreciation', {
        startDate: startDate,
        endDate: endDate,
        accountNumber: accountNumber
    }).then(res => {
        if (Object.keys(res).length === 0 && res.constructor === Object) {
            return [];
        } else {
            return ArrayUtils.transformToArray(res.accountDepreciation);
        }
    }),
}