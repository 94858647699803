import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import React, {useContext, useEffect, useState} from 'react';
import AppContext from "../../AppContext";
import {ActiveFilter} from "../../components/filters/ActiveFilter";
import ExtraMenu from "../../components/Grid/ExtraMenu";
import {GridTemplates} from "../../GridTemplates";
import ArrayUtils from "../../utils/ArrayUtils";
import NetUtils from "../../utils/NetUtils";
import RouterUtils from "../../utils/RouterUtils";
import SearchUtils from "../../utils/SearchUtils";
import {dynamicSort} from "../../utils/SortUtils";
import ArticleService from "./ArticleService";

const ArticleGrid = props => {
    const NAME = 'ArticleGrid';
    const context = useContext(AppContext);
    const [filter, setFilter] = useState({
        active: context.getFilter(NAME, 'active', 1),
        search: context.getFilter(NAME, 'search', ''),
        pageFirst: context.getFilter(NAME, 'pageFirst', 0),
        pageRows: context.getFilter(NAME, 'pageRows', 50)
    });
    const [list, setList] = useState([]);

    const matchActive = article => filter.active === null || article.active === filter.active;
    const matchSearch = article => SearchUtils.isMatching(article, filter.search);
    const filteredList = list.filter(a => matchActive(a) && matchSearch(a))

    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(filter);
    }

    const onRowSelect = model => RouterUtils.navigateTo(props, '/articles/' + model.id);

    const getListPriceValue = a => {
        let listPriceList = ArrayUtils.transformToArray(a.listPriceList.doublevalue);
        if (listPriceList.length > 0) {
            listPriceList.sort(dynamicSort("startTime"));
            return listPriceList[listPriceList.length - 1].value;
        } else {
            return 0;
        }
    }

    useEffect(() => {
        Promise.all([
            ArticleService.findAll({
                company: context.currentCompany.id
            }),
        ]).then(values => {
            const data = values[0];
            if (data.error) {
                alert(data.message)
                this.setState({allData: []})
            } else {
                data.forEach(a => {
                    a.exposure = a.company > 0 ? 'Endast ' + context.currentCompany.name : 'Alla företag';
                    a.listPriceValue = getListPriceValue(a);
                })

                data.sort(dynamicSort("name"));
                setList(data);
            }

        })
    }, [filter, context.currentCompany])

    return <div className="Grid">

        <div className="filter">
            <div>
                <label className="label">Status</label>
                <Dropdown
                    value={filter.active}
                    options={ActiveFilter.statusList}
                    onChange={e => updateFilter('active', e.value)}
                    showClear={true}
                    placeholder='Alla'
                    style={{width: '90px'}}/>
            </div>

            <div style={{marginLeft: '50px'}}>
                <label className="label">&nbsp;</label>
                <Button label='Lägg till artikel'
                        icon="pi pi-plus"
                        onClick={() => RouterUtils.navigateTo(props, '/articles/0')}/>
            </div>

            <div style={{flexGrow: 1, padding: 0}}></div>

            <div>
                <label className="label">Sök</label>
                <InputText className="search" type="search" defaultValue={filter.search}
                           onInput={(e) => updateFilter('search', e.target.value)}/>
            </div>

            <div style={{paddingRight: 0}}>
                <label className="label">&nbsp;</label>
                <ExtraMenu list={filteredList}
                           onExportToExcel={() => NetUtils.exportToExcel('Artiklar', [
                               {field: 'name', label: 'Namn'},
                               {field: 'number', label: 'Art.nr'},
                               {field: 'description', label: 'Beskrivning'},
                               {field: 'exposure', label: 'Exponering'},
                               {field: 'categoryString', label: 'Kategorier'},
                               {field: 'listPriceValue', label: 'Listpris'},
                               {field: 'quantityPerPack', label: 'Antal/förp'},
                               {field: 'weight', label: 'Vikt'},
                               {field: 'stockQuantity', label: 'Lagersaldo'},
                               {field: 'active', label: 'Aktiv'},
                           ], filteredList)}/>
            </div>
        </div>

        <DataTable className="dataTable"
                   value={filteredList}
                   resizableColumns={true}
                   columnResizeMode="expand"
                   selectionMode="single"
                   onRowSelect={e => onRowSelect(e.data)}
                   scrollable={true} scrollHeight="calc(100vh - 240px)"

                   paginator={true} rows={filter.pageRows} first={filter.pageFirst}
                   onPage={e => {
                       updateFilter('pageFirst', e.first);
                       updateFilter('pageRows', e.rows);
                   }}
                   rowsPerPageOptions={[10, 50, 100, 300, 1000]}
        >
            <Column field='name' header='Namn' sortable={true}/>
            <Column field='number' header='Art.nr' sortable={true} style={{width: '100px'}}/>
            <Column field='description' header='Beskrivning' sortable={true}/>
            <Column field='exposure' header='Exponering' sortable={true}/>
            <Column field='categoryString' header='Kategorier' sortable={true}/>
            <Column field='listPriceValue' header='Listpris' sortable={true} body={GridTemplates.currency}
                    style={{width: '90px', textAlign: 'right'}}/>
            <Column field='listPriceValueUpdated' header='Uppdaterad' sortable={true}
                    style={{width: '90px'}}/>
            <Column field='quantityPerPack' header='Antal/förp' sortable={true} body={GridTemplates.integer}
                    style={{width: '100px', textAlign: 'right'}}/>
            <Column field='weight' header='Vikt' sortable={true} body={GridTemplates.weightTwoDecimals}
                    style={{width: '80px', textAlign: 'right'}}/>
            <Column field='stockQuantity' header='Lagersaldo' sortable={true} body={GridTemplates.integer}
                    style={{width: '120px', textAlign: 'right'}}/>
            <Column field='active' header='Aktiv' sortable={true} body={GridTemplates.boolean}
                    style={{width: '100px'}}/>
        </DataTable>
    </div>
}

export default ArticleGrid;
