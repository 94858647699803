import moment from "moment";
import React, {useContext, useState} from 'react';
import {useForm} from 'react-hook-form';
import AppContext from "../../../AppContext";
import ErrorText from "../../../components/EditForm/ErrorText";
import {errorAlert} from "../../../components/Overlay/Alert";
import ArrayUtils from "../../../utils/ArrayUtils";
import DateUtils from "../../../utils/DateUtils";
import RouterUtils from "../../../utils/RouterUtils";
import {dynamicSort} from "../../../utils/SortUtils";
import useMountEffect from "../../../utils/useMountEffect";
import ValidateUtils from "../../../utils/ValidateUtils";
import AcquisitiontypeService from "../../Acquisitiontypes/AcquisitiontypeService";
import ArticleService from "../../Articles/ArticleService";
import Currency from "../../Currencies/Currency";
import FinancingService from "../../Financing/FinancingService";
import SupplierService from "../../Suppliers/SupplierService";
import AcquisitionappendixService from "../AcquisitionappendixService";
import AcquisitionService from "../AcquisitionService";
import AcquisitionfinancingGrid from "./AcquisitionfinancingGrid";
import AcquisitionrowGrid from "./AcquisitionrowGrid";
import AppendixGrid from "./AppendixGrid";
import MonthDepreciationGrid from "./MonthDepreciationGrid";

const AcquisitionEditForm = props => {
    const context = useContext(AppContext);
    const [model, setModel] = useState({id: 0});

    const [typeList, setTypeList] = useState();
    const [supplierList, setSupplierList] = useState();
    const [articleList, setArticleList] = useState();
    const [financingList, setFinancingList] = useState([]);
    const [rowList, setRowList] = useState([]);

    const [monthDepreciationList, setMonthDepreciationList] = useState([]);
    const [acquisitionfinancingList, setFinancingacquisitionfinancingList] = useState([]);
    const [appendixList, setAppendixList] = useState([]);

    const disabled = false;

    const {register, errors, handleSubmit, reset, formState, getValues, watch} = useForm({
        defaultValues: {
            name: '',
            accountNumber: 0,
            exchangeRate: 1,
            discount: 0,
            active: true
        }
    });

    const id = RouterUtils.getIdFromPath(props, '/acquisitions/:id');

    const watchCurrency = watch("currency", model.currency);
    const watchExchangeRate = Number(watch("exchangeRate", model.exchangeRate));
    const watchDiscount = Number(watch("discount", model.discount));

    const {isSubmitting} = formState;

    const onSubmit = data => {
        saveData(data).then(_model => {
            RouterUtils.goBack(props);
        });
    };

    const saveData = async data => {
        data.monthDepreciations = monthDepreciationList.map(ap => moment(ap.startTime).format('yyyy-MM-DD') + '_' + ap.value).join(';')

        rowList
            .filter(r => Number(r.quantity) !== 0)
            .forEach((r, i) => {
                data['rowId' + i] = r.id;
                data['rowArticle' + i] = r.article;
                data['rowAssetId' + i] = r.assetId;
                data['rowQuantity' + i] = r.quantity;
                data['rowAprice' + i] = r.aprice;
                data['rowPrice' + i] = r.price;
            });

        acquisitionfinancingList.forEach((f, i) => {
            data['financingId' + i] = f.id;
            data['financingFinancingType' + i] = f.financingtype;
            data['financingFinancing' + i] = f.financing;
            data['financingStartDate' + i] = f.startDate;
            data['financingEndDate' + i] = f.endDate.length === 10 ? f.endDate : DateUtils.get1972DateString();
            data['financingNote' + i] = f.note;
        });

        appendixList.forEach((a, i) => {
            data['appendixId' + i] = a.id;
            data['appendixContentId' + i] = a.content;
            data['appendixNumber' + i] = i;
            data['appendixAttach' + i] = 0;
        });

        Object.assign(model, data);
        return AcquisitionService.update(model);
    };

    const onDepreciationsClick = e => {
        const data = getValues();
        saveData(data).then(_model => {
            console.log("DEDEFWE", _model)
            RouterUtils.navigateTo(props, '/acquisitions/' + _model.id + '/depreciations')
        })
    }

    useMountEffect(() => {
        let promises = [
            AcquisitiontypeService.findByCompany(context.currentCompany.id),
            SupplierService.findByCompany(context.currentCompany.id),
            ArticleService.findAll(),
            FinancingService.findByCompany(context.currentCompany.id)
        ];

        if (id > 0) {
            promises.push(AcquisitionService.findById(id));
            promises.push(AcquisitionService.findRowsByAcquisitionId(id));
            promises.push(AcquisitionService.findFinancingByAcquisitionId(id));
            promises.push(AcquisitionappendixService.findByAcquisition(id));
        }

        Promise.all(promises).then(values => {
            if (values[0].length === 0) {
                errorAlert('Det finns inga anskaffningstyper upplagda.')
                RouterUtils.goBack(props)
            }
            setTypeList(values[0]);
            setSupplierList(values[1])
            setArticleList(values[2])
            values[3].sort(dynamicSort("financierString"));
            setFinancingList(values[3])

            let m;
            if (id > 0) {
                m = values[4];
                m.acquisitionDate = moment(m.acquisitionDate).format('yyyy-MM-DD');
                setModel(m);

                values[5].sort(dynamicSort("listOrder"));
                setRowList(values[5])

                const _monthDepreciationList = ArrayUtils.transformToArray(m.monthDepreciationList.doublevalue)
                _monthDepreciationList.sort(dynamicSort("startTime"));
                setMonthDepreciationList(_monthDepreciationList);

                const _acquisitionfinancingList = ArrayUtils.transformToArray(values[6])
                _acquisitionfinancingList.sort(dynamicSort("startDate"));
                setFinancingacquisitionfinancingList(_acquisitionfinancingList);

                const _appendixList = ArrayUtils.transformToArray(values[7])
                _appendixList.sort(dynamicSort("listOrder"));
                setAppendixList(_appendixList);

                reset(m);
            }
        });
    }, [])

    return (
        <div className="EditForm">
            {model && typeList && supplierList && articleList && financingList && rowList && acquisitionfinancingList &&
                <div>
                    <div className="headline">
                        <h1>Anskaffning</h1>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="ui-dialog-buttonpane p-clearfix button-row">
                            <div className='left-buttons'>
                                {!disabled &&
                                    <input type='submit' value='Spara' disabled={isSubmitting} className='save-btn'/>}
                                <input type='button' value='Avbryt' onClick={e => RouterUtils.goBack(props)}/>
                                <div style={{width: '50px'}}></div>
                                <input type='button' value='Avskrivningar' onClick={e => onDepreciationsClick(e)}/>
                            </div>
                        </div>


                        <div className='formOfThree'>
                            <div className='column'>
                                <div className="formItem">
                                    <label htmlFor='name'>Namn</label>
                                    <input name="name"
                                           ref={register({
                                               maxLength: ValidateUtils.maxLength(255)
                                           })}
                                           style={{width: '300px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="name"/>
                                </div>

                                <div className="formItem">
                                    <label htmlFor='acquisitionDate'>Datum</label>
                                    <input type='date' name="acquisitionDate"
                                           ref={register({
                                               required: ValidateUtils.required(),
                                               pattern: ValidateUtils.datePattern()
                                           })}
                                           readOnly={disabled}
                                    />
                                    <ErrorText errors={errors} name="acquisitionDate"/>
                                </div>

                                <div className="formItem">
                                    <label htmlFor='type'>Type</label>
                                    <select name="type"
                                            ref={register()}
                                            disabled={disabled}>
                                        {typeList.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                                    </select>
                                    <ErrorText errors={errors} name="type"/>
                                </div>

                                <div className="formItem">
                                    <label htmlFor='supplier'>Leverantör</label>
                                    <select name="supplier"
                                            ref={register()}
                                            disabled={disabled}>
                                        <option value='0'>--- Ingen ---</option>
                                        {supplierList.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                                    </select>
                                    <ErrorText errors={errors} name="supplier"/>
                                </div>
                            </div>


                            <div className='column'>
                                <div className="formItem">
                                    <label htmlFor='place'>Plats</label>
                                    <input name="place"
                                           ref={register({
                                               maxLength: ValidateUtils.maxLength(255)
                                           })}
                                           style={{width: '300px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="place"/>
                                </div>
                                <div className="formItem">
                                    <label htmlFor='number'>Referens</label>
                                    <input name="number"
                                           ref={register({
                                               maxLength: ValidateUtils.maxLength(45)
                                           })}
                                           style={{width: '300px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="number"/>
                                </div>
                                <div className="formItem">
                                    <label htmlFor='note'>Anteckning</label>
                                    <input name="note"
                                           ref={register({
                                               maxLength: ValidateUtils.maxLength(255)
                                           })}
                                           style={{width: '300px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="note"/>
                                </div>
                                <div className="formItem">
                                    <label htmlFor='accountNumber'>Kontonr</label>
                                    <input type='number' name="accountNumber"
                                           ref={register({
                                               required: ValidateUtils.required(),
                                               pattern: ValidateUtils.numberPattern
                                           })}
                                           style={{width: '80px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="accountNumber"/>
                                </div>
                                <div className="formItem">
                                    <label htmlFor='bookkeepingId'>Bokförings-ID</label>
                                    <input name="bookkeepingId" maxLength='45'
                                           ref={register({
                                               maxLength: ValidateUtils.maxLength(45)
                                           })}
                                           style={{width: '300px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="bookkeepingId"/>
                                </div>
                            </div>


                            <div className='column'>
                                <div className="formItem">
                                    <label htmlFor='currency'>Valuta</label>
                                    <select name="currency" ref={register()}>
                                        {Currency.list.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                                    </select>
                                </div>
                                <div className="formItem">
                                    <label htmlFor='exchangeRate'>Valutakurs</label>
                                    <input type='number' step='any' name="exchangeRate"
                                           ref={register({
                                               pattern: ValidateUtils.numberPattern
                                           })}
                                           style={{width: '80px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="exchangeRate"/>
                                </div>
                                <div className="formItem">
                                    <label htmlFor='discount'>Rabatt %</label>
                                    <input type='number' step='any' name="discount"
                                           ref={register({
                                               pattern: ValidateUtils.numberPattern
                                           })}
                                           style={{width: '120px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="discount"/>
                                </div>
                                <div className="formItem">
                                    <label htmlFor='active'>Aktiv</label>
                                    <input type='checkbox' name="active" ref={register} disabled={disabled}/>
                                </div>
                            </div>
                        </div>

                    </form>

                    <div className="formItem">
                        <label>Avskrivningstid</label>
                        <MonthDepreciationGrid parentId={model.id}
                                               value={monthDepreciationList}
                                               onChange={newList => setMonthDepreciationList(newList)}/>
                    </div>

                    <div className="formItem">
                        <label>Finansiering</label>
                        <AcquisitionfinancingGrid parentId={model.id}
                                                  value={acquisitionfinancingList}
                                                  financingList={financingList}
                                                  onChange={newList => setFinancingacquisitionfinancingList(newList)}/>
                    </div>


                    <div className="formItem">
                        <label>Rader</label>
                        <AcquisitionrowGrid model={model}
                                            rows={rowList}
                                            articleList={articleList}
                                            currency={watchCurrency}
                                            exchangeRate={watchExchangeRate}
                                            discount={watchDiscount}
                                            onSave={newRows => {
                                                setRowList(newRows);
                                            }}
                        />
                    </div>

                    <div className="formItem">
                        <label>Bilagor & dokument</label>
                        <AppendixGrid value={appendixList}
                                      onChange={newList => setAppendixList(newList)}/>
                    </div>

                </div>
            }
        </div>
    );
}

export default AcquisitionEditForm;
