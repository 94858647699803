import React from 'react';
import moment from "moment";
import NumberUtil from "./utils/NumberUtil";
import OrderStatus from "./modules/Orders/OrderStatus";
import {NumberUtils} from "./utils/NumberUtils";

export class GridTemplates {
    static getValue = (rowData, column) => column.field.split('.').reduce((o, i) => o[i], rowData);
    static getNumber = (rowData, column) => Number(GridTemplates.getValue(rowData, column));

    static integer = (rowData, column) => NumberUtil.formatNumber(Number(GridTemplates.getValueByString(rowData, column.field)), 0);

    static weight = (rowData, column) => NumberUtil.formatWeight(Number(rowData[column.field]), 0);
    static weightTwoDecimals = (rowData, column) => NumberUtil.formatWeight(GridTemplates.getNumber(rowData, column), 2);

    static currency = (rowData, column) => NumberUtils.formatCurrency(Number(rowData[column.field]), 0);
    static numberTwoDecimals = (rowData, column) => NumberUtils.format(Number(rowData[column.field]), 2);

    static currencyTwoDecimals(rowData, column) {
        let res = GridTemplates.numberTwoDecimals(rowData, column)
        return res.length > 0 ? res + ' kr' : '';
    }

    static percent(rowData, column) {
        let value = Number(rowData[column.field]);
        if (isNaN(value) || value === 0) {
            return '';
        } else {
            return Math.round(value * 100) + " %";
        }
    }

    static time(rowData, column) {
        var timeStr = rowData[column.field];
        var time = new Date(timeStr);
        return time.toLocaleString();
    }

    static date(rowData, column) {
        var date = rowData[column.field];
        console.log("DFAATRT", date, typeof date)
        if (date === "1970-01-01") {
            return <span></span>;
        } else {
            return moment(date).format("L");
        }
    }

    static zip(rowData, column) {
        let value = Number(rowData[column.field]);
        if (isNaN(value) || value === 0) {
            return '';
        } else {
            let str = value + '';
            if (str.length === 5) {
                return str.substr(0, 3) + ' ' + str.substr(3, 2);
            } else {
                return value;
            }
        }
    }

    static active(rowData, column) {
        var active = rowData[column.field];
        return active ? 'Aktiv' : 'Inaktiv';
    }

    static boolean(rowData, column) {
        var active = rowData[column.field];
        return active ? 'Ja' : 'Nej';
    }

    static orderStatus(rowData, column) {
        return OrderStatus.getName(rowData[column.field]);
    }

    static getValueByString = function (o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }
}

