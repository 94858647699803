import {Card} from "primereact/card";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {DataTable} from "primereact/datatable";
import {Row} from "primereact/row";
import React from 'react';
import renderOverlay from "../../components/Overlay/renderOverlay";
import {GridTemplates} from "../../GridTemplates";
import {NumberUtils} from "../../utils/NumberUtils";
import AssetfinancingDistributionDetails from "./AssetfinancingDistributionDetails";
import './Dashboard.scss'

const AssetfinancingDistribution = props => {
    const yearMonth = props.yearMonth;
    let value = props.value;
    if (!value.length) { //Make sure it is an array
        value = [value];
    }

    const sumByField = field => value.reduce((sum, item) => sum += item[field], 0);

    const onRowSelect = model => {
        renderOverlay(<AssetfinancingDistributionDetails yearMonth={yearMonth} model={model}/>)
    }


    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={'Totalt (' + value.length + ' st)'}
                    style={{textAlign: 'left'}}/>
            <Column footer={NumberUtils.formatCurrency(sumByField('listPriceValue'))} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(sumByField('marketValue'))} style={{textAlign: 'right'}}/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer={NumberUtils.formatCurrency(sumByField('bookkeepingValue'))} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(sumByField('debt'))} style={{textAlign: 'right'}}/>
            <Column footer=''/>
            <Column footer={NumberUtils.formatCurrency(sumByField('overvalue'))} style={{textAlign: 'right'}}/>
        </Row>
    </ColumnGroup>;

    return <Card className='assetfinancingDistributionCard' style={{width: '1200px'}}>
        <div className="Grid">
            <h1>Finansieringstyper</h1>
            <DataTable className="dataTable"
                       value={value}
                       resizableColumns={true}
                       columnResizeMode="expand"
                       paginator={false}
                       footerColumnGroup={footerGroup}
                       selectionMode="single"
                       onRowSelect={e => onRowSelect(e.data)}
                       scrollable={true} scrollHeight="calc(100vh - 240px)"
            >
                <Column field='financingtypeString' header='Finansieringstyp' body={row => {
                    if (row.financingtypeString === 'bought') return 'Köpt'
                    if (row.financingtypeString === 'leasing') return 'Leasing'
                    if (row.financingtypeString === 'rent_buy') return 'Hyrköp'
                    return row.financingtypeString;
                }}/>
                <Column field='listPriceValue' header='Listpris' body={GridTemplates.currency}
                        style={{textAlign: 'right', width: '110px'}}/>
                <Column field='marketValue' header='Marknadsvärde' body={GridTemplates.currency}
                        style={{textAlign: 'right', width: '110px'}}/>
                <Column field='price' header='Anskaffningspris' body={GridTemplates.currency}
                        style={{textAlign: 'right', width: '110px'}}/>

                <Column field='depreciationValue' header='Ack.avskrivningar'
                        body={row => row.financingtypeString === "bought"
                            ? NumberUtils.formatCurrency(row.price - row.bookkeepingValue)
                            : ''}
                        style={{textAlign: 'right', width: '110px'}}/>

                <Column field='bookkeepingValue' header='Bokfört värde' body={GridTemplates.currency}
                        style={{textAlign: 'right', width: '110px'}}/>
                <Column field='debt' header='Restskuld' body={GridTemplates.currency}
                        style={{textAlign: 'right', width: '110px'}}/>
                <Column field='undepreciatedFirstLeasingRent' header='Oavskriven första leasingavgift'
                        body={GridTemplates.currency} style={{textAlign: 'right', width: '200px'}}/>
                <Column field='overvalue' header='Övervärde' body={GridTemplates.currency}
                        style={{textAlign: 'right', width: '110px'}}/>

            </DataTable>
        </div>
    </Card>
}

export default AssetfinancingDistribution;
