import moment from "moment";
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import AppContext from "../../../AppContext";
import ErrorText from "../../../components/EditForm/ErrorText";
import ArrayUtils from "../../../utils/ArrayUtils";
import DateUtils from "../../../utils/DateUtils";
import RouterUtils from "../../../utils/RouterUtils";
import {dynamicSort} from "../../../utils/SortUtils";
import ValidateUtils from "../../../utils/ValidateUtils";
import AcquisitiontypeService from "../../Acquisitiontypes/AcquisitiontypeService";
import CustomerService from "../../Customers/CustomerService";
import DivestmentappendixService from "../DivestmentappendixService";
import DivestmentService from "../DivestmentService";
import AppendixGrid from "./AppendixGrid";
import DivestmentfinancingGrid from "./DivestmentfinancingGrid";
import DivestmentrowGrid from "./DivestmentrowGrid";

const DivestmentEditForm = props => {
    const context = useContext(AppContext);
    const [model, setModel] = useState({
        id: 0
    });

    const [typeList, setTypeList] = useState();
    const [customerList, setCustomerList] = useState();
    const [rowList, setRowList] = useState([]);

    const [acquisitionfinancingList, setFinancingacquisitionfinancingList] = useState([]);
    const [appendixList, setAppendixList] = useState([]);

    const disabled = false;

    const {register, errors, handleSubmit, reset, formState, watch} = useForm({
        defaultValues: {
            name: '',
            divestmentDate: moment().format('yyyy-MM-DD'),
            accountNumber: 0,
            discount: 0,
            active: true
        }
    });

    let id = RouterUtils.getIdFromPath(props, '/divestments/:id');

    const watchAcquisitiontype = Number(watch("acquisitiontype", model.acquisitiontype));
    const watchDiscount = Number(watch("discount", model.discount));
    const watchDivestmentDate = watch("divestmentDate", model.divestmentDate);

    const {isSubmitting} = formState;

    const onSubmit = data => {
        saveData(data).then(_model => {
            RouterUtils.goBack(props);
        });
    };

    const saveData = async data => {
        rowList
            .filter(r => Number(r.quantity) !== 0)
            .forEach((r, i) => {
                data['rowId' + i] = r.id;
                data['rowAcquisition' + i] = r.acquisitionrow;
                data['rowQuantity' + i] = r.quantity;
                data['rowAprice' + i] = r.aprice;
                data['rowPrice' + i] = r.price;
            });

        acquisitionfinancingList.forEach((f, i) => {
            data['financingId' + i] = f.id;
            data['financingFinancingtype' + i] = f.financingtype;
            data['financingStartDate' + i] = f.startDate;
            data['financingEndDate' + i] = f.endDate.length === 10 ? f.endDate : DateUtils.get1972DateString();
            data['financingNote' + i] = f.note;
        });

        appendixList.forEach((a, i) => {
            data['appendixId' + i] = a.id;
            data['appendixContentId' + i] = a.content;
            data['appendixNumber' + i] = i;
            data['appendixAttach' + i] = 0;
        });

        Object.assign(model, data);
        return DivestmentService.update(model);
    };

    useEffect(() => {
        let promises = [
            AcquisitiontypeService.findByCompany(context.currentCompany.id),
            CustomerService.findByCompany(context.currentCompany.id)
        ];

        if (id > 0) {
            promises.push(DivestmentService.findById(id));
            promises.push(DivestmentService.findRowsByDivestmentId(id));
            promises.push(DivestmentService.findFinancingByDivestmentId(id));
            promises.push(DivestmentappendixService.findByAcquisition(id));
        }

        Promise.all(promises).then(values => {
            setTypeList(values[0]);
            setCustomerList(values[1])

            let m;
            if (id > 0) {
                m = values[2];
                m.divestmentDate = moment(m.divestmentDate).format('yyyy-MM-DD');
                setModel(m);

                values[3].sort(dynamicSort("listOrder"));
                setRowList(values[3])

                const _acquisitionfinancingList = ArrayUtils.transformToArray(values[4])
                _acquisitionfinancingList.sort(dynamicSort("startDate"));
                setFinancingacquisitionfinancingList(_acquisitionfinancingList);

                const _appendixList = ArrayUtils.transformToArray(values[5])
                _appendixList.sort(dynamicSort("listOrder"));
                setAppendixList(_appendixList);

                reset(m);
            }
        });
    }, [id, reset, context.currentCompany])

    return (
        <div className="EditForm">
            {model && typeList && customerList && rowList && acquisitionfinancingList &&
                <div>
                    <div className="headline">
                        <h1>Avyttring</h1>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="ui-dialog-buttonpane p-clearfix button-row">
                            <div className='left-buttons'>
                                {!disabled &&
                                    <input type='submit' value='Spara' disabled={isSubmitting} className='save-btn'/>}
                                <input type='button' value='Avbryt' onClick={e => RouterUtils.goBack(props)}/>
                            </div>
                        </div>


                        <div className='formOfThree'>
                            <div className='column'>
                                <div className="formItem">
                                    <label htmlFor='name'>Namn</label>
                                    <input name="name"
                                           ref={register({
                                               required: ValidateUtils.required(),
                                               maxLength: ValidateUtils.maxLength(255)
                                           })}
                                           style={{width: '300px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="name"/>
                                </div>

                                <div className="formItem">
                                    <label htmlFor='divestmentDate'>Datum</label>
                                    <input type='date' name="divestmentDate"
                                           ref={register({
                                               required: ValidateUtils.required(),
                                               pattern: ValidateUtils.datePattern()
                                           })}
                                           readOnly={disabled}
                                    />
                                    <ErrorText errors={errors} name="divestmentDate"/>
                                </div>

                                <div className="formItem">
                                    <label htmlFor='acquisitiontype'>Type</label>
                                    <select name="acquisitiontype"
                                            ref={register()}
                                            disabled={disabled}>
                                        {typeList.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                                    </select>
                                    <ErrorText errors={errors} name="acquisitiontype"/>
                                </div>

                                <div className="formItem">
                                    <label htmlFor='customer'>Kund</label>
                                    <select name="customer"
                                            ref={register()}
                                            disabled={disabled}>
                                        <option value='0'>--- Ingen ---</option>
                                        {customerList.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                                    </select>
                                    <ErrorText errors={errors} name="customer"/>
                                </div>
                            </div>


                            <div className='column'>
                                <div className="formItem">
                                    <label htmlFor='number'>Referens</label>
                                    <input name="number"
                                           ref={register({
                                               maxLength: ValidateUtils.maxLength(45)
                                           })}
                                           style={{width: '300px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="number"/>
                                </div>
                                <div className="formItem">
                                    <label htmlFor='note'>Anteckning</label>
                                    <input name="note"
                                           ref={register({
                                               maxLength: ValidateUtils.maxLength(255)
                                           })}
                                           style={{width: '300px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="note"/>
                                </div>
                                <div className="formItem">
                                    <label htmlFor='accountNumber'>Kontonr</label>
                                    <input type='number' name="accountNumber"
                                           ref={register({
                                               pattern: ValidateUtils.numberPattern
                                           })}
                                           style={{width: '80px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="accountNumber"/>
                                </div>
                                <div className="formItem">
                                    <label htmlFor='bookkeepingId'>Bokförings-ID</label>
                                    <input name="bookkeepingId" maxLength='45'
                                           ref={register({
                                               maxLength: ValidateUtils.maxLength(45)
                                           })}
                                           style={{width: '300px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="bookkeepingId"/>
                                </div>
                            </div>


                            <div className='column'>
                                <div className="formItem">
                                    <label htmlFor='discount'>Rabatt %</label>
                                    <input type='number' step='any' name="discount"
                                           ref={register({
                                               pattern: ValidateUtils.numberPattern
                                           })}
                                           style={{width: '120px'}}
                                           disabled={disabled}
                                    />
                                    <ErrorText errors={errors} name="discount"/>
                                </div>
                                <div className="formItem">
                                    <label htmlFor='active'>Aktiv</label>
                                    <input type='checkbox' name="active" ref={register} disabled={disabled}/>
                                </div>
                            </div>
                        </div>

                    </form>

                    <div className="formItem">
                        <label>Finansiering</label>
                        <DivestmentfinancingGrid parentId={model.id}
                                                 value={acquisitionfinancingList}
                                                 onChange={newList => setFinancingacquisitionfinancingList(newList)}/>
                    </div>


                    <div className="formItem">
                        <label>Rader</label>
                        <DivestmentrowGrid model={model}
                                           divestmentDate={watchDivestmentDate}
                                           rows={rowList}
                                           acquisitiontype={watchAcquisitiontype}
                                           discount={watchDiscount}
                                           onSave={newRows => {
                                               setRowList(newRows);
                                           }}
                        />
                    </div>

                    <div className="formItem">
                        <label>Bilagor & dokument</label>
                        <AppendixGrid value={appendixList}
                                      onChange={newList => setAppendixList(newList)}/>
                    </div>

                </div>
            }
        </div>
    );
}

export default DivestmentEditForm;
