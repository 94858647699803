import DivestmentGrid from "./DivestmentGrid";
import DivestmentEditForm from "./edit/DivestmentEditForm";

export default {
    routeProps: {
        path: '/divestments',
        exact: true,
        component: DivestmentGrid,
    },
    name: 'Avyttringar',
    icon: 'pi pi-fw pi-briefcase',
    subModules: [{
        routeProps: {
            path: '/divestments/:id',
            exact: true,
            component: DivestmentEditForm
        }
    }],
};