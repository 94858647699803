/**
 * XLNZ COMMON REACT - Do not edit elsewhere
 */

const ValidateUtils = {
    required: () => 'Detta fält måste fyllas i',
    email: () => ({
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        // value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'E-postadressen har fel format'
    }),
    maxLength: value => ({
        value: value,
        message: `Max ${value} tecken`
    }),
    max: value => ({
        value: value,
        message: `Max ${value}`
    }),
    numberPattern: () => ({
        // value: /^(\d*\.)?\d+$/,
        value: /^[+-]?\d+(\.\d+)?$/,
        message: "Endast nummer är tillåtet"
    }),
    integerPattern: () => ({
        value: /^\d+$/,
        message: "Endast heltal är tillåtet"
    }),
    datePattern: () => ({
        value: /^\d{4}-\d{2}-\d{2}$/,
        message: "Datum ska matas in enligt ÅÅÅÅ-MM-DD"
    })
}

export default ValidateUtils;

