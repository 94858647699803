import NetUtils from "../../utils/NetUtils";

export default {
    findByCompany: async companyId => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findByConcernAndArticletype,findByCompanyAndArticletype',
        manager: 'ArticlecategoryMgr,ArticlecategoryMgr',
        schema: 'byggletasset,byggletasset',
        postData: '10000;10000,' + companyId + ';10000'
    }).then(res => {
        let result = [];
        if (res.list0.articlecategory) {
            result = result.concat(res.list0.articlecategory);
        }
        if (res.list1.articlecategory) {
            result = result.concat(res.list1.articlecategory);
        }
        result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        return result;
    }),

    findById: async id => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findById',
        manager: 'ArticlecategoryMgr',
        schema: 'byggletasset',
        postData: '' + id
    }).then(res => {
        return res.list0.articlecategory;
    }),

    update: model => NetUtils.doPostWithParams('/asset/UpdateArticlecategory', model),

    findMappings: articleId => NetUtils.doGet('/api/articlecategories/mappings/' + articleId),
    updateMappings: (articleId, modelList) => NetUtils.doPost('/api/articlecategories/mappings/' + articleId, modelList),
}