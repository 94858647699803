import React, {useState} from 'react';
import {Dialog} from 'primereact/dialog';
import './ChangePassword.scss';
import ReactPasswordStrength from 'react-password-strength';
import Spinner from "../Spinner/Spinner";
import {SecurityService} from "../../security/SecurityService";

const ChangePassword = props => {
    const [password, setPassword] = useState({
        score: 0,
        isValid: false,
        password: ''
    });
    const [loading, setLoading] = useState(false);

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        SecurityService.changePassword(password.password).then(() => {
            SecurityService.signOut();
        })
    }

    return (
        <div className='ChangePassword'>
            {loading && <Spinner/>}
            <Dialog className='dialog'
                    header="Byt lösenord"
                    visible={props.displayDialog}
                    onHide={props.onHide}>

                <div className="EditForm">
                    <form>

                        <div className="formItem">
                            <label htmlFor='name'>Nytt lösenord</label>
                            <ReactPasswordStrength
                                className="password-input"
                                minLength={8}
                                minScore={2}
                                scoreWords={['För dåligt', 'För dåligt', 'Bra', 'Starkt', 'Starkt!']}
                                tooShortWord={'För kort'}
                                changeCallback={e => setPassword(e)}
                                inputProps={{name: "password_input", autoComplete: "off", className: "form-control"}}
                            />
                        </div>

                        <div className="ui-dialog-buttonpane p-clearfix button-row">
                            <div className='left-buttons'></div>
                            <div className='divider'></div>

                            <div className='right-buttons'>
                                <input type='submit' value='Spara' className='save-btn' onClick={onSubmit}
                                       disabled={!password.isValid}
                                />
                                <input type='button' value='Avbryt' onClick={e => props.onHide()}/>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    )
}
export default ChangePassword;