const hasRole = (roles, role) => {
    return roles.find(r => r === role) !== undefined;
}
const getRoleGroupString = (roles, items, groupName) => {
    if (!roles) return '';

    const arr = []
    items.forEach(i => {
        if (hasRole(roles, i.role))
            arr.push(i.type);
    })
    if (arr.length > 0) {
        let str = arr.join('/') + ' ' + groupName;
        str = str.charAt(0).toUpperCase() + str.slice(1);
        return str;
    }
    return ''
}

const getString = roles => {
    const result = [];

    result.push(getRoleGroupString(roles, [
        {role: 'SUPERADMIN', type: ''},
    ], 'Superadmin'));

    result.push(getRoleGroupString(roles, [
        {role: 'DASHBOARD_READ', type: 'läsa'},
    ], 'översiken'));

    result.push(getRoleGroupString(roles, [
        {role: 'CUSTOMER_READ', type: 'läsa'},
        {role: 'CUSTOMER_WRITE', type: 'skriva'}
    ], 'kunder'));

    result.push(getRoleGroupString(roles, [
        {role: 'ARTICLE_READ', type: 'läsa'},
        {role: 'ARTICLE_WRITE', type: 'skriva'}
    ], 'artiklar'));

    result.push(getRoleGroupString(roles, [
        {role: 'ORDER_READ', type: 'läsa'},
        {role: 'ORDER_WRITE', type: 'skriva'},
        {role: 'ORDER_UNLOCK', type: 'låsa upp'}
    ], 'ordrar'));

    result.push(getRoleGroupString(roles, [
        {role: 'RENT_READ', type: 'läsa'},
    ], 'hyresunderlag'));

    result.push(getRoleGroupString(roles, [
        {role: 'ARTICLECATEGORY_READ', type: 'läsa'},
        {role: 'ARTICLECATEGORY_WRITE', type: 'skriva'}
    ], 'artikelkategorier'));

    result.push(getRoleGroupString(roles, [
        {role: 'SUPPLIER_READ', type: 'läsa'},
        {role: 'SUPPLIER_WRITE', type: 'skriva'}
    ], 'leverantörer'));

    result.push(getRoleGroupString(roles, [
        {role: 'USER_READ', type: 'läsa'},
        {role: 'USER_WRITE', type: 'skriva'}
    ], 'användar- och kundåtkomst'));

    return result.filter(s => s.length > 0);
}

const getLabel = role => {
    switch (role) {
        case 'ARTICLE_READ':
            return "Läsa artiklar"
        case 'ARTICLE_WRITE':
            return "Skriva artiklar"
        case 'ARTICLECATEGORY_READ':
            return "Läsa artikelkategorier"
        case 'ARTICLECATEGORY_WRITE':
            return "Skriva artikelkategorier"
        case 'CUSTOMER_READ':
            return "Läsa kunder"
        case 'CUSTOMER_WRITE':
            return "Skriva kunder"
        case 'DASHBOARD_READ':
            return "Läsa översikt"
        case 'ORDER_READ':
            return "Läsa ordrar"
        case 'ORDER_WRITE':
            return "Skriva ordrar"
        case 'ORDER_UNLOCK':
            return "Låsa upp ordrar"
        case 'RENT_READ':
            return "Läsa hyresunderlag"
        case 'SUPPLIER_READ':
            return "Läsa leverantörer"
        case 'SUPPLIER_WRITE':
            return "Skriva leverantörer"
        case 'USER_READ':
            return "Läsa användar- och kundåtkomst"
        case 'USER_WRITE':
            return "Skriva användar- och kundåtkomst"

        default:
            return role;
    }
}

export default {
    SUPERADMIN: 'SUPERADMIN',

    ARTICLE_READ: 'ARTICLE_READ',
    ARTICLE_WRITE: 'ARTICLE_WRITE',

    ARTICLECATEGORY_READ: 'ARTICLECATEGORY_READ',
    ARTICLECATEGORY_WRITE: 'ARTICLECATEGORY_WRITE',

    CUSTOMER_READ: 'CUSTOMER_READ',
    CUSTOMER_WRITE: 'CUSTOMER_WRITE',

    DASHBOARD_READ: 'DASHBOARD_READ',

    ORDER_READ: 'ORDER_READ',
    ORDER_WRITE: 'ORDER_WRITE',
    ORDER_UNLOCK: 'ORDER_UNLOCK',

    RENT_READ: 'RENT_READ',

    SUPPLIER_READ: 'SUPPLIER_READ',
    SUPPLIER_WRITE: 'SUPPLIER_WRITE',

    USER_READ: 'USER_READ',
    USER_WRITE: 'USER_WRITE',

    PORTAL_USER: 'PORTAL_USER',

    getString: getString,
    getLabel: getLabel,

    getDependencyTree: () => {

        return [
            {
                name: 'DASHBOARD_READ',
                label: getLabel('DASHBOARD_READ'),
                dependencies: []
            },

            {
                name: 'CUSTOMER_READ',
                label: getLabel('CUSTOMER_READ'),
                dependencies: []
            },
            {
                name: 'CUSTOMER_WRITE',
                label: getLabel('CUSTOMER_WRITE'),
                dependencies: []
            },
            {
                name: 'SUPPLIER_READ',
                label: getLabel('SUPPLIER_READ'),
                dependencies: []
            },
            {
                name: 'SUPPLIER_WRITE',
                label: getLabel('SUPPLIER_WRITE'),
                dependencies: []
            },
            {
                name: 'ARTICLECATEGORY_READ',
                label: getLabel('ARTICLECATEGORY_READ'),
                dependencies: []
            },
            {
                name: 'ARTICLECATEGORY_WRITE',
                label: getLabel('ARTICLECATEGORY_WRITE'),
                dependencies: []
            },
            {
                name: 'ARTICLE_READ',
                label: getLabel('ARTICLE_READ'),
                dependencies: ['ARTICLECATEGORY_READ']
            },
            {
                name: 'ARTICLE_WRITE',
                label: getLabel('ARTICLE_WRITE'),
                dependencies: ['ARTICLECATEGORY_READ']
            },

            {
                name: 'ORDER_READ',
                label: getLabel('ORDER_READ'),
                dependencies: ['CUSTOMER_READ', 'SUPPLIER_READ', 'ARTICLECATEGORY_READ', 'ARTICLE_READ']
            },
            {
                name: 'ORDER_WRITE',
                label: getLabel('ORDER_WRITE'),
                dependencies: ['CUSTOMER_READ', 'SUPPLIER_READ', 'ARTICLECATEGORY_READ', 'ARTICLE_READ']
            },

            {
                name: 'USER_READ',
                label: getLabel('USER_READ'),
                dependencies: ['CUSTOMER_READ']
            },
            {
                name: 'USER_WRITE',
                label: getLabel('USER_WRITE'),
                dependencies: ['CUSTOMER_READ']
            },

            {
                name: 'RENT_READ',
                label: getLabel('RENT_READ'),
                dependencies: ['CUSTOMER_READ']
            },
        ]


    }

}