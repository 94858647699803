import moment from "moment";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {DataTable} from "primereact/datatable";
import {InputText} from "primereact/inputtext";
import {Row} from "primereact/row";
import React, {useContext, useState} from 'react';
import {withRouter} from 'react-router-dom';
import AppContext from "../../../AppContext";
import ExtraMenu from "../../../components/Grid/ExtraMenu";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import {GridTemplates} from "../../../GridTemplates";
import NetUtils from "../../../utils/NetUtils";
import {NumberUtils} from "../../../utils/NumberUtils";
import SearchUtils from "../../../utils/SearchUtils";


const ArticlesPerYearReport = props => {
    const NAME = 'ArticlesPerYearReport'
    const context = useContext(AppContext);
    const [filter, setFilter] = useState({
        startDate: context.getFilter(NAME, 'startDate', moment().set('date', 1).format("YYYY-MM-DD")),
        endDate: context.getFilter(NAME, 'endDate', moment().format("YYYY-MM-DD")),
        search: context.getFilter(NAME, 'search', '')
    });

    const [model, setModel] = useState({
        yearList: [],
        list: []
    });

    const matchSearch = customer => SearchUtils.isMatching(customer, filter.search);
    const filteredList = model?.list?.filter(c => matchSearch(c))


    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(Object.assign({}, filter));
    }

    // const onRowSelect = model => RouterUtils.navigateTo(props, '/financing/' + model.id);
    const onRowSelect = model => {
    };

    const sumByField = field => filteredList.reduce((sum, a) => sum += !isNaN(a[field]) ? a[field] : 0, 0);
    const totalQuantity = sumByField('quantity');

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={'Totalt (' + (model?.list.length || 0) + ' st)'}
                    style={{textAlign: 'left'}}/>
            <Column footer=''/>
            {
                model.yearList.map(year =>
                    <Column key={year} footer={NumberUtils.format(sumByField('quantity' + year))}
                            style={{textAlign: 'right'}}/>
                )
            }
            <Column footer={NumberUtils.format(totalQuantity)} style={{textAlign: 'right'}}/>
        </Row>
    </ColumnGroup>;

    const loadData = async () => {
        showLoading()
        const _res = await NetUtils.doGet('/api/reports/articles-per-year')
        _res.list.forEach(item => {
            _res.yearList.forEach(year => {
                item['quantity' + year] = item.yearQuantityMap[year]
            })

        })
        setModel(_res)
        hideLoading()
    }

    const columns = [
        {field: 'articleName', header: 'Artikel'},
        {field: 'articleNumber', header: 'Art.nr', style: {width: '130px'}},
    ];

    if (model) {
        model.yearList.forEach(year => {
            columns.push({
                field: 'quantity' + year,
                header: '' + year,
                style: {width: '70px', textAlign: 'right'},
                body: GridTemplates.integer
            })
        })
    }

    columns.push({
        field: 'quantity',
        header: 'Totalt',
        style: {width: '70px', textAlign: 'right', fontWeight: 'bold'},
        body: GridTemplates.integer
    })

    return <div className="Grid" style={{padding: '10px 0'}}>

        <div className="filter">
            {/*<div>*/}
            {/*    <label className="label">Startdatum</label>*/}
            {/*    <input type="date" value={filter.startDate}*/}
            {/*           onChange={e => {*/}
            {/*               updateFilter('startDate', e.target.value)*/}
            {/*           }}/>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <label className="label">Slutdatum</label>*/}
            {/*    <input type="date" value={filter.endDate}*/}
            {/*           onChange={e => updateFilter('endDate', e.target.value)}/>*/}
            {/*</div>*/}

            <div>
                <label className="label">&nbsp;</label>
                <input type="button" onClick={() => loadData()} value='Skapa rapport'/>
            </div>


            <div style={{flexGrow: 1, padding: 0}}></div>

            <div>
                <label className="label">Sök</label>
                <InputText className="search" type="search" defaultValue={filter.search}
                           onInput={(e) => updateFilter('search', e.target.value)}/>
            </div>

            <div style={{paddingRight: 0}}>
                <label className="label">&nbsp;</label>
                <ExtraMenu list={filteredList}
                           onExportToExcel={() => NetUtils.exportToExcel('Artiklar per år', columns, filteredList)}/>
            </div>
        </div>

        <DataTable className="dataTable"
                   value={filteredList}
                   resizableColumns={true}
                   columnResizeMode="expand"
                   paginator={false}
                   footerColumnGroup={footerGroup}
                   selectionMode="single"
                   onRowSelect={e => onRowSelect(e.data)}
                   scrollable={true} scrollHeight="calc(100vh - 310px)">
            {
                columns.map(c => <Column key={c.field}
                                         field={c.field}
                                         header={c.header}
                                         style={c.style}
                                         body={c.body}
                                         sortable={true}
                />)
            }
        </DataTable>

    </div>
}

export default withRouter(ArticlesPerYearReport);
