import React from 'react';
import {Dropdown} from "primereact/dropdown";

import './OrderStatus.scss'

const OrderStatus = props => {
    let list = props.showAll
        ? [{
            value: 0,
            label: "Alla",
            labelMultiple: 'Alla (ej makulerade)'
        }]
        : [];

    list = list.concat(OrderStatus.LIST);

    let optionLabel = props.multipleLabel ? 'labelMultiple' : 'label';

    return (
        <div className="OrderStatus">
            <Dropdown
                value={props.value}
                options={list}
                optionLabel={optionLabel}
                onChange={e => (props.onChange(e))}
                required={true}/>
        </div>
    );
}

OrderStatus.WAITING_FOR_CONFIRMATION = 10000;
OrderStatus.DELIVERED = 10004;
OrderStatus.CANCELED = 10001;

OrderStatus.LIST = [{
    value: OrderStatus.WAITING_FOR_CONFIRMATION,
    label: 'Väntar på bekräftelse',
    labelMultiple: 'Väntar på bekräftelse'
}, {
    value: OrderStatus.DELIVERED,
    label: "Levererad",
    labelMultiple: 'Levererade'
}, {
    value: OrderStatus.CANCELED,
    label: "Makulerad",
    labelMultiple: 'Makulerade'
}];

OrderStatus.getName = id => {
    const res = OrderStatus.LIST.find(os => os.value === id);
    return res ? res.label : '';
}

export default OrderStatus;