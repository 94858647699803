const NumberUtil = {
    formatNumber: (value, numOfDecimals) => isNaN(value) || value === 0
        ? ''
        : value.toLocaleString(undefined, {
            minimumFractionDigits: numOfDecimals,
            maximumFractionDigits: numOfDecimals
        }),

    formatCurrency: (value, numOfDecimals) => {
        let res = NumberUtil.formatNumber(value, numOfDecimals);
        return res.length > 0 ? res + ' kr' : ''
    },

    formatWeight: (value, numOfDecimals) => {
        let res = NumberUtil.formatNumber(value, numOfDecimals);
        return res.length > 0 ? res + ' kg' : ''
    }
};

export default NumberUtil