import ArticleGrid from "./ArticleGrid";
import ArticleEditForm from "./edit/ArticleEditForm";
import Role from "../Users/Role";

export default {
    routeProps: {
        path: '/articles',
        exact: true,
        component: ArticleGrid
    },
    name: 'Artiklar',
    nameSingular: 'Artikel',
    icon: 'pi pi-fw pi-list',

    subModules: [{
        routeProps: {
            path: '/articles/:id',
            exact: true,
            component: ArticleEditForm,
        },
        name: 'Redigera artikel',
    }],
    roles: [Role.ARTICLE_READ, Role.ARTICLECATEGORY_READ]
}