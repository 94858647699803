import {matchPath} from "react-router-dom";

/**
 * XLNZ COMMON REACT - Do not edit elsewhere
 */

/**
 * Path example: '/customers/:id'
 */
const getIdFromPath = (props, path) => Number(matchPath(props.location.pathname, {
    path: path,
    exact: true,
    strict: true
}).params.id)

const navigateTo = (props, url) => props.history.push({
    pathname: url
});

const goBack = props => props.history.goBack();

// const reload = props => window.location = '/new/';
const reload = props => window.location = '/';

export default {
    getIdFromPath,
    navigateTo,
    goBack,
    reload
}

