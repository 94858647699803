import React, {useState} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {GridTemplates} from "../../../GridTemplates";
import MonthDepreciationEditFormDialog from "./MonthDepreciationEditFormDialog";
import moment from "moment";

const MonthDepreciationGrid = props => {
    const [modelToEdit, setModelToEdit] = useState();

    return <div>
        <DataTable value={props.value} style={{width: '250px'}}
                   selectionMode="single"
                   onSelectionChange={e => setModelToEdit(e.value)}>

            <Column field="startTime" header="Startdatum" className='alignLeft'
                body={row => moment(row.startTime).format('yyyy-MM-DD')}/>

            <Column field="value" header="Antal månader"
                    headerClassName='alignRight'
                    className='alignRight'
                    style={{width: '120px'}}
                    body={GridTemplates.numberTwoDecimals}
            />
        </DataTable>

        <Button icon="pi pi-plus" className="p-button-secondary" onClick={e => {
            e.preventDefault();
            setModelToEdit({
                article: props.parentId ? props.parentId : 0,
                startDate: moment().format('YYYY-MM-DD'),
                value: 0,
                newPrice: true
            });
        }}/>

        {
            modelToEdit &&
            <MonthDepreciationEditFormDialog visible={modelToEdit !== undefined}
                                             model={modelToEdit}
                                             onSave={savedModel => {
                                            console.log("modelToEditmodelToEdit", modelToEdit)
                                            if (modelToEdit.newPrice) {
                                                delete modelToEdit.newPrice;
                                                let newList = [...props.value];
                                                newList.push(savedModel);
                                                props.onChange(newList);
                                            }
                                            setModelToEdit(undefined);
                                        }}
                                             onRemove={removeModel => {
                                            let newList = [...props.value]
                                            newList.splice(
                                                props.value.findIndex(d => d.id === removeModel.id),
                                                1
                                            );
                                            props.onChange(newList);

                                            setModelToEdit(undefined);
                                        }}
                                             onHide={() => setModelToEdit(undefined)}/>
        }
    </div>
}

export default MonthDepreciationGrid;
