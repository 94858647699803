import React, {useContext, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import RouterUtils from "../../../utils/RouterUtils";
import ErrorText from "../../../components/EditForm/ErrorText";
import AppContext from "../../../AppContext";
import FinancingService from "../FinancingService";
import FinancierService from "../../Financiers/FinancierService";
import ValidateUtils from "../../../utils/ValidateUtils";
import moment from "moment";
import MonthDepreciationGrid from "./InterestRateGrid";
import ArrayUtils from "../../../utils/ArrayUtils";
import {dynamicSort} from "../../../utils/SortUtils";
import FinancingappendixService from "../FinancingappendixService";
import AppendixGrid from "./AppendixGrid";

const FinancingEditForm = props => {
    const context = useContext(AppContext);
    const [model, setModel] = useState({id: 0});
    const [financierList, setFinancierList] = useState();
    const [interestRateList, setInterestRateList] = useState([]);
    const [appendixList, setAppendixList] = useState([]);

    const disabled = false;

    const {register, errors, handleSubmit, reset, formState, getValues} = useForm({
        defaultValues: {
            name: '',
            vatNumber: '',
            address: '',
            zip: '',
            city: '',
            active: true
        }
    });
    const {isSubmitting} = formState;

    const onSubmit = data => {
        data.interestRates = interestRateList.map(ap => moment(ap.startTime).format('yyyy-MM-DD') + '_' + ap.value).join(';')

        appendixList.forEach((a, i) => {
            data['appendixId' + i] = a.id;
            data['appendixContentId' + i] = a.content;
            data['appendixNumber' + i] = i;
            data['appendixAttach' + i] = 0;
        });

        Object.assign(model, data);
        return FinancingService.update(model).then(savedModel => {
            RouterUtils.goBack(props);
        });
    };

    const saveData = async data => {
        data.interestRates = interestRateList.map(ap => moment(ap.startTime).format('yyyy-MM-DD') + '_' + ap.value).join(';')

        appendixList.forEach((a, i) => {
            data['appendixId' + i] = a.id;
            data['appendixContentId' + i] = a.content;
            data['appendixNumber' + i] = i;
            data['appendixAttach' + i] = 0;
        });

        Object.assign(model, data);
        return FinancingService.update(model);
    };

    const onPaymentsClick = e => {
        const data = getValues();


        console.log("onPaymentsClick", model, data)


        saveData(data).then(_model => {
            RouterUtils.navigateTo(props, '/financing/' + _model.id + '/payments')
        })
    }

    useEffect(() => {
        let promises = [
            FinancierService.findByCompany(context.currentCompany.id),
        ];

        let id = RouterUtils.getIdFromPath(props, '/financing/:id');
        if (id > 0) {
            promises.push(FinancingService.findById(id));
            promises.push(FinancingappendixService.findByFinancing(id));
        }

        Promise.all(promises).then(values => {
            setFinancierList(values[0]);

            let m;
            if (id > 0) {
                m = values[1];
                m.startDate = moment(m.startDate).format('yyyy-MM-DD');
                m.endDate = moment(m.endDate).format('yyyy-MM-DD');
                setModel(m);

                const _interestRateList = ArrayUtils.transformToArray(m.interestRateList.doublevalue)
                _interestRateList.sort(dynamicSort("startTime"));
                setInterestRateList(_interestRateList);


                const _appendixList = ArrayUtils.transformToArray(values[2])
                _appendixList.sort(dynamicSort("listOrder"));
                setAppendixList(_appendixList);

                reset(m);
            }
        });
    }, [props, reset, context.currentCompany])

    return (
        <div className="EditForm">
            {model && financierList &&
            <div>
                <div className="headline">
                    <h1>Finansiering</h1>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="ui-dialog-buttonpane p-clearfix button-row">
                        <div className='left-buttons'>
                            {!disabled &&
                            <input type='submit' value='Spara' disabled={isSubmitting} className='save-btn'/>}
                            <input type='button' value='Avbryt' onClick={e => RouterUtils.goBack(props)}/>
                            <div style={{width: '50px'}}></div>
                            <input type='button' value='Betalningar' onClick={e => onPaymentsClick(e)}/>
                        </div>
                    </div>


                    <div className='formOfThree'>
                        <div className='column'>
                            <div className="formItem">
                                <label htmlFor='type'>Typ</label>
                                <select name="type" ref={register()}>
                                    <option value='10004'>Hyrköp</option>
                                    <option value='10003'>Leasing</option>
                                    <option value='10001'>Lån</option>
                                </select>
                            </div>

                            <div className="formItem">
                                <label htmlFor='financier'>Finansiär</label>
                                <select name="financier"
                                        ref={register({
                                            required: ValidateUtils.required()
                                        })}
                                        disabled={disabled}>
                                    {financierList.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                                </select>
                                <ErrorText errors={errors} name="financier"/>
                            </div>

                            <div className="formItem">
                                <label htmlFor='paymentplan'>Betalningsplan</label>
                                <select name="paymentplan" ref={register()}>
                                    <option value='10000'>Rak</option>
                                    <option value='10001'>Annuitet</option>
                                </select>
                            </div>

                            <div className="formItem">
                                <label htmlFor='number'>Referens</label>
                                <input name="number"
                                       ref={register({
                                           maxLength: {
                                               value: 128,
                                               message: "Max 128 tecken"
                                           }
                                       })}
                                       style={{width: '300px'}}
                                       disabled={disabled}
                                />
                                <ErrorText errors={errors} name="number"/>
                            </div>

                            <div className="formItem">
                                <label htmlFor='startDate'>Startdatum</label>
                                <input type='date' name="startDate"
                                       ref={register({
                                           required: ValidateUtils.required(),
                                           pattern: ValidateUtils.datePattern()
                                       })}
                                       readOnly={disabled}
                                />
                                <ErrorText errors={errors} name="startDate"/>
                            </div>

                            <div className="formItem">
                                <label htmlFor='endDate'>Slutdatum</label>
                                <input type='date' name="endDate"
                                       ref={register({
                                           required: ValidateUtils.required(),
                                           pattern: ValidateUtils.datePattern()
                                       })}
                                       readOnly={disabled}
                                />
                                <ErrorText errors={errors} name="endDate"/>
                            </div>
                        </div>

                        <div className='column'>
                            <div className="formItem">
                                <label htmlFor='numOfPayments'>Antal betalningar</label>
                                <input type='number' name="numOfPayments"
                                       ref={register({
                                           pattern: ValidateUtils.numberPattern
                                       })}
                                       style={{width: '80px'}}
                                       disabled={disabled}
                                />
                                <ErrorText errors={errors} name="numOfPayments"/>
                            </div>

                            <div className="formItem">
                                <label htmlFor='price'>Köpesumma</label>
                                <input type='number' step='any' name="price"
                                       ref={register({
                                           pattern: ValidateUtils.numberPattern
                                       })}
                                       style={{width: '120px'}}
                                       disabled={disabled}
                                />
                                <ErrorText errors={errors} name="price"/>
                            </div>

                            <div className="formItem">
                                <label htmlFor='firstPaymentPrice'>Första förhöjd hyra</label>
                                <input type='number' step='any' name="firstPaymentPrice"
                                       ref={register({
                                           pattern: ValidateUtils.numberPattern
                                       })}
                                       style={{width: '120px'}}
                                       disabled={disabled}
                                />
                                <ErrorText errors={errors} name="firstPaymentPrice"/>
                            </div>

                            <div className="formItem">
                                <label htmlFor='lastPaymentPrice'>Restvärde</label>
                                <input type='number' step='any' name="lastPaymentPrice"
                                       ref={register({
                                           pattern: ValidateUtils.numberPattern
                                       })}
                                       style={{width: '120px'}}
                                       disabled={disabled}
                                />
                                <ErrorText errors={errors} name="lastPaymentPrice"/>
                            </div>

                            <div className="formItem">
                                <label htmlFor='startExchangeRate'>Valutakurs</label>
                                <input type='number' step='any' name="startExchangeRate"
                                       ref={register({
                                           pattern: ValidateUtils.numberPattern
                                       })}
                                       style={{width: '80px'}}
                                       disabled={disabled}
                                />
                                <ErrorText errors={errors} name="startExchangeRate"/>
                            </div>
                        </div>

                        <div className='column'>
                            <div className="formItem">
                                <label htmlFor='pledged'>Belånad</label>
                                <input type='checkbox' name="pledged" ref={register} disabled={disabled}/>
                            </div>

                            <div className="formItem">
                                <label htmlFor='showInGraph'>Visa i översikt</label>
                                <input type='checkbox' name="showInGraph" ref={register} disabled={disabled}/>
                            </div>

                            <div className="formItem">
                                <label htmlFor='active'>Aktiv</label>
                                <input type='checkbox' name="active" ref={register} disabled={disabled}/>
                            </div>
                        </div>
                    </div>

                </form>

                <div className="formItem">
                    <label htmlFor='divisions'>Räntesats per månad</label>
                    <MonthDepreciationGrid parentId={model.id}
                                           value={interestRateList}
                                           onChange={newList => setInterestRateList(newList)}/>
                </div>

                <div className="formItem">
                    <label htmlFor='divisions'>Bilagor & dokument</label>
                    <AppendixGrid value={appendixList}
                                  onChange={newList => setAppendixList(newList)}/>
                </div>

            </div>
            }
        </div>
    );
}

export default FinancingEditForm;
