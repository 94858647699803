import NetUtils from "../../utils/NetUtils";

export default {
    findAll: async () => {
        const res = await NetUtils.doGet('/GetCompanies')
        if (res.company.length) {
            return res.company
        } else {
            return [res.company]
        }
    }
}