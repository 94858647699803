import SupplierGrid from "./SupplierGrid";
import SupplierEditForm from "./SupplierEditForm";

export default {
    routeProps: {
        path: '/suppliers',
        exact: true,
        component: SupplierGrid,
    },
    name: 'Leverantörer',
    nameSingular: 'Leverantör',
    showHeadline: true,
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/suppliers/:id',
            exact: true,
            component: SupplierEditForm,
        }
    }],
};