import React, {Component} from 'react';
import AppContext from "./AppContext";
import './App.scss';
import './components/Grid/Grid.scss';

import {HashRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import moment from 'moment';
import 'moment/locale/sv';
import SignInScreen from "./components/SignInScreen/SignInScreen";
import Header from "./components/Header/Header";
import {modules, settingModules} from "./modules/modules";
import {SecurityService} from "./security/SecurityService";
import CompanyService from "./modules/Companies/CompanyService";
import RouterUtils from "./utils/RouterUtils";

class App extends Component {
    constructor(props) {
        super();
        moment.locale('sv');
        window.SERVER_URL = ''
        SecurityService.init();


        this.state = {
            initialized: false,
            user: undefined,
            companies: undefined,
            currentCompany: undefined,
            newVersionAvailable: false,

            signIn: (companies) => {
                if (companies.length === 0) {
                    alert("Du har inte tillgång till några företag.")
                    localStorage.removeItem("userEmail");
                    localStorage.removeItem("userUid");
                    localStorage.removeItem("userCert");
                    RouterUtils.reload(this.props);
                }

                var currentCompany;
                let companyId = localStorage.getItem('company');
                if (companyId > 0) {
                    var c = companies.find(r => r.id === Number(companyId));
                    currentCompany = c;
                }
                if (!currentCompany) {
                    currentCompany = companies[0];
                }
                console.log("Selected company:", currentCompany.name);
                localStorage.setItem("company", currentCompany.id);


                this.setState({
                    initialized: true,
                    user: {
                        email: localStorage.getItem("userEmail"),
                    },
                    companies: companies,
                    currentCompany: currentCompany
                })
            },
            signOut: () => {
                localStorage.removeItem("userEmail");
                localStorage.removeItem("userUid");
                localStorage.removeItem("userCert");
                RouterUtils.reload(this.props);
            },
            setFilter: (gridName, name, value) => {
                let key = gridName + '-' + name;
                console.log("Setting filter:", key, 'to', value);
                let sessionFilter = sessionStorage.getItem('filter');
                if (!sessionFilter) {
                    sessionFilter = {}
                } else {
                    sessionFilter = JSON.parse(sessionFilter);
                }
                sessionFilter[key] = value;
                sessionStorage.setItem('filter', JSON.stringify(sessionFilter));

                this.setState({[key]: value});
            },
            getFilter: (gridName, name, defaultValue) => {
                let key = gridName + '-' + name;
                let result = defaultValue;
                if (this.state[key] !== undefined) {
                    result = this.state[key];
                } else {
                    let sessionFilter = JSON.parse(sessionStorage.getItem('filter'));
                    if (sessionFilter && sessionFilter[key] !== undefined) {
                        result = sessionFilter[key];
                    }
                }
                return result;
            },
        }
    }

    componentDidMount() {
        // UserService.getCurrentUser().then(async user => {
        //     if (user) {
        //         console.log("Logged in user:", user.email)
        //         Promise.all([
        //             UserService.getRoles(),
        //             new CompanyService().findAll()
        //         ]).then(([_roles, _companies]) => this.state.signIn(user, _roles, _companies))
        //     } else {
        //         this.setState({initialized: true})
        //     }
        // }, () => {
        //     this.setState({initialized: true})
        // });

        if (localStorage.getItem("userEmail")) {

            console.log("localStorage.getItem(\"userEmail\")", localStorage.getItem("userEmail"))
            CompanyService.findAll().then(_companyList => {
                this.state.signIn(_companyList);
            })
        } else {
            this.setState({initialized: true})
        }
    }

    isModuleAccessible = m => {
        return true;
    }

    render() {
        let view;
        if (!this.state.user && this.state.initialized) {
            return <SignInScreen/>
        } else if (this.state.user && this.state.initialized && !this.state.companies) {
            view = <h1>Du har inte tillgång till några företag</h1>
        } else if (!this.state.initialized) {
            view = <div>Laddar...</div>
        } else if (this.state.user && this.state.companies && this.state.companies.length > 0) {
            view = <Router>
                <div id='App' className="App">
                    <div className="container">
                        <Header/>
                        <div className="content">
                            <Switch>
                                {modules
                                    .filter(m => this.isModuleAccessible(m))
                                    .map(m => <Route {...m.routeProps} key={m.routeProps.path}/>)}
                                {modules
                                    .filter(m => this.isModuleAccessible(m))
                                    .filter(m => m.subModules)
                                    .map(m => m.subModules.map(sub => <Route {...sub.routeProps}
                                                                             key={sub.routeProps.path}/>))}

                                {settingModules
                                    .filter(m => this.isModuleAccessible(m))
                                    .map(m => <Route {...m.routeProps} key={m.routeProps.path}/>)}
                                {settingModules
                                    .filter(m => this.isModuleAccessible(m))
                                    .filter(m => m.subModules)
                                    .map(m => m.subModules.map(sub => <Route {...sub.routeProps}
                                                                             key={sub.routeProps.path}/>))}

                                <Redirect exact from="/" to={'/dashboard'}/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </Router>
        } else {
            view = <SignInScreen/>
        }

        let metaTags = <MetaTags>
            <title>Nobius Logistics - Tillgångar</title>
            <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'/>
            <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet"/>
        </MetaTags>

        return (
            <AppContext.Provider value={this.state}>
                {metaTags}
                {view}
            </AppContext.Provider>
        );
    }
}

export default App;