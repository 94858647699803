import React from 'react';
import {ErrorMessage} from "@hookform/error-message";
import './ErrorText.scss';

const ErrorText = props =>
    <div className='ErrorText'>
        <ErrorMessage {...props} />
    </div>

export default ErrorText;
