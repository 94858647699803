import Dashboard from './Dashboard';
import Articles from './Articles';
import Financing from './Financing';
import Acquisitions from './Acquisitions';
import Divestments from './Divestments';
import Reports from './Reports';

import Acquisitiontypes from './Acquisitiontypes';
import Articlecategories from './Articlecategories';
import Customers from './Customers';
import Suppliers from './Suppliers';
import Financiers from './Financiers';

export const modules = [
    Dashboard,
    Articles,
    Financing,
    Acquisitions,
    Divestments,
    Reports
];

export const settingModules = [
    Acquisitiontypes,
    Articlecategories,
    Customers,
    Suppliers,
    Financiers,
];

export function getModule(path) {
    let m = modules.find(m => m.routeProps.path === path);

    if (!m) {
        m = settingModules.find(m => m.routeProps.path === path);
    }

    return m;
}