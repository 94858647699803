import React, {useContext, useState, useEffect} from 'react';
import {Dialog} from "primereact/dialog";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {InputText} from "primereact/inputtext";
import AppContext from "../../../AppContext";
import SearchUtils from "../../../utils/SearchUtils";
import {GridTemplates} from "../../../GridTemplates";
import ArticleService from "../../Articles/ArticleService";
import DivestmentService from "../DivestmentService";

const DivestmentAddFromArticlesDialog = props => {
    const NAME = 'DivestmentSelectArticlesDialog';
    const context = useContext(AppContext);

    const [filter, setFilter] = useState({
        method: context.getFilter(NAME, 'method', 'fifo'),
        search: context.getFilter(NAME, 'search', ''),
        pageFirst: context.getFilter(NAME, 'pageFirst', 0),
        pageRows: context.getFilter(NAME, 'pageRows', 50)
    });

    const [articleList, setArticleList] = useState([]);

    const acquisitiontype = props.acquisitiontype;

    const matchSearch = article => SearchUtils.isMatching(article, filter.search);
    const filteredList = articleList.filter(a => matchSearch(a))

    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(filter);
    }

    const onQuantityChange = (article, value) => {
        let q = Number(value);
        if (q < 0) {
            q = 0;
        } else if (q > article.stockQuantity) {
            q = article.stockQuantity;
        }
        article.quantity = q;
        setArticleList([...articleList]);
    }

    const onSave = () => {
        const articlesWithQuantity = articleList.filter(a => a.quantity > 0);
        const params = {
            postData: articlesWithQuantity.map(a => a.id + ':' + a.quantity).join(';'),
            fetchType: filter.method
        }
        DivestmentService.getDivestmentrows(params).then(rows => {
            props.onSave(rows)
        })

    };


    useEffect(() => {
        ArticleService.findAll({
            acquisitiontype: acquisitiontype,
            onlyWithStockQuantity: 1,
            excludePledged: 1,
            active: 1
        }).then(_articleList => {
            _articleList.forEach(a => a.quantity = 0);
            setArticleList(_articleList)
        });


    }, [acquisitiontype]);

    return <Dialog className='dialog'
                   header='Lägg till artiklar'
                   visible={props.visible}
                   onHide={props.onHide}
                   style={{width: '900px'}}>
        <div className="Grid" style={{minHeight: '900px'}}>
            <div className="filter">
                <div>
                    <label className="label">Metod</label>
                    <select defaultValue={filter.method} onChange={e => updateFilter('method', e.target.value)}>
                        <option value='fifo'>FIFO</option>
                        <option value='lifo'>LIFO</option>
                        <option value='bookkeepingMax'>Maximalt bokfört värde</option>
                        <option value='bookkeepingMin'>Minimalt bokfört värde</option>
                    </select>
                </div>

                <div style={{flexGrow: 1, padding: 0}}></div>

                <div>
                    <label className="label">Sök</label>
                    <InputText className="search" type="search" defaultValue={filter.search}
                               onInput={(e) => updateFilter('search', e.target.value)}/>
                </div>
            </div>

            <DataTable className="dataTable"
                       value={filteredList}
                       resizableColumns={true}
                       columnResizeMode="expand"
                       scrollable={true}
                       scrollHeight="calc(100vh - 380px)"

                       paginator={true} rows={filter.pageRows} first={filter.pageFirst}
                       onPage={e => {
                           updateFilter('pageFirst', e.first);
                           updateFilter('pageRows', e.rows);
                       }}
                       rowsPerPageOptions={[10, 50, 100, 300, 1000]}
            >
                <Column field='numberAndName' header='Article' sortable={true}
                        body={row => row.number + " - " + row.name}/>
                <Column field='description' header='Beskrivning' sortable={true}/>

                <Column field='stockQuantity' header='Lagersaldo' sortable={true} body={GridTemplates.integer}
                        style={{width: '110px', textAlign: 'right'}}
                />
                <Column field='quantity' header='Antal' sortable={true} body={GridTemplates.integer}
                        style={{width: '80px', textAlign: 'right'}}
                        editor={e => <InputText type="number" value={e.rowData.quantity !== 0 ? e.rowData.quantity : ''}
                                                onChange={e2 => onQuantityChange(e.rowData, e2.target.value)}
                                                style={{textAlign: 'right'}}/>}
                />
            </DataTable>

            <div style={{textAlign: 'right', paddingTop: '10px'}}>
                <input type='submit' value='Spara' className='save-btn' onClick={e => onSave()}/>
                <input type='button' value='Avbryt' onClick={e => props.onHide()}
                       style={{margin: '0'}}/>
            </div>
        </div>
    </Dialog>
}

export default DivestmentAddFromArticlesDialog;
