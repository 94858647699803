import {cloneElement} from "react";
import '../../App.scss'
import ReactDOM from "react-dom";

export default function renderOverlay(component) {
    const renderContainer = document.createElement('div')
    const id = makeId()

    renderContainer.setAttribute('id', id)

    document.body.appendChild(renderContainer)
    // const root = createRoot(renderContainer)
    const unmountModal = () => {
        // root.unmount()
        ReactDOM.unmountComponentAtNode(renderContainer)
        // renderContainer.parentNode.removeChild(renderContainer)
    }

    const props = {}
    if (component.type !== 'div') {
        props.onHide = unmountModal
    }

    // root.render(cloneElement(component, props))
    ReactDOM.render(cloneElement(component, props), renderContainer);

    return id
}

export function removeOverlay(elementId) {
    const element = document.getElementById(elementId)
    document.body.removeChild(element)
}


let counter = 0
const makeId = () => 'GEN' + (++counter)