import React, {useState, useEffect} from 'react';
import {useForm} from "react-hook-form";
import RouterUtils from "../../utils/RouterUtils";
import ErrorText from "../../components/EditForm/ErrorText";
import ValidateUtils from "../../utils/ValidateUtils";
import CustomerService from "./CustomerService";

const CustomerEditForm = props => {
    const [model, setModel] = useState({
        id: 0
    });
    const disabled = false;

    const {register, errors, handleSubmit, reset, formState} = useForm({
        defaultValues: {
            name: '',
            active: true
        }
    });
    const {isDirty, isSubmitting} = formState;

    const onSubmit = data => {
        if (!isDirty) return RouterUtils.goBack(props);

        Object.assign(model, data);
        return CustomerService.update(model).then(savedModel => {
            RouterUtils.goBack(props);
        });
    };

    useEffect(() => {
        let id = RouterUtils.getIdFromPath(props, '/customers/:id');
        if (id > 0) {
            Promise.all([
                CustomerService.findById(id),
            ]).then(values => {
                setModel(values[0]);
                reset(values[0]);
            });
        }
    }, [props, reset])

    return (
        <div className="EditForm">
            <div className="headline">
                <h1>Kund</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="ui-dialog-buttonpane p-clearfix button-row">
                    <div className='left-buttons'>
                        {!disabled && <input type='submit' value='Spara' disabled={isSubmitting}/>}
                        <input type='button' value='Avbryt' onClick={e => RouterUtils.goBack(props)}/>
                    </div>
                </div>


                <div className="formItem">
                    <label htmlFor='name'>Namn</label>
                    <input name="name"
                           ref={register({
                               required: ValidateUtils.required(),
                               maxLength: ValidateUtils.maxLength(45)
                           })}
                           style={{width: '350px'}}
                           disabled={disabled}
                    />
                    <ErrorText errors={errors} name="name"/>
                </div>
                <div className="formItem">
                    <label htmlFor='registrationNumber'>Org.nr</label>
                    <input name="registrationNumber"
                           ref={register()}
                           style={{width: '120px'}}
                           disabled={disabled}
                    />
                    <ErrorText errors={errors} name="registrationNumber"/>
                </div>
                <div className="formItem">
                    <label htmlFor='active'>Aktiv</label>
                    <input type='checkbox' name="active" ref={register} disabled={disabled}/>
                </div>
            </form>
        </div>
    );
}

export default CustomerEditForm;
