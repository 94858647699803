import React from 'react';
import './Spinner.scss';
import {ProgressSpinner} from "primereact/progressspinner";

const Spinner = () => {
    return (
        <div className="Spinner">
            {/*<div className="frame">*/}
                {/*<img src='/spinner.svg' alt='Loading...' />*/}
                <ProgressSpinner/>
            {/*</div>*/}
        </div>
    )
}
export default Spinner;