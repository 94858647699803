import moment from "moment";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Row} from "primereact/row";
import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import AppContext from "../../../AppContext";
import ExtraMenu from "../../../components/Grid/ExtraMenu";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import {GridTemplates} from "../../../GridTemplates";
import NetUtils from "../../../utils/NetUtils";
import {NumberUtils} from "../../../utils/NumberUtils";
import SearchUtils from "../../../utils/SearchUtils";
import {dynamicSort} from "../../../utils/SortUtils";
import DepreciationPerAccountService from "./DepreciationPerAccountService";


const DepreciationPerAccountReport = props => {
    const NAME = 'DepreciationPerAccountReport'
    const context = useContext(AppContext);
    const [filter, setFilter] = useState({
        startDate: context.getFilter(NAME, 'startDate', moment().set('date', 1).format("YYYY-MM-DD")),
        endDate: context.getFilter(NAME, 'endDate', moment().format("YYYY-MM-DD")),
        account: context.getFilter(NAME, 'account', 0),
        search: context.getFilter(NAME, 'search', '')
    });

    const [list, setList] = useState([]);
    const [accountList, setAccountList] = useState([]);

    const matchSearch = customer => SearchUtils.isMatching(customer, filter.search);
    const filteredList = list.filter(c => matchSearch(c))


    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(Object.assign({}, filter));
    }

    // const onRowSelect = model => RouterUtils.navigateTo(props, '/financing/' + model.id);
    const onRowSelect = model => {
    };

    const sumByField = field => filteredList.reduce((sum, a) => sum += !isNaN(a[field]) ? a[field] : 0, 0);
    const totalAcquisitionValueIn = sumByField('acquisitionValueIn');
    const totalDepreciationIn = sumByField('depreciationIn');
    const totalDivestedValuePeriod = sumByField('divestedValuePeriod');
    const totalDepreciationDeviation = sumByField('depreciationDeviation');
    const totalAcquisitionPeriod = sumByField('acquisitionPeriod');
    const totalDepreciationPeriod = sumByField('depreciationPeriod');
    const totalAcquisitionOut = sumByField('acquisitionOut');
    const totalDepreciationOut = sumByField('depreciationOut');

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={'Totalt (' + list.length + ' st)'}
                    style={{textAlign: 'left'}}/>
            <Column footer=''/>
            <Column footer={NumberUtils.formatCurrency(totalAcquisitionValueIn)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalDepreciationIn)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalDivestedValuePeriod)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalDepreciationDeviation)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalAcquisitionPeriod)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalDepreciationPeriod)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalAcquisitionOut)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalDepreciationOut)} style={{textAlign: 'right'}}/>
        </Row>
    </ColumnGroup>;

    useEffect(() => {
        DepreciationPerAccountService.findAccounts().then(_accountList => {
            setAccountList(_accountList)
        })
    }, []);

    const loadData = () => {
        showLoading()
        DepreciationPerAccountService.findByintervalAndAccount(filter.startDate, filter.endDate, filter.account).then(res => {
            res.forEach(p => {
                p.id = p.acquisition.id;
                p.name = p.acquisition.name;
                p.financingString = p.acquisition.financingString;
                p.acquisitionPeriod = p.acquisitionOut - p.acquisitionValueIn + p.divestedValuePeriod;
            })
            res.sort(dynamicSort('name'))
            setList(res);
            hideLoading()
        });
    }

    const columns = [
        {field: 'name', header: 'Anskaffning'},
        {field: 'financingString', header: 'Finansiering', style: {width: '130px'}},
        {
            field: 'acquisitionValueIn',
            header: 'IB ansk.värde',
            style: {width: '130px', textAlign: 'right'},
            body: GridTemplates.currency
        }, {
            field: 'depreciationIn',
            header: 'IB ack.avskr.',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.currency
        }, {
            field: 'divestedValuePeriod',
            header: 'Avyttrat ansk.värde',
            style: {width: '150px', textAlign: 'right'},
            body: GridTemplates.currency
        }, {
            field: 'depreciationDeviation',
            header: 'Avyttrad avskr.',
            style: {width: '130px', textAlign: 'right'},
            body: GridTemplates.currency
        }, {
            field: 'acquisitionPeriod',
            header: 'Periodens ansk.',
            style: {width: '130px', textAlign: 'right'},
            body: GridTemplates.currency
        }, {
            field: 'depreciationPeriod',
            header: 'Periodens avskr.',
            style: {width: '130px', textAlign: 'right'},
            body: GridTemplates.currency
        }, {
            field: 'acquisitionOut',
            header: 'UB ansk.värde',
            style: {width: '130px', textAlign: 'right'},
            body: GridTemplates.currency
        }, {
            field: 'depreciationOut',
            header: 'UB ack avskr.',
            style: {width: '130px', textAlign: 'right'},
            body: GridTemplates.currency
        },
    ];

    return <div className="Grid" style={{padding: '10px 0'}}>

        <div className="filter">
            <div>
                <label className="label">Startdatum</label>
                <input type="date" value={filter.startDate}
                       onChange={e => {
                           updateFilter('startDate', e.target.value)
                       }}/>
            </div>
            <div>
                <label className="label">Slutdatum</label>
                <input type="date" value={filter.endDate}
                       onChange={e => updateFilter('endDate', e.target.value)}/>
            </div>
            <div>
                <label className="label">Konto</label>
                <Dropdown
                    value={filter.account}
                    options={accountList}
                    placeholder='Välj konto...'
                    onChange={e => updateFilter('account', e.value)}
                    style={{width: '100px'}}/>
            </div>

            <div>
                <label className="label">&nbsp;</label>
                <input type="button" onClick={() => loadData()} value='Skapa rapport' disabled={!(filter.account > 0)}/>
            </div>


            <div style={{flexGrow: 1, padding: 0}}></div>

            <div>
                <label className="label">Sök</label>
                <InputText className="search" type="search" defaultValue={filter.search}
                           onInput={(e) => updateFilter('search', e.target.value)}/>
            </div>

            <div style={{paddingRight: 0}}>
                <label className="label">&nbsp;</label>
                <ExtraMenu list={filteredList}
                           onExportToExcel={() => NetUtils.exportToExcel('Avskrivningar per konto', columns, filteredList)}/>
            </div>
        </div>

        <DataTable className="dataTable"
                   value={filteredList}
                   resizableColumns={true}
                   columnResizeMode="expand"
                   paginator={false}
                   footerColumnGroup={footerGroup}
                   selectionMode="single"
                   onRowSelect={e => onRowSelect(e.data)}
                   scrollable={true} scrollHeight="calc(100vh - 310px)">
            {
                columns.map(c => <Column key={c.field}
                                         field={c.field}
                                         header={c.header}
                                         style={c.style}
                                         body={c.body}
                                         sortable={true}
                />)
            }
        </DataTable>

    </div>
}

export default withRouter(DepreciationPerAccountReport);
