
const SEK = 1;
const EUR = 3;

const list = [
    {id: SEK, name: 'SEK'},
    {id: EUR, name: 'EUR'}
]

export default {
    SEK: SEK,
    EUR: EUR,

    list: list,
    getName: id => list.find(c => c.id === id)?.name || ''
}