/**
 * XLNZ COMMON REACT - Do not edit elsewhere
 */

export class NumberUtils {

    static formatCurrency(value, numOfDecimals) {
        let formattedValue = this.format(value, numOfDecimals);
        return formattedValue.length > 0 ? formattedValue + ' kr' : '';
    }

    static formatWeight(value, numOfDecimals) {
        let formattedValue = this.format(value, numOfDecimals);
        return formattedValue.length > 0 ? formattedValue + ' kg' : '';
    }

    static formatSquareMeter(value, numOfDecimals) {
        let formattedValue = this.format(value, numOfDecimals);
        return formattedValue.length > 0 ? formattedValue + ' m\u00B2' : '';
    }

    static format(value, numOfDecimals) {
        value = Number(value);
        if (!numOfDecimals) {
            numOfDecimals = 0;
        }
        if (isNaN(value) || value === 0) return '';
        if (Math.abs(value) < 0.001) return '';

        return value.toLocaleString(undefined, {
            minimumFractionDigits: numOfDecimals,
            maximumFractionDigits: numOfDecimals
        });
    }

    static formatTwoDecimals = (value) => this.format(value, 2);

    static formatDuration(value) {
        let result = ''
        if (value > 0) {

            let hours = parseInt(value);
            let minutes = Math.round((value - hours) * 60);

            result = hours + ":" + (minutes < 10 ? '0' : '') + minutes;

            // if (hours > 0) {
            //     result += hours + "h ";
            // }
            // result += minutes + ' min';
        }
        return result
    }

    static formatPercent(value, numOfDecimals) {
        if (isNaN(value) || value === 0) {
            return '';
        } else {
            return this.format(value * 100, numOfDecimals) + " %";
        }
    }

    static round(value, numOfDecimals) {
        const smallValue = value > 0 ? Number.EPSILON : -Number.EPSILON;
        return Math.round((value + smallValue) * (10 ** numOfDecimals)) / (10 ** numOfDecimals);
    }

    static sumByField = (list, field) => list.reduce((sum, a) => sum += !isNaN(a[field]) ? a[field] : 0, 0)
}

