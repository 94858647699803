import NetUtils from "../../utils/NetUtils";
import ArrayUtils from "../../utils/ArrayUtils";

export default {
    findByCompany: async companyId => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findByCompany',
        manager: 'AcquisitionMgr',
        schema: 'byggletasset',
        postData: '' + companyId
    }).then(res => {
        return ArrayUtils.transformToArray(res.list0.acquisition);
    }),

    findById: async id => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findById',
        manager: 'AcquisitionMgr',
        schema: 'byggletasset',
        postData: '' + id
    }).then(res => {
        return res.list0.acquisition;
    }),

    update: async model => NetUtils.doPostWithParams('/asset/UpdateAcquisition', model).then(_res => _res.acquisition),


    findRowsByAcquisitionId: async acquisitionId => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findByAcquisition',
        manager: 'AcquisitionrowMgr',
        schema: 'byggletasset',
        postData: '' + acquisitionId
    }).then(res => {
        return ArrayUtils.transformToArray(res.list0.acquisitionrow);
    }),

    findFinancingByAcquisitionId: async acquisitionId => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findByAcquisition',
        manager: 'AcquisitionfinancingMgr',
        schema: 'byggletasset',
        postData: '' + acquisitionId
    }).then(res => {
        return ArrayUtils.transformToArray(res.list0.acquisitionfinancing);
    }),


    findPaymentsById: async id => NetUtils.doGet('/asset/GetAcquisitionDepreciationPeriods', {asset: id}).then(res => {
        return res.depreciationPeriod;
    }),
}