import React, {useContext, useState, useEffect} from 'react';
import AppContext from "../../AppContext";
import {GridTemplates} from "../../GridTemplates";
import SearchUtils from "../../utils/SearchUtils";
import RouterUtils from "../../utils/RouterUtils";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Column} from "primereact/column";
import {Dropdown} from "primereact/dropdown";
import {ActiveFilter} from "../../components/filters/ActiveFilter";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import ExtraMenu from "../../components/Grid/ExtraMenu";
import NetUtils from "../../utils/NetUtils";
import {DataTable} from "primereact/datatable";
import {dynamicSort} from "../../utils/SortUtils";
import AcquisitiontypeService from "./AcquisitiontypeService";

const AcquisitiontypeGrid = props => {
    const NAME = 'AcquisitiontypeGrid'
    const context = useContext(AppContext);
    const [filter, setFilter] = useState({
        active: context.getFilter(NAME, 'active', 1),
        search: context.getFilter(NAME, 'search', '')
    });
    const [list, setList] = useState([]);

    const matchActive = s => filter.active === null || s.active === filter.active;
    const matchSearch = s => SearchUtils.isMatching(s, filter.search);

    const filteredList = list.filter(s => matchActive(s) && matchSearch(s))

    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(filter);
    }

    const onRowSelect = model => RouterUtils.navigateTo(props, '/acquisitiontypes/' + model.id);

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={'Totalt (' + filteredList.length + ' st)'}
                    style={{textAlign: 'left'}}/>
            <Column footer=''/>
            <Column footer=''/>
        </Row>
    </ColumnGroup>;


    useEffect(() => {
        AcquisitiontypeService.findByCompany(context.currentCompany.id).then(res => {
            res.sort(dynamicSort('name'));
            setList(res)
        });
    }, [context.currentCompany])

    return <div className="Grid">

        <h1>Anskaffningstyper</h1>

        <div className="filter">
            <div>
                <label className="label">Status</label>
                <Dropdown
                    value={filter.active}
                    options={ActiveFilter.statusList}
                    onChange={e => updateFilter('active', e.value)}
                    showClear={true}
                    placeholder='Alla'
                    style={{width: '90px'}}/>
            </div>

            <div style={{marginLeft: '50px'}}>
                <label className="label">&nbsp;</label>
                <Button label='Lägg till anskaffningstyp'
                        icon="pi pi-plus"
                        onClick={() => RouterUtils.navigateTo(props, '/acquisitiontypes/0')}/>
            </div>


            <div style={{flexGrow: 1, padding: 0}}></div>

            <div>
                <label className="label">Sök</label>
                <InputText className="search" type="search" defaultValue={filter.search}
                           onInput={(e) => updateFilter('search', e.target.value)}/>
            </div>

            <div style={{paddingRight: 0}}>
                <label className="label">&nbsp;</label>
                <ExtraMenu list={filteredList}
                           onExportToExcel={() => NetUtils.exportToExcel('Leverantörer', [
                               {field: 'name', label: 'Namn'},
                               {field: 'registrationNumber', label: 'Org.nr'},
                               {field: 'active', label: 'Aktiv'}], filteredList)}/>
            </div>
        </div>

        <DataTable className="dataTable"
                   value={filteredList}
                   resizableColumns={true}
                   columnResizeMode="expand"
                   paginator={false}
                   footerColumnGroup={footerGroup}
                   selectionMode="single"
                   onRowSelect={e => onRowSelect(e.data)}
                   scrollable={true} scrollHeight="calc(100vh - 300px)"
        >
            <Column field='name' header='Namn' sortable={true}/>
            <Column field='showInGraph' header='Visa i översikt' sortable={true} body={GridTemplates.boolean}/>
            <Column field='active' header='Aktiv' sortable={true} body={GridTemplates.boolean}
                    style={{width: '100px'}}/>
        </DataTable>
    </div>
}

export default AcquisitiontypeGrid;
