import NetUtils from "../../../utils/NetUtils";
import ArrayUtils from "../../../utils/ArrayUtils";

export default {
    findByinterval: async (startDate, endDate) => NetUtils.doPostWithParams('/asset/GetPaymentsByInterval', {
        startDate: startDate,
        endDate: endDate
    }).then(res => {
        if (Object.keys(res).length === 0 && res.constructor === Object) {
            return [];
        } else {
            return ArrayUtils.transformToArray(res.payment);
        }
    }),
}