const isMatching = (model, gf) => {
    if (gf && gf.length >= 3) {
        gf = gf.toLowerCase();
        return Object.keys(model).find(key => {
                const valueString = '' + model[key];
                return valueString.toLowerCase().indexOf(gf) !== -1
            }
        )
    }
    return true;
}

export default {
    isMatching
}

