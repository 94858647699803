import React, {useContext, useState} from 'react';
import AppContext from "../../AppContext";
import {Dropdown} from "primereact/dropdown";
import AcquisitionListPriceValueReport from "./acquisitionListPriceValue/AcquisitionListPriceValueReport";
import ArticlesPerYearReport from "./articlesPerYear/ArticlesPerYearReport";
import PaymentsReport from "./payments/PaymentsReport";
import DepreciationPerAccountReport from "./depreciationPerAccount/DepreciationPerAccountReport";

const ReportContainer = props => {
    const NAME = 'ReportContainer'
    const context = useContext(AppContext);
    const [filter, setFilter] = useState({
        report: context.getFilter(NAME, 'report', 1)
    });

    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(filter);
    }

    const reportList = [
        {
            id: 1,
            name: 'Betalningar',
            component: <PaymentsReport/>
        },
        {
            id: 2,
            name: 'Avskrivningar per konto',
            component: <DepreciationPerAccountReport/>
        },
        {
            id: 3,
            name: 'Artiklar per år',
            component: <ArticlesPerYearReport/>
        },
        {
            id: 4,
            name: 'Listprisvärde för anskaffning',
            component: <AcquisitionListPriceValueReport/>
        }
    ]

    const report = reportList.find(r => r.id === filter.report);

    return <div className="Grid">
        <div className="filter">
            <div>
                <label className="label">Rapport</label>
                <Dropdown
                    value={filter.report}
                    options={reportList}
                    optionLabel='name'
                    optionValue='id'
                    onChange={e => updateFilter('report', e.value)}
                    style={{width: '300px'}}/>
            </div>
        </div>

        {
            report &&
            <div>
                {report.component}
            </div>
        }
    </div>
}

export default ReportContainer;