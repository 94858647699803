/**
 * XLNZ COMMON REACT - Do not edit elsewhere
 */

export default class VersionUtils {
    static newVersionAvailable = false;
    static apiBuildTime = undefined;

    static setApiBuildTime = value => {
        if (!VersionUtils.apiBuildTime) {
            VersionUtils.apiBuildTime = value;
            console.log("Setting default build time", VersionUtils.apiBuildTime)
        } else if (VersionUtils.apiBuildTime !== value) {
            VersionUtils.newVersionAvailable = true;
            console.log("Setting new version available", VersionUtils.newVersionAvailable)
        }
    }
}

