import React, {useState, useCallback, useEffect} from 'react';
import {Row} from "primereact/row";
import {ColumnGroup} from "primereact/columngroup";
import {Column} from "primereact/column";
import {NumberUtils} from "../../../utils/NumberUtils";
import RouterUtils from "../../../utils/RouterUtils";
import FinancingService from "../FinancingService";
import {DataTable} from "primereact/datatable";
import {GridTemplates} from "../../../GridTemplates";
import moment from "moment";
import ExtraMenu from "../../../components/Grid/ExtraMenu";
import NetUtils from "../../../utils/NetUtils";
import FinancingPaymentEditFormDialog from "./FinancingPaymentEditFormDialog";

const FinancingPaymentGrid = props => {
    const [list, setList] = useState([]);
    const [modelToEdit, setModelToEdit] = useState();

    const sumByField = field => list.reduce((sum, a) => sum += !isNaN(a[field]) ? a[field] : 0, 0);
    const totalAmount = sumByField('amount');
    const totalInterest = sumByField('interest');
    const totalInstalment = sumByField('instalment');
    const totalPaymentInterest = sumByField('paymentInterest');
    const totalPaymentInstalment = sumByField('paymentInstalment');
    const totalPaymentPrice = sumByField('paymentPrice');
    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer={NumberUtils.format(totalAmount, 2)} style={{textAlign: 'right'}}/>
            <Column footer=''/>
            <Column footer={NumberUtils.format(totalInterest, 2)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.format(totalInstalment, 2)} style={{textAlign: 'right'}}/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer={NumberUtils.formatCurrency(totalPaymentInterest, 2)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalPaymentInstalment, 2)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalPaymentPrice, 2)} style={{textAlign: 'right'}}/>
            <Column footer=''/>
        </Row>
    </ColumnGroup>;

    const loadData = useCallback(() => {
        const financingId = RouterUtils.getIdFromPath(props, '/financing/:id/payments');
        FinancingService.findById(financingId).then(_financing => {
            FinancingService.findPaymentsById(financingId).then(res => {
                res.forEach(p => {
                    p.financingId = _financing.id;

                    const fp = p.financingpayment;
                    if (fp) {
                        p.paymentDateString = moment(fp.date).format('YYYY-MM-DD');
                        p.paymentExchangeRate = fp.exchangeRate;
                        p.paymentInterest = p.interest * _financing.startExchangeRate;
                        p.paymentInstalment = p.instalment * _financing.startExchangeRate;
                        p.paymentPrice = p.paymentInterest + p.paymentInstalment;
                        p.paymentCurrencyDiff = (fp.exchangeRate - _financing.startExchangeRate) * p.instalment
                    }

                })
                setList(res)
            });
        });
    }, [props]);

    useEffect(() => {
        loadData();
    }, [loadData])

    const columns = [
        {field: 'index', header: '#', style: {width: '40px'}},
        {field: 'date', header: 'Datum', style: {width: '90px'}},
        {
            field: 'amount',
            header: 'Belopp',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.numberTwoDecimals
        }, {
            field: 'interestRate',
            header: 'Räntesats',
            style: {width: '80px', textAlign: 'right'},
            body: GridTemplates.numberTwoDecimals
        }, {
            field: 'interest',
            header: 'Ränta',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.numberTwoDecimals
        }, {
            field: 'instalment',
            header: 'Avbetalning',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.numberTwoDecimals
        }, {
            field: 'leftToPay',
            header: 'Kvar att betala',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.numberTwoDecimals
        }, {
            field: 'paymentDateString',
            header: 'Betalningsdatum',
            style: {width: '110px', textAlign: 'right'},
        }, {
            field: 'paymentExchangeRate',
            header: 'Valutakurs',
            style: {width: '80px', textAlign: 'right'},
            body: GridTemplates.currencyTwoDecimals
        }, {
            field: 'paymentInterest',
            header: 'Ränta SEK',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.currencyTwoDecimals
        }, {
            field: 'paymentInstalment',
            header: 'Avbetalning SEK',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.currencyTwoDecimals
        }, {
            field: 'paymentPrice',
            header: 'Totalt SEK',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.currencyTwoDecimals
        }, {
            field: 'paymentCurrencyDiff',
            header: 'Valutadifferens',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.currencyTwoDecimals
        }
    ];

    return <div className="Grid">
        <div className="filter">
            <div className="ui-dialog-buttonpane p-clearfix button-row">
                <div className='left-buttons'>
                    <input type='button' value='Gå tillbaka' onClick={e => RouterUtils.goBack(props)} style={{height: '10'}}/>
                </div>
            </div>

            <div style={{flexGrow: 1, padding: 0}}></div>

            <div style={{paddingRight: 0}}>
                <label className="label">&nbsp;</label>
                <ExtraMenu list={list} onExportToExcel={() => NetUtils.exportToExcel('Betalningar', columns, list)}/>
            </div>
        </div>

        <DataTable className="dataTable"
                   value={list}
                   resizableColumns={true}
                   columnResizeMode="expand"
                   paginator={false}
                   footerColumnGroup={footerGroup}
                   selectionMode="single"
                   onSelectionChange={e => setModelToEdit(e.value)}
                   scrollable={true} scrollHeight="calc(100vh - 240px)"
        >
            {
                columns.map(c => <Column key={c.field}
                                         field={c.field}
                                         header={c.header}
                                         style={c.style}
                                         body={c.body}
                                         sortable={false}
                />)
            }
        </DataTable>

        {
            modelToEdit &&
            <FinancingPaymentEditFormDialog visible={modelToEdit !== undefined}
                                            model={modelToEdit}
                                            onChange={() => {
                                                setModelToEdit(undefined);
                                                loadData();
                                            }}
                                            onHide={() => setModelToEdit(undefined)}/>
        }
    </div>
}

export default FinancingPaymentGrid;
