import ReportContainer from "./ReportContainer";

export default {
    routeProps: {
        path: '/reports',
        exact: true,
        component: ReportContainer
    },
    name: 'Rapporter',
    icon: 'pi pi-fw pi-list',
}