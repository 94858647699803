import React, {useState, useEffect} from 'react';
import {Column} from "primereact/column";
import RouterUtils from "../../../utils/RouterUtils";
import {DataTable} from "primereact/datatable";
import {GridTemplates} from "../../../GridTemplates";
import ExtraMenu from "../../../components/Grid/ExtraMenu";
import NetUtils from "../../../utils/NetUtils";
import AcquisitionService from "../AcquisitionService";

const DepreciationGrid = props => {
    const [list, setList] = useState([]);

    useEffect(() => {
        const acquisitionId = RouterUtils.getIdFromPath(props, '/acquisitions/:id/depreciations');
        AcquisitionService.findPaymentsById(acquisitionId).then(res => {
            setList(res)
        });
    }, [props])

    const columns = [
        {field: 'index', header: '#', style: {width: '40px'}},
        {field: 'date', header: 'Datum', style: {width: '90px'}},
        {
            field: 'monthDepreciation',
            header: 'Avskrivningstid i månader',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.numberTwoDecimals
        }, {
            field: 'acquisitionValue',
            header: 'Ansk. värde',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.numberTwoDecimals
        }, {
            field: 'depreciationValue',
            header: 'Avskrivet',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.numberTwoDecimals
        }, {
            field: 'accumulatedDepreciationValue',
            header: 'Ack. avskr.',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.numberTwoDecimals
        }, {
            field: 'bookkeepingValue',
            header: 'Bokfört värde',
            style: {width: '110px', textAlign: 'right'},
            body: GridTemplates.currencyTwoDecimals
        }, {
            field: 'divestedValue',
            header: 'Avvytting',
            style: {width: '80px', textAlign: 'right'},
            body: GridTemplates.currencyTwoDecimals
        }, {
            field: 'divestedDepreciationValue',
            header: 'Avyttr avskr',
            style: {width: '80px', textAlign: 'right'},
            body: GridTemplates.currencyTwoDecimals
        }
    ];

    return <div className="Grid">
        <div className="filter">
            <div className="ui-dialog-buttonpane p-clearfix button-row">
                <div className='left-buttons'>
                    <input type='button' value='Gå tillbaka' onClick={e => RouterUtils.goBack(props)}/>
                </div>
            </div>

            <div style={{flexGrow: 1, padding: 0}}></div>

            <div style={{paddingRight: 0}}>
                <label className="label">&nbsp;</label>
                <ExtraMenu list={list} onExportToExcel={() => NetUtils.exportToExcel('Avskrivningar', columns, list)}/>
            </div>
        </div>

        <DataTable className="dataTable"
                   value={list}
                   resizableColumns={true}
                   columnResizeMode="expand"
                   paginator={false}
                   scrollable={true} scrollHeight="calc(100vh - 240px)"
        >
            {
                columns.map(c => <Column key={c.field}
                                         field={c.field}
                                         header={c.header}
                                         style={c.style}
                                         body={c.body}
                                         sortable={false}
                />)
            }
        </DataTable>
    </div>
}

export default DepreciationGrid;
