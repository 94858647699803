import React, {useContext, useState, useEffect, useCallback} from 'react';
import moment from "moment";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {DataTable} from "primereact/datatable";
import PaymentsService from "./PaymentsService";
import SearchUtils from "../../../utils/SearchUtils";
import AppContext from "../../../AppContext";
import {GridTemplates} from "../../../GridTemplates";
import ExtraMenu from "../../../components/Grid/ExtraMenu";
import NetUtils from "../../../utils/NetUtils";
import RouterUtils from "../../../utils/RouterUtils";
import {dynamicSort} from "../../../utils/SortUtils";
import {NumberUtils} from "../../../utils/NumberUtils";
import {withRouter} from 'react-router-dom';


const PaymentsReport = props => {
    const NAME = 'PaymentsReport'
    const context = useContext(AppContext);
    const [filter, setFilter] = useState({
        startDate: context.getFilter(NAME, 'startDate', moment().set('date', 1).format("YYYY-MM-DD")),
        endDate: context.getFilter(NAME, 'endDate', moment().format("YYYY-MM-DD")),
        search: context.getFilter(NAME, 'search', '')
    });

    const [list, setList] = useState([]);
    const matchSearch = customer => SearchUtils.isMatching(customer, filter.search);
    const filteredList = list.filter(c => matchSearch(c))

    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(Object.assign({}, filter));
    }

    const onRowSelect = model => RouterUtils.navigateTo(props, '/financing/' + model.id);

    const sumByField = field => filteredList.reduce((sum, a) => sum += !isNaN(a[field]) ? a[field] : 0, 0);
    const totalInterest = sumByField('interest');
    const totalInstalment = sumByField('instalment');
    const totalPrice = sumByField('price');
    const totalCurrencyDiff = sumByField('currencyDiff');

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={'Totalt (' + list.length + ' st)'}
                    style={{textAlign: 'left'}}/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer={NumberUtils.formatCurrency(totalInterest, 2)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalInstalment, 2)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalPrice, 2)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalCurrencyDiff, 2)} style={{textAlign: 'right'}}/>
        </Row>
    </ColumnGroup>;

    const loadData = useCallback(() => {
        PaymentsService.findByinterval(filter.startDate, filter.endDate).then(res => {
            res.forEach(p => {
                p.id = p.financing.id;
                p.typeString = p.financing.typeString;
                p.financierString = p.financing.financierString;
                p.number = p.financing.number;
            })
            res.sort(dynamicSort('interest'))
            res.sort(dynamicSort('number'))
            res.sort(dynamicSort('financierString'))
            res.sort(dynamicSort('typeString'))

            setList(res);
        });
    }, [filter]);

    const useMountEffect = (fun) => useEffect(fun, [])
    useMountEffect(() => {
        loadData()
    }, [loadData])

    return <div className="Grid" style={{padding: '10px 0'}}>

        <div className="filter">
            <div>
                <label className="label">Startdatum</label>
                <input type="date" value={filter.startDate}
                       onChange={e => {
                           updateFilter('startDate', e.target.value)
                       }}/>
            </div>
            <div>
                <label className="label">Slutdatum</label>
                <input type="date" value={filter.endDate}
                       onChange={e => updateFilter('endDate', e.target.value)}/>
            </div>

            <div>
                <label className="label">&nbsp;</label>
                <input type="button" onClick={() => loadData()} value='Skapa rapport'/>
            </div>


            <div style={{flexGrow: 1, padding: 0}}></div>

            <div>
                <label className="label">Sök</label>
                <InputText className="search" type="search" defaultValue={filter.search}
                           onInput={(e) => updateFilter('search', e.target.value)}/>
            </div>

            <div style={{paddingRight: 0}}>
                <label className="label">&nbsp;</label>
                <ExtraMenu list={filteredList}
                           onExportToExcel={() => NetUtils.exportToExcel('Betalningar',
                               [{field: 'typeString', label: 'Typ'},
                                   {field: 'financierString', label: 'Finansiär'},
                                   {field: 'number', label: 'Referens'},
                                   {field: 'interest', label: 'Ränta'},
                                   {field: 'instalment', label: 'Avbetalning'},
                                   {field: 'price', label: 'Totalt'},
                                   {field: 'currencyDiff', label: 'Valutadifferens'},], filteredList)}/>
            </div>
        </div>

        <DataTable className="dataTable"
                   value={filteredList}
                   resizableColumns={true}
                   columnResizeMode="expand"
                   paginator={false}
                   footerColumnGroup={footerGroup}
                   selectionMode="single"
                   onRowSelect={e => onRowSelect(e.data)}
                   scrollable={true} scrollHeight="calc(100vh - 310px)"
        >
            <Column field='typeString' header='Typ' sortable={true}
                    style={{width: '100px'}}/>
            <Column field='financierString' header='Finansiär' sortable={true}/>
            <Column field='number' header='Referens' sortable={true}/>
            <Column field='interest' header='Ränta' sortable={true} body={GridTemplates.currencyTwoDecimals}
                    style={{width: '120px', textAlign: 'right'}}/>
            <Column field='instalment' header='Avbetalning' sortable={true}
                    body={GridTemplates.currencyTwoDecimals}
                    style={{width: '120px', textAlign: 'right'}}/>
            <Column field='price' header='Totalt' sortable={true} body={GridTemplates.currencyTwoDecimals}
                    style={{width: '120px', textAlign: 'right'}}/>
            <Column field='currencyDiff' header='Valutadifferens' sortable={true} body={GridTemplates.currency}
                    style={{width: '130px', textAlign: 'right'}}/>
        </DataTable>

    </div>
}

export default withRouter(PaymentsReport);
