import FinancingGrid from "./FinancingGrid";
import FinancingEditForm from "./edit/FinancingEditForm";
import FinancingPaymentGrid from "./payments/FinancingPaymentGrid";

export default {
    routeProps: {
        path: '/financing',
        exact: true,
        component: FinancingGrid,
    },
    name: 'Finansieringar',
    icon: 'pi pi-fw pi-briefcase',
    subModules: [{
        routeProps: {
            path: '/financing/:id',
            exact: true,
            component: FinancingEditForm
        }
    }, {
        routeProps: {
            path: '/financing/:id/payments',
            exact: true,
            component: FinancingPaymentGrid
        }
    }],
};