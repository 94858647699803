import React, {useState} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {InputText} from "primereact/inputtext";
import {NumberUtils} from "../../../utils/NumberUtils";
import {GridTemplates} from "../../../GridTemplates";
import SelectArticlesDialog from "../../Articles/SelectArticlesDialog";
import Currency from "../../Currencies/Currency";
import NetUtils from "../../../utils/NetUtils";

const AcquisitionrowGrid = props => {
    const rowList = props.rows;
    const articleList = props.articleList;
    const [showOrderAddArticlesDialog, setShowOrderAddArticlesDialog] = useState(false);

    const currency = props.currency;
    const exchangeRate = props.exchangeRate;
    const discount = props.discount;

    rowList.forEach(r => {
        const a = articleList.find(a => a.id === r.article);
        r.numberAndName = a.number + ' - ' + a.name;

        r.price = r.quantity * r.aprice;
        r.priceSek = exchangeRate * r.price;
        r.discountPriceSek = discount / 100 * r.priceSek;
        r.netPriceSek = r.priceSek - r.discountPriceSek;
    })

    const sumByField = field => rowList.reduce((sum, r) => sum + r[field], 0)

    const totalPrice = sumByField('price');
    const totalPriceSek = sumByField('priceSek');
    const totalDiscountPriceSek = sumByField('discountPriceSek');
    const totalNetPriceSek = sumByField('netPriceSek');

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={'Totalt (' + rowList.length + ' st)'}
                    style={{textAlign: 'left'}}/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer={NumberUtils.formatCurrency(totalPrice)} style={{textAlign: 'right'}}/>

        </Row>
    </ColumnGroup>;

    const onPropertyChange = (r, field, value) => {
        r[field] = value;
        props.onSave([...rowList]);
    }

    const columns = [
        {field: 'numberAndName', header: 'Artikel'},
        {
            field: 'assetId', header: 'Tillgångs-ID',
            style: {width: '150px'},
            editor: e => <InputText value={e.rowData.assetId}
                                    onChange={e2 => onPropertyChange(e.rowData, 'assetId', e2.target.value)}/>
        }, {
            field: 'quantity', header: 'Antal',
            body: GridTemplates.integer,
            style: {width: '80px', textAlign: 'right'},
            editor: e => <InputText type="number"
                                    value={e.rowData.quantity !== 0 ? e.rowData.quantity : ''}
                                    onChange={e2 => onPropertyChange(e.rowData, 'quantity', e2.target.value)}
                                    style={{textAlign: 'right'}}/>
        }, {
            field: 'divestedQuantity', header: 'Avyttr. antal',
            body: GridTemplates.integer,
            style: {width: '90px', textAlign: 'right'}
        }, {
            field: 'aprice', header: 'À-pris',
            body: GridTemplates.currencyTwoDecimals,
            style: {width: '110px', textAlign: 'right'},
            editor: e => <InputText type="number"
                                    value={e.rowData.aprice !== 0 ? e.rowData.aprice : ''}
                                    onChange={e2 => onPropertyChange(e.rowData, 'aprice', e2.target.value)}
                                    style={{textAlign: 'right'}}/>
        }, {
            field: 'price', header: 'Bruttopris',
            body: GridTemplates.currency,
            style: {width: '110px', textAlign: 'right'}
        }
    ];

    return props.model &&
        <div className='CalcrowGrid'>
            <DataTable value={rowList}
                       footerColumnGroup={footerGroup}
                       style={{width: '900px'}}>
                {
                    columns.map(c => <Column key={c.field}
                                             field={c.field}
                                             header={c.header}
                                             style={c.style}
                                             className={c.className}
                                             body={c.body}
                                             editor={c.editor}
                                             sortable={false}
                    />)
                }
            </DataTable>

            <div style={{display: 'flex', width: '900px', marginTop: '7px'}}>
                <div>
                    <input type='button' className='add-btn' value='Lägg till artiklar'
                           onClick={e => setShowOrderAddArticlesDialog(true)}/>
                </div>
                <div style={{flexGrow: '1'}}></div>

                <button className='excel-icon-btn'
                        onClick={() => NetUtils.exportToExcel('Anskaffningsrader', columns, rowList)}/>

                <div style={{textAlign: 'right'}}>
                    <table className='sum-table'>
                        {
                            exchangeRate === 1 &&
                            <tbody>
                            <tr>
                                <td>Bruttopris:</td>
                                <td>{NumberUtils.formatCurrency(totalPriceSek)}</td>
                            </tr>
                            </tbody>
                        }
                        {
                            exchangeRate !== 1 &&
                            <tbody>
                            <tr>
                                <td>Bruttopris {Currency.getName(currency)}:</td>
                                <td>{NumberUtils.formatCurrency(totalPrice)}</td>
                            </tr>
                            <tr>
                                <td>Bruttopris SEK:</td>
                                <td>{NumberUtils.formatCurrency(totalPriceSek)}</td>
                            </tr>
                            </tbody>
                        }
                        <tbody>
                        <tr>
                            <td>Rabatt:</td>
                            <td>{NumberUtils.formatCurrency(totalDiscountPriceSek)}</td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: 'bold'}}>Nettopris::</td>
                            <td style={{
                                fontWeight: 'bold',
                                paddingLeft: '50px'
                            }}>{NumberUtils.formatCurrency(totalNetPriceSek)}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            {
                showOrderAddArticlesDialog &&
                <SelectArticlesDialog visible={showOrderAddArticlesDialog}
                                      type={props.type}
                                      articlecategoryList={props.articlecategoryList}
                                      articleList={articleList}
                                      stockQuantityMap={props.stockQuantityMap}
                                      rowList={rowList}
                                      onSave={addList => {
                                          props.onSave([...rowList]
                                              .concat(addList
                                                  .map((a, index) => ({
                                                      id: 0,
                                                      article: a.id,
                                                      stockQuantity: a.stockQuantity,
                                                      assetId: '',
                                                      quantity: a.quantity,
                                                      aprice: a.listPriceValue,
                                                      listOrder: rowList.length + index + 1
                                                  }))))
                                          setShowOrderAddArticlesDialog(false)
                                      }
                                      }
                                      onHide={() => {
                                          setShowOrderAddArticlesDialog(false)
                                      }}
                />
            }
        </div>

}
export default AcquisitionrowGrid;