import FinancierGrid from "./FinancierGrid";
import FinanciersEditForm from "./FinancierEditForm";

export default {
    routeProps: {
        path: '/financiers',
        exact: true,
        component: FinancierGrid,
    },
    name: 'Finansiärer',
    nameSingular: 'Finansiär',
    showHeadline: true,
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/financiers/:id',
            exact: true,
            component: FinanciersEditForm,
        }
    }],
};