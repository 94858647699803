import React, {useState} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import moment from "moment";
import Financingtype from "../../Financing/Financingtype";
import DateUtils from "../../../utils/DateUtils";
import AcquisitionfinancingEditFormDialog from "./AcquisitionfinancingEditFormDialog";

const AcquisitionfinancingGrid = props => {
    const [modelToEdit, setModelToEdit] = useState();
    const value = props.value;
    const financingList = props.financingList;

    value.forEach(r => {
        r.financingtypeString = Financingtype.getName(r.financingtype);

        const financing = financingList.find(f => f.id === r.financing)
        if (financing) {
            r.financingString = financing.financierString + ' - ' + financing.number;
        }
        r.startDate = DateUtils.getDateString(r.startDate);
        r.endDate = DateUtils.getDateString(r.endDate);
    })

    const columns = [
        {field: 'financingtypeString', header: 'Finansieringstyp', style: {width: '110px'}},
        {field: 'financingString', header: 'Finansiering'},
        {field: 'startDate', header: 'Startdatum', style: {width: '90px'}},
        {field: 'endDate', header: 'Slutdatum', style: {width: '90px'}},
        {field: 'note', header: 'Anteckning'},

    ].filter(c => !c.hide);

    return <div>
        <DataTable value={props.value} style={{width: '800px'}}
                   selectionMode="single"
                   onSelectionChange={e => setModelToEdit(e.value)}>
            {
                columns.map(c => <Column key={c.field}
                                         field={c.field}
                                         header={c.header}
                                         style={c.style}
                                         className={c.className}
                                         body={c.body}
                                         editor={c.editor}
                                         sortable={false}
                />)
            }
        </DataTable>

        <Button icon="pi pi-plus" className="p-button-secondary" onClick={e => {
            e.preventDefault();
            setModelToEdit({
                startDate: moment().format('YYYY-MM-DD'),
                endDate: '',
                newPrice: true
            });
        }}/>

        {
            modelToEdit &&
            <AcquisitionfinancingEditFormDialog visible={modelToEdit !== undefined}
                                                model={modelToEdit}
                                                financingList={financingList}
                                                onSave={savedModel => {
                                                    console.log("modelToEditmodelToEdit", modelToEdit)
                                                    if (modelToEdit.newPrice) {
                                                        delete modelToEdit.newPrice;
                                                        let newList = [...props.value];
                                                        newList.push(savedModel);
                                                        props.onChange(newList);
                                                    } else {
                                                        props.onChange([...props.value]);
                                                    }
                                                    setModelToEdit(undefined);
                                                }}
                                                onRemove={removeModel => {
                                                    let newList = [...props.value]
                                                    newList.splice(
                                                        props.value.findIndex(d => d.id === removeModel.id),
                                                        1
                                                    );
                                                    props.onChange(newList);

                                                    setModelToEdit(undefined);
                                                }}
                                                onHide={() => setModelToEdit(undefined)}/>
        }
    </div>
}

export default AcquisitionfinancingGrid;
