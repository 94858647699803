import moment from "moment";
import NetUtils from "../../utils/NetUtils";
import ArrayUtils from "../../utils/ArrayUtils";

export default {
    findAll: async params => NetUtils.doGet('/asset/GetArticles', params).then(res => {
        return ArrayUtils.transformToArray(res.article);
    }),

    findById: async id => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findById',
        manager: 'ArticleMgr',
        schema: 'byggletasset',
        postData: '' + id
    }).then(res => {
        return res.list0.article;
    }),

    update: model => NetUtils.doPostWithParams('/asset/UpdateArticle', model),

    stockQuantities: time => NetUtils.doGet('/api/articles/stockQuantities?time=' + moment(time).toISOString()),
    customerStockQuantities: params => NetUtils.doGet('/api/articles/stockQuantities', params)
    // '?time=' + moment(time).toISOString() + '&customer=' + customerId)
};