import NetUtils from "../../utils/NetUtils";
import ArrayUtils from "../../utils/ArrayUtils";
import {dynamicSort} from "../../utils/SortUtils";

export default {
    findByCompany: async companyId => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findByCompany',
        manager: 'DivestmentcustomerMgr',
        schema: 'byggletasset',
        postData: '' + companyId
    }).then(res => {
        const result = ArrayUtils.transformToArray(res.list0.divestmentcustomer);
        result.sort(dynamicSort('name'))
        return result;
    }),

    findById: async id => NetUtils.doPostWithParams('/UberServletBygglet', {
        method: 'findById',
        manager: 'DivestmentcustomerMgr',
        schema: 'byggletasset',
        postData: '' + id
    }).then(res => {
        return res.list0.divestmentcustomer;
    }),
    update: model => NetUtils.doPostWithParams('/asset/UpdateDivestmentcustomer', model),

}