import React, {useContext, useState, useEffect} from 'react';
import AppContext from "../../AppContext";
import {GridTemplates} from "../../GridTemplates";
import ArticlecategoryService from "./ArticlecategoryService";
import SearchUtils from "../../utils/SearchUtils";
import RouterUtils from "../../utils/RouterUtils";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Column} from "primereact/column";
import {Dropdown} from "primereact/dropdown";
import {ActiveFilter} from "../../components/filters/ActiveFilter";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import ExtraMenu from "../../components/Grid/ExtraMenu";
import NetUtils from "../../utils/NetUtils";
import {DataTable} from "primereact/datatable";

const ArticlecategoryGrid = props => {
    const NAME = 'ArticlecategoryGrid'
    const context = useContext(AppContext);
    const [filter, setFilter] = useState({
        active: context.getFilter(NAME, 'active', 1),
        search: context.getFilter(NAME, 'search', '')
    });
    const [list, setList] = useState([]);

    const matchActive = ac => filter.active === null || ac.active === filter.active;
    const matchSearch = customer => SearchUtils.isMatching(customer, filter.search);

    const filteredList = list.filter(c => matchActive(c) && matchSearch(c))

    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(filter);
    }

    const onRowSelect = model => RouterUtils.navigateTo(props, '/articlecategories/' + model.id);

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={'Totalt (' + filteredList.length + ' st)'}
                    style={{textAlign: 'left'}}/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
        </Row>
    </ColumnGroup>;


    useEffect(() => {
        ArticlecategoryService.findByCompany(context.currentCompany.id).then(l => {
            setList(l)
        });
    }, [context.currentCompany.id])

    return <div className="Grid">

        <h1>Artikelkatagorier</h1>

        <div className="filter">
            <div>
                <label className="label">Status</label>
                <Dropdown
                    value={filter.active}
                    options={ActiveFilter.statusList}
                    onChange={e => updateFilter('active', e.value)}
                    showClear={true}
                    placeholder='Alla'
                    style={{width: '90px'}}/>
            </div>

            <div style={{marginLeft: '50px'}}>
                <label className="label">&nbsp;</label>
                <Button label='Lägg till artikelkatagori'
                        icon="pi pi-plus"
                        onClick={() => RouterUtils.navigateTo(props, '/articlecategories/0')}/>
            </div>

            <div style={{flexGrow: 1, padding: 0}}></div>

            <div>
                <label className="label">Sök</label>
                <InputText className="search" type="search" defaultValue={filter.search}
                           onInput={(e) => updateFilter('search', e.target.value)}/>
            </div>

            <div style={{paddingRight: 0}}>
                <label className="label">&nbsp;</label>
                <ExtraMenu list={filteredList}
                           onExportToExcel={() => NetUtils.exportToExcel('Artikelkatagorier', [
                               {field: 'name', label: 'Namn'},
                               {field: 'active', label: 'Aktiv'}], filteredList)}/>
            </div>
        </div>

        <DataTable className="dataTable"
                   value={filteredList}
                   resizableColumns={true}
                   columnResizeMode="expand"
                   paginator={false}
                   footerColumnGroup={footerGroup}
                   selectionMode="single"
                   onRowSelect={e => onRowSelect(e.data)}
                   scrollable={true} scrollHeight="calc(100vh - 240px)"
        >
            <Column field='name' header='Namn' sortable={true}/>

            <Column field='exposure' header='Exponering' sortable={true}
                    body={row => row.company > 0 ? context.currentCompany.name : 'Alla'}/>

            <Column field='active' header='Aktiv' sortable={true} body={GridTemplates.boolean}
                    style={{width: '100px'}}/>
        </DataTable>
    </div>
}

export default ArticlecategoryGrid;
