import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {UserService} from "../../modules/Users/UserService";
import logo from '../../resources/images/Nobius_Logistics_logo_cmyk.png'
import {SecurityService} from "../../security/SecurityService";
import RouterUtils from "../../utils/RouterUtils";
import FormItemEmail from "../EditForm/FormItemEmail";
import FormItemText from "../EditForm/FormItemText";
import Spinner from "../Spinner/Spinner";

import './SignInScreen.scss';

const SignInScreen = props => {
    const [loading, setLoading] = useState();
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const defaultValues = {
        username: '',
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {handleSubmit, reset, formState: {isSubmitting}} = form

    const onSubmit = data => {
        setLoading(true);

        if (!showForgotPassword) {
            UserService.login({
                username: data.username,
                password: data.password
            }).then(user => {
                console.log("USERRRR", user)
                if (user) {
                    console.log("User signed in:", user);
                    localStorage.setItem("userEmail", user.email)
                    localStorage.setItem("userUid", user.idExternal)
                    localStorage.setItem("userCert", user.password.substr(0, 7))
                    RouterUtils.reload(props)
                }
                setLoading(false);
            })
        } else {
            SecurityService.sendNewPassword(data.username).then(res => {
                alert("Ett nytt lösenord har skickats till din e-postadress")
                setShowForgotPassword(false);
                setLoading(false);
                reset({
                    username: data.username
                })
            });
        }
    };

    if (loading) {
        return <Spinner/>
    }

    return <div className="EditForm SignInScreen">
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="logo-container">
                <img src={logo} alt='Assets'/>
            </div>

            <div className="credBox">
                <FormItemEmail name='username' label='E-post' autoComplete='username' required {...form}/>

                {
                    !showForgotPassword &&
                    <div>
                        <FormItemText name='password' type='password' label='Lösenord' maxLength={128}
                                      required {...form}/>
                        <div>
                            <input type='button' value='Glömt lösenordet?' className='a-btn'
                                   onClick={() => setShowForgotPassword(true)}/>
                        </div>
                    </div>
                }
            </div>

            {
                !showForgotPassword &&
                <input type='submit' value='Logga in' disabled={isSubmitting}/>
            }
            {
                showForgotPassword &&
                <div>
                    <input type='submit' value='Skicka nytt lösenord' disabled={isSubmitting}/>
                    <input type='button' value='Tillbaka' disabled={isSubmitting}
                           onClick={() => setShowForgotPassword(false)}/>
                </div>
            }

        </form>
    </div>
}
export default SignInScreen;