import moment from "moment";
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import AppContext from "../../../AppContext";
import "../../../components/EditForm/EditForm.scss"
import ErrorText from "../../../components/EditForm/ErrorText";
import ArrayUtils from "../../../utils/ArrayUtils";
import RouterUtils from "../../../utils/RouterUtils";
import {dynamicSort} from "../../../utils/SortUtils";
import ValidateUtils from "../../../utils/ValidateUtils";
import ArticlecategoryService from "../../Articlecategories/ArticlecategoryService";
import ArticleService from "../ArticleService";
import {Articletype} from "../Articletype";
import ArticlepriceGrid from "./ArticlepriceGrid";

const ArticleEditForm = props => {
    const context = useContext(AppContext);
    const [model, setModel] = useState({id: 0});
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategoryList, setSelectedCategoryList] = useState([]);
    const [priceList, setPriceList] = useState([]);

    const disabled = false;

    const {register, errors, handleSubmit, reset, formState} = useForm({
        defaultValues: {
            id: 0,
            type: Articletype.ASSET,
            name: '',
            number: '',
            description: '',
            weight: 0,
            quantityPerPack: 0,
            active: true,
            categories: []
        }
    });
    const {isSubmitting} = formState;

    const onSubmit = data => {
        data.type = data.disposable ? 10001 : 10000;
        data.onlyCompanyExposure = data.company > 0

        const newSelectedCategoryIdList = [];
        categoryList.forEach(c => {
            if (data['category' + c.id]) {
                newSelectedCategoryIdList.push(c.id);
            }
            delete data['category' + c.id];
        })
        data.categoryIds = newSelectedCategoryIdList.join(';');

        data.listPrices = priceList.map(ap => moment(ap.startTime).format('yyyy-MM-DD') + '_' + ap.value).join(';')


        Object.assign(model, data);

        return ArticleService.update(model).then(savedModel => {
            if (savedModel) {
                RouterUtils.goBack(props);
            }
        });
    };

    useEffect(() => {
        let promises = [
            ArticlecategoryService.findByCompany(context.currentCompany.id),
        ];

        let id = RouterUtils.getIdFromPath(props, '/articles/:id');
        if (id > 0) {
            promises.push(ArticleService.findById(id));
        }

        Promise.all(promises).then(values => {
            setCategoryList(values[0].filter(ac => ac.active));

            let m;
            if (id > 0) {
                m = values[1];
                setModel(m);

                setSelectedCategoryList(ArrayUtils.transformToArray(m.articlecategory));

                const priceList = ArrayUtils.transformToArray(m.listPriceList.doublevalue)
                priceList.sort(dynamicSort("startTime"));
                setPriceList(priceList);

                reset(m);
            }
        });
    }, [props, reset, context.currentCompany.id])

    return (
        <div className="EditForm">
            <div className="headline">
                <h1>Artikel</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="ui-dialog-buttonpane p-clearfix button-row">
                    <div className='left-buttons'>
                        {!disabled && <input type='submit' value='Spara' disabled={isSubmitting}/>}
                        <input type='button' value='Avbryt' onClick={e => RouterUtils.goBack(props)}/>
                    </div>
                </div>

                <div className="formItem">
                    <label htmlFor='disposable'>Typ</label>
                    <select name="disposable" ref={register} disabled={disabled || model.id > 0}>
                        {Articletype.list.map(at => <option key={at.value} value={at.value}>{at.label}</option>)}
                    </select>
                </div>

                <div className="formItem">
                    <label htmlFor='company'>Exponering</label>
                    <select name="company" ref={register()}>
                        <option value={0}>Alla företag</option>
                        <option value={context.currentCompany.id}>Endast {context.currentCompany.name}</option>
                    </select>
                </div>

                <div className="formItem">
                    <label htmlFor='name'>Namn</label>
                    <input name="name"
                           ref={register({
                               required: ValidateUtils.required(),
                               maxLength: ValidateUtils.maxLength(255)
                           })}
                           style={{width: '350px'}}
                           disabled={disabled}
                    />
                    <ErrorText errors={errors} name="name"/>
                </div>
                <div className="formItem">
                    <label htmlFor='number'>Art.nr</label>
                    <input name="number"
                           ref={register({
                               required: ValidateUtils.required(),
                               maxLength: ValidateUtils.maxLength(128)
                           })}
                           style={{width: '150px'}}
                           disabled={disabled}
                    />
                    <ErrorText errors={errors} name="number"/>
                </div>
                <div className="formItem">
                    <label htmlFor='description'>Beskrivning</label>
                    <input name="description"
                           ref={register({
                               maxLength: ValidateUtils.maxLength(255)
                           })}
                           style={{width: '350px'}}
                           disabled={disabled}
                    />
                    <ErrorText errors={errors} name="description"/>
                </div>
                <div className="formItem">
                    <label htmlFor='weight'>Vikt (kg)</label>
                    <input type='number' step='any' name="weight"
                           ref={register({
                               pattern: ValidateUtils.numberPattern()
                           })}
                           style={{width: '70px'}}
                           disabled={disabled}
                    />
                    <ErrorText errors={errors} name="weight"/>
                </div>
                <div className="formItem">
                    <label htmlFor='quantityPerPack'>Antal/förpackning</label>
                    <input type='number' step='any' name="quantityPerPack"
                           ref={register({
                               pattern: ValidateUtils.numberPattern()
                           })}
                           style={{width: '70px'}}
                           disabled={disabled}
                    />
                    <ErrorText errors={errors} name="quantityPerPack"/>
                </div>

                {
                    categoryList && selectedCategoryList &&
                    <div className="formItem">
                        <label>Kategorier</label>
                        {
                            categoryList.map(c => <div key={c.id}>
                                <input type='checkbox' name={'category' + c.id} ref={register}
                                       defaultChecked={selectedCategoryList.find(c2 => c2.id === c.id) !== undefined}
                                       disabled={disabled}
                                />
                                <span style={{marginLeft: '10px', fontSize: '12px'}}>{c.name}</span>
                            </div>)
                        }
                    </div>
                }

                <div className="formItem">
                    <label htmlFor='active'>Aktiv</label>
                    <input type='checkbox' name="active" ref={register} disabled={disabled}/>
                </div>
            </form>

            {
                <div className="formItem">
                    <label htmlFor='divisions'>Listpriser</label>
                    <ArticlepriceGrid parentId={model.id}
                                      value={priceList}
                                      onChange={newPriceList => setPriceList(newPriceList)}/>
                </div>
            }
        </div>
    );
}

export default ArticleEditForm;
