// import {Divider} from "primereact/divider";
import React from 'react';
import ReactDOM from "react-dom";
import './Alert.scss';

const Dialog = props => {
    const onHide = props.onHide;
    const header = props.header;
    const message = props.message;
    const closeButtonText = props.closeButtonText ? props.closeButtonText : "Stäng";

    return <div className='Alert'>
        <h1 className='header'>
            <i className="pi pi-exclamation-triangle" style={{'fontSize': '2em'}}></i>
            {header}
        </h1>

        {/*<Divider/>*/}
        <div className='message'>
            {
                message.split('\n').map((item, key) => {
                    return <span key={key}>{item}<br/></span>
                })
            }
        </div>
        <div className='footer'>
            <input type='button' className='cancel-btn' value={closeButtonText} onClick={onHide}/>
        </div>
    </div>
}


export function errorAlert(msg) {
    const renderContainer = document.createElement('div');
    document.body.appendChild(renderContainer);

    const unmountModal = () => {
        ReactDOM.unmountComponentAtNode(renderContainer);
        renderContainer.parentNode.removeChild(renderContainer);
    };
    ReactDOM.render(<Dialog onHide={unmountModal} header='Felmeddelande' message={msg}/>, renderContainer);
}

export function warningAlert(msg, callback) {
    const renderContainer = document.createElement('div');
    document.body.appendChild(renderContainer);

    const unmountModal = () => {
        ReactDOM.unmountComponentAtNode(renderContainer);
        renderContainer.parentNode.removeChild(renderContainer);

        if (callback) {
            callback()
        }
    };
    ReactDOM.render(<Dialog onHide={unmountModal} header='Meddelande' message={msg}
                            closeButtonText='OK'/>, renderContainer);
}

export function infoAlert(msg, callback) {
    const renderContainer = document.createElement('div');
    document.body.appendChild(renderContainer);

    const unmountModal = () => {
        ReactDOM.unmountComponentAtNode(renderContainer);
        renderContainer.parentNode.removeChild(renderContainer);

        if (callback) {
            callback()
        }
    };
    ReactDOM.render(<Dialog onHide={unmountModal} header='Meddelande' message={msg}
                            closeButtonText='OK'/>, renderContainer);
}