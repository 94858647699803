import React from 'react';
import {Dialog} from "primereact/dialog";
import {useForm} from "react-hook-form";
import ErrorText from "../../../components/EditForm/ErrorText";
import ValidateUtils from "../../../utils/ValidateUtils";
import moment from "moment";
import FinancingService from "../FinancingService";

const FinancingPaymentEditFormDialog = props => {
    const model = Object.assign({}, props.model);
    const disabled = false;

    if (!model.paymentDateString) {
        model.paymentDateString = moment().format('yyyy-MM-DD');
        model.paymentExchangeRate = 1;
    }

    const {register, errors, handleSubmit, formState} = useForm({
        defaultValues: model
    });
    const {isSubmitting} = formState;

    const onSubmit = data => {
        const modelToSave = {
            financing: model.financingId,
            period: model.index,
            date: data.paymentDateString,
            exchangeRate: Number(data.paymentExchangeRate),
        }
        FinancingService.updatePayment(modelToSave).then(_res => {
            props.onChange();
        });
    }

    return <Dialog className='dialog'
                   header="Betalning"
                   visible={props.visible}
                   onHide={props.onHide}>

        <div className="EditForm">
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="formItem">
                    <label htmlFor='paymentDateString'>Datum</label>
                    <input type="date" name="paymentDateString"
                           ref={register({
                               pattern: ValidateUtils.datePattern()
                           })}
                           disabled={disabled}
                    />
                    <ErrorText errors={errors} name="paymentDateString"/>
                </div>

                <div className="formItem">
                    <label htmlFor='paymentExchangeRate'>Valutakurs</label>
                    <input type='number' step='any' name="paymentExchangeRate"
                           ref={register({
                               pattern: ValidateUtils.numberPattern()
                           })}
                           style={{width: '100px'}}
                           disabled={disabled}
                    />
                    <ErrorText errors={errors} name="paymentExchangeRate"/>
                </div>

                <div className="ui-dialog-buttonpane p-clearfix button-row">
                    <div className='left-buttons'></div>
                    <div className='divider'></div>

                    <div className='right-buttons'>
                        {!disabled && <input type='submit' value='Spara' disabled={isSubmitting} className='save-btn'/>}
                        <input type='button' value='Avbryt' onClick={e => props.onHide()}/>
                    </div>
                </div>
            </form>
        </div>

    </Dialog>
}

export default FinancingPaymentEditFormDialog;
