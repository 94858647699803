import moment from "moment";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {DataTable} from "primereact/datatable";
import {InputText} from "primereact/inputtext";
import {Row} from "primereact/row";
import React, {useState} from 'react';
import {GridTemplates} from "../../../GridTemplates";
import ArrayUtils from "../../../utils/ArrayUtils";
import NetUtils from "../../../utils/NetUtils";
import {NumberUtils} from "../../../utils/NumberUtils";
import DivestmentAddFromAcquisitionsDialog from "./DivestmentAddFromAcquisitionsDialog";
import DivestmentAddFromArticlesDialog from "./DivestmentAddFromArticlesDialog";

const DivestmentrowGrid = props => {
    const divestmentDate = props.divestmentDate;
    const rowList = props.rows;
    const [showAddFromArticlesDialog, setShowAddFromArticlesDialog] = useState(false);
    const [showAddFromAcquisitionsDialog, setShowAddFromAcquisitionsDialog] = useState(false);

    const acquisitiontype = props.acquisitiontype;
    const discount = props.discount;

    // acquisitionPrice: 18823.781212944
    // acquisitionString: "Berger Tak"
    // acquisitionrow: 9292
    // aprice: 552
    // article: 10723
    // articleNumber: "U-16073072"
    // articleString: "UBIX-Diagonalstag 0,78x3,07m"
    // depreciationValue: 0
    // divestment: 10145
    // id: 9925
    // listOrder: 4
    // price: 49128
    // quantity: 89

    rowList.forEach(r => {
        // const a = articleList.find(a => a.id === r.article);
        r.numberAndName = r.articleNumber + ' - ' + r.articleString;

        r.price = r.quantity * r.aprice;
        r.priceSek = r.price;
        r.discountPriceSek = discount / 100 * r.priceSek;
        r.netPriceSek = r.priceSek - r.discountPriceSek;
    })

    const sumByField = field => rowList.reduce((sum, r) => sum + r[field], 0)

    const totalPrice = sumByField('price');
    const totalPriceSek = totalPrice;
    const totalDiscountPriceSek = sumByField('discountPriceSek');
    const totalNetPriceSek = sumByField('netPriceSek');

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={'Totalt (' + rowList.length + ' st)'} style={{textAlign: 'left'}}/>
            <Column footer=''/>
            <Column footer={NumberUtils.formatTwoDecimals(ArrayUtils.sum(rowList, 'acquisitionPrice'))}
                    style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatTwoDecimals(ArrayUtils.sum(rowList, 'depreciationValue'))}
                    style={{textAlign: 'right'}}/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer={NumberUtils.formatTwoDecimals(ArrayUtils.sum(rowList, 'price'))}
                    style={{textAlign: 'right'}}/>
            <Column footer=''/>
        </Row>
    </ColumnGroup>;

    const onPropertyChange = (r, field, value) => {
        r[field] = value;
        props.onSave([...rowList]);
    }

    const onRemoveClick = row => {
        const index = rowList.indexOf(row);
        rowList.splice(index, 1)
        props.onSave([...rowList]);
    }

    const columns = [
        {field: 'numberAndName', header: 'Artikel'},
        {field: 'acquisitionString', header: 'Anskaffning', style: {width: '300px'}},
        {
            field: 'acquisitionDate', header: 'Ansk.datum', style: {width: '90px'},
            body: row => {
                const isErrorDate = moment(divestmentDate).isBefore(moment(row.acquisitionDate))
                const style = {
                    color: isErrorDate ? 'red' : 'black'
                }
                const title = isErrorDate ? 'Anskaffningsdatumet är efter avyttringsdatumet. Det blir problem...' : ''

                return <span style={style} title={title}>{row.acquisitionDate}</span>
            },
        },
        {
            field: 'acquisitionPrice',
            header: 'Anskaffningspris',
            body: GridTemplates.numberTwoDecimals,
            style: {width: '110px', textAlign: 'right'}
        },
        {
            field: 'depreciationValue',
            header: 'Ack.avskrivningar',
            body: GridTemplates.numberTwoDecimals,
            style: {width: '110px', textAlign: 'right'}
        },
        {
            field: 'quantity', header: 'Antal',
            body: GridTemplates.numberTwoDecimals,
            style: {width: '80px', textAlign: 'right'}
        }, {
            field: 'aprice', header: 'À-pris',
            body: GridTemplates.numberTwoDecimals,
            style: {width: '110px', textAlign: 'right'},
            editor: e => <InputText type="number"
                                    value={e.rowData.aprice !== 0 ? e.rowData.aprice : ''}
                                    onChange={e2 => onPropertyChange(e.rowData, 'aprice', e2.target.value)}
                                    style={{textAlign: 'right'}}/>
        }, {
            field: 'price', header: 'Bruttopris',
            body: GridTemplates.numberTwoDecimals,
            style: {width: '110px', textAlign: 'right'}
        }, {
            field: 'remove', header: '',
            body: row => <button className='icon-btn'
                                 onClick={e => onRemoveClick(row)}>
                <i className="pi pi-times"></i>
            </button>,
            style: {width: '50px'}
        }
    ];

    return props.model &&
        <div className='CalcrowGrid'>
            <DataTable value={rowList}
                       footerColumnGroup={footerGroup}
                       style={{width: '1300px'}}>
                {
                    columns.map(c => <Column key={c.field}
                                             field={c.field}
                                             header={c.header}
                                             style={c.style}
                                             className={c.className}
                                             body={c.body}
                                             editor={c.editor}
                                             sortable={false}
                    />)
                }
            </DataTable>

            <div style={{display: 'flex', width: '1100px', marginTop: '7px'}}>
                <div>
                    <input type='button' className='add-btn' value='Lägg till från artiklar'
                           onClick={e => setShowAddFromArticlesDialog(true)}/>
                </div>
                <div>
                    <input type='button' className='add-btn' value='Lägg till från anskaffningar'
                           onClick={e => setShowAddFromAcquisitionsDialog(true)}/>
                </div>
                <div style={{flexGrow: '1'}}></div>

                <button className='excel-icon-btn'
                        onClick={() => NetUtils.exportToExcel('Avyttringsrader', columns, rowList)}/>

                <div style={{textAlign: 'right'}}>
                    <table className='sum-table'>
                        <tbody>
                        <tr>
                            <td>Bruttopris:</td>
                            <td>{NumberUtils.formatCurrency(totalPriceSek)}</td>
                        </tr>
                        <tr>
                            <td>Rabatt:</td>
                            <td>{NumberUtils.formatCurrency(totalDiscountPriceSek)}</td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: 'bold'}}>Nettopris::</td>
                            <td style={{
                                fontWeight: 'bold',
                                paddingLeft: '50px'
                            }}>{NumberUtils.formatCurrency(totalNetPriceSek)}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            {
                showAddFromArticlesDialog &&
                <DivestmentAddFromArticlesDialog visible={showAddFromArticlesDialog}
                                                 acquisitiontype={acquisitiontype}
                                                 onSave={addList => {
                                                     props.onSave([...rowList].concat(addList));
                                                     setShowAddFromArticlesDialog(false)
                                                 }}
                                                 onHide={() => {
                                                     setShowAddFromArticlesDialog(false)
                                                 }}
                />
            }
            {
                showAddFromAcquisitionsDialog &&
                <DivestmentAddFromAcquisitionsDialog visible={showAddFromAcquisitionsDialog}
                                                     acquisitiontype={acquisitiontype}
                                                     onSave={addList => {
                                                         props.onSave([...rowList].concat(addList));
                                                         setShowAddFromAcquisitionsDialog(false)
                                                     }}
                                                     onHide={() => {
                                                         setShowAddFromAcquisitionsDialog(false)
                                                     }}
                />
            }
        </div>

}
export default DivestmentrowGrid;