import NetUtils from "../../utils/NetUtils";

export class UserService {

    static login = params => NetUtils.doPostWithParams('/Login', params);

    static async findAll() {
        return await fetch('/api/users').then(res => res.json());
    };

    static async findById(id) {
        return await fetch('/api/users/' + id).then(res => res.json());
    };

    static async getCurrentUser() {
        return await fetch('/api/users/current', {
            method: 'GET',
        }).then(res => res.json());
    }

    static getRoles = () => NetUtils.doGet('/api/users/roles');
    static findRolesByUser = id => NetUtils.doGet('/api/users/' + id + '/roles');
    static updatedRoles = async (userId, roleList) => NetUtils.doPost('/api/users/roles/' + userId, roleList);

    static addCollaborator = async model => NetUtils.doPost('/api/users/collaborators', model);
    static removeCollaborator = async model => NetUtils.doDelete('/api/users/collaborators', [model]);

    static getCurrentlyOnline = () => NetUtils.doGet('/api/users/currently-online');
}