import Dashboard from './Dashboard.js';
import Role from "../Users/Role";

export default {
    routeProps: {
        path: '/dashboard',
        exact: true,
        component: Dashboard,
    },
    name: 'Översikt',
    icon: 'pi pi-fw pi-chart-line',
    roles: [Role.DASHBOARD_READ]
};