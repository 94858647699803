import CustomerGrid from "./CustomerGrid";
import CustomerEditForm from "./CustomerEditForm";

export default {
    routeProps: {
        path: '/customers',
        exact: true,
        component: CustomerGrid,
    },
    name: 'Kunder',
    nameSingular: 'Kund',
    showHeadline: true,
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/customers/:id',
            exact: true,
            component: CustomerEditForm,
        }
    }],
};