import ArticlecategoryGrid from "./ArticlecategoryGrid";
import ArticlecategoryEditForm from "./ArticlecategoryEditForm";
import Role from "../Users/Role";

export default {
    routeProps: {
        path: '/articlecategories',
        exact: true,
        component: ArticlecategoryGrid,
    },
    name: 'Artikelkategorier',
    nameSingular: 'Artikelkategori',
    showHeadline: true,
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/articlecategories/:id',
            exact: true,
            component: ArticlecategoryEditForm,
        }
    }],
    roles: [Role.ARTICLECATEGORY_READ]
};