import {Menu} from "primereact/menu";
import {Button} from "primereact/button";
import React, {useRef} from "react";


const ExtraMenu = props => {
    const menuRef = useRef();
    const items = [];

    items.push({
        label: 'Exportera till Excel',
        icon: 'pi pi-fw pi-download',
        command: props.onExportToExcel
    })

    return <div><Menu model={items}
                      popup={true} ref={el => menuRef.current = el} id="popup_menu"
                      style={{width: '200px'}}
                      appendTo={document.getElementsByTagName('body')[0]}/>
        <Button type="button" icon="pi pi-ellipsis-v"
                className="p-button-success"
                onClick={e => menuRef.current.toggle(e)}
                aria-controls="popup_menu" aria-haspopup={true}></Button>
    </div>
}

export default ExtraMenu;