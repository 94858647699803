import React, {useState, useEffect, useContext} from 'react';
import {GridTemplates} from "../../GridTemplates";
import {DataTable} from "primereact/datatable";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Column} from "primereact/column";
import {ActiveFilter} from "../../components/filters/ActiveFilter";
import {InputText} from "primereact/inputtext";
import AppContext from "../../AppContext";
import {Dropdown} from "primereact/dropdown";
import SearchUtils from "../../utils/SearchUtils";
import NetUtils from "../../utils/NetUtils";
import ExtraMenu from "../../components/Grid/ExtraMenu";
import RouterUtils from "../../utils/RouterUtils";
import {Button} from "primereact/button";
import {dynamicSort} from "../../utils/SortUtils";
import {NumberUtils} from "../../utils/NumberUtils";
import AcquisitionService from "./AcquisitionService";
import moment from "moment";
import AcquisitiontypeService from "../Acquisitiontypes/AcquisitiontypeService";
import FinancingService from "../Financing/FinancingService";
import OptionalDate from "../../components/OptionalDate/OptionalDate";

const BOUGHT_ID = -999;
const CASH_ID = 10000;
const LOAN_ID = 10001;
const INSTALMENT_ID = 10002;
const INVENTORY_ADJUSTMENT_ID = 10006;
const BOUGHT_ID_ARRAY = [CASH_ID, LOAN_ID, INSTALMENT_ID, INVENTORY_ADJUSTMENT_ID];

const AcquisitionGrid = props => {
    const NAME = 'AcquisitionGrid'
    const context = useContext(AppContext);
    const [filter, setFilter] = useState({
        active: context.getFilter(NAME, 'active', 1),
        type: context.getFilter(NAME, 'type', undefined),
        financingtype: context.getFilter(NAME, 'financingtype', undefined),
        startDate: context.getFilter(NAME, 'startDate', ''),
        endDate: context.getFilter(NAME, 'endDate', ''),
        search: context.getFilter(NAME, 'search', '')
    });
    const [list, setList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [financingtypeList, setFinancingtypeList] = useState([]);

    const matchActive = a => filter.active === null || a.active === filter.active;
    const matchType = a => !filter.type || a.type === filter.type;


    const matchFinancingtype = a => filter.financingtype === BOUGHT_ID
        ? BOUGHT_ID_ARRAY.includes(a.financing)
        : !filter.financingtype || a.financing === filter.financingtype;

    const matchStartDate = a => filter.startDate.length === 0 || a.acquisitionDate >= moment(filter.startDate).valueOf();
    const matchEndDate = a => filter.endDate.length === 0 || a.acquisitionDate <= moment(filter.endDate).valueOf();


    const matchSearch = a => SearchUtils.isMatching(a, filter.search);

    const filteredList = list.filter(a => matchActive(a) &&
        matchType(a) && matchFinancingtype(a) &&
        matchStartDate(a) && matchEndDate(a) &&
        matchSearch(a))

    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(filter);
    }

    const onRowSelect = model => RouterUtils.navigateTo(props, '/acquisitions/' + model.id);

    const sumByField = field => filteredList.reduce((sum, a) => sum += !isNaN(a[field]) ? a[field] : 0, 0);
    const totalPrice = sumByField('price');
    const totalListPriceValue = sumByField('listPriceValue');
    const totalMarketValue = sumByField('marketValue');
    const totalBookkeepingValue = sumByField('bookkeepingValue');
    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={'Totalt (' + filteredList.length + ' st)'}
                    style={{textAlign: 'left'}}/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer=''/>
            <Column footer={NumberUtils.formatCurrency(totalPrice)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalListPriceValue)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalMarketValue)} style={{textAlign: 'right'}}/>
            <Column footer={NumberUtils.formatCurrency(totalBookkeepingValue)} style={{textAlign: 'right'}}/>
        </Row>
    </ColumnGroup>;


    useEffect(() => {
        Promise.all([
            AcquisitionService.findByCompany(context.currentCompany.id),
            AcquisitiontypeService.findByCompany(context.currentCompany.id),
            FinancingService.getTypes()
        ]).then(([_list, _typeList, _financingtypeList]) => {
            _list.forEach(a => {
                a.priceSek = a.price * a.startExchangeRate;
                a.acquisitionDateString = moment(a.acquisitionDate).format('YYYY-MM-DD');
            })
            _list.sort(dynamicSort('name'))
            setList(_list)

            _typeList.sort(dynamicSort('name'));
            setTypeList(_typeList.filter(t => t.active === 1));


            _financingtypeList.sort(dynamicSort('nameTranslated'));
            setFinancingtypeList([{id: BOUGHT_ID, nameTranslated: 'Köpt'}, ..._financingtypeList]);
        });
    }, [context.currentCompany.id])


    const columns = [
        {field: 'name', header: 'Namn', className: 'nowrap'},
        {field: 'typeString', header: 'Typ', className: 'nowrap'},
        {field: 'supplierString', header: 'Leverantör', className: 'nowrap'},
        {field: 'financingString', header: 'Finansiering', className: 'nowrap'},
        {field: 'acquisitionDateString', header: 'Datum', style: {width: '90px'}},
        {field: 'accountNumber', header: 'Konto', style: {width: '80px'}},
        {field: 'bookkeepingId', header: 'Bokförings-ID', style: {width: '130px'}, className: 'nowrap'},
        {
            field: 'monthDepreciationValue',
            header: 'Avskriv.tid',
            style: {width: '100px', textAlign: 'right'},
            body: GridTemplates.integer
        }, {
            field: 'basePrice',
            header: 'Urprungspris',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.currency
        }, {
            field: 'price',
            header: 'Pris',
            style: {width: '100px', textAlign: 'right'},
            body: GridTemplates.currency
        }, {
            field: 'listPriceValue',
            header: 'Listpris',
            style: {width: '100px', textAlign: 'right'},
            body: GridTemplates.currency
        }, {
            field: 'marketValue',
            header: 'Marknadsvärde',
            style: {width: '130px', textAlign: 'right'},
            body: GridTemplates.currency
        }, {
            field: 'bookkeepingValue',
            header: 'Bokfört värde',
            style: {width: '120px', textAlign: 'right'},
            body: GridTemplates.currency
        }
    ];

    return <div className="Grid">

        <div className="filter">
            <div>
                <label className="label">Status</label>
                <Dropdown
                    value={filter.active}
                    options={ActiveFilter.statusList}
                    onChange={e => updateFilter('active', e.value)}
                    showClear={true}
                    placeholder='Alla'
                    style={{width: '90px'}}/>
            </div>
            <div>
                <label className="label">Typ</label>
                <Dropdown
                    value={filter.type}
                    options={typeList}
                    onChange={e => updateFilter('type', e.value)}
                    optionValue='id'
                    optionLabel='name'
                    showClear={true}
                    placeholder='Alla'
                    style={{width: '200px'}}/>
            </div>
            <div>
                <label className="label">Finansiering</label>
                <Dropdown
                    value={filter.financingtype}
                    options={financingtypeList}
                    onChange={e => updateFilter('financingtype', e.value)}
                    optionValue='id'
                    optionLabel='nameTranslated'
                    showClear={true}
                    placeholder='Alla'
                    style={{width: '130px'}}/>
            </div>

            <div>
                <label className="label">Intervall</label>
                <div style={{display: 'flex'}}>
                    <OptionalDate
                        value={filter.startDate}
                        onChange={date => updateFilter('startDate', date)}/>
                    <div style={{width: '10px'}}></div>
                    <OptionalDate
                        value={filter.endDate}
                        onChange={date => updateFilter('endDate', date)}/>
                </div>
            </div>

            <div style={{marginLeft: '50px'}}>
                <label className="label">&nbsp;</label>
                <Button label='Lägg till anskaffning'
                        icon="pi pi-plus"
                        onClick={() => RouterUtils.navigateTo(props, '/acquisitions/0')}/>
            </div>


            <div style={{flexGrow: 1, padding: 0}}></div>

            <div>
                <label className="label">Sök</label>
                <InputText className="search" type="search" defaultValue={filter.search}
                           onInput={(e) => updateFilter('search', e.target.value)}/>
            </div>

            <div style={{paddingRight: 0}}>
                <label className="label">&nbsp;</label>
                <ExtraMenu list={filteredList}
                           onExportToExcel={() => NetUtils.exportToExcel('Anskaffningar', columns, filteredList)}/>
            </div>
        </div>

        <DataTable className="dataTable"
                   value={filteredList}
                   resizableColumns={true}
                   columnResizeMode="expand"
                   paginator={false}
                   footerColumnGroup={footerGroup}
                   selectionMode="single"
                   onRowSelect={e => onRowSelect(e.data)}
                   scrollable={true} scrollHeight="calc(100vh - 240px)"
        >
            {
                columns.map(c => <Column key={c.field}
                                         field={c.field}
                                         header={c.header}
                                         style={c.style}
                                         className={c.className}
                                         body={c.body}
                                         sortable={true}
                />)
            }
        </DataTable>
    </div>
}

export default AcquisitionGrid;
