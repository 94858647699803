import React, {useContext} from 'react';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import './CompanySwitch.scss';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import AppContext from "../../AppContext";

const CompanySwitch = props => {
    const context = useContext(AppContext);

    const switchRepo = repo => {
        localStorage.setItem('company', repo.id);
        window.location = '/';
    }

    let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="Avbryt" icon="pi pi-times" className="p-button-secondary" onClick={() => props.onHide()}/>
    </div>;

    return (
        <div className='CompanySwitch'>
            <Dialog visible={props.displayDialog} responsive={true} header="Byt företag" modal={false}
                    footer={dialogFooter} onHide={() => props.onHide()} maximizable={false}>
                {
                    <div>
                        <DataTable value={context.companies} style={{width: '100%'}}
                                   selectionMode="single"
                                   onSelectionChange={e => switchRepo(e.value)}>

                            <Column field="name" header="Välj företag..." className='alignLeft'/>
                        </DataTable>
                    </div>
                }
            </Dialog>
        </div>
    );
}
export default CompanySwitch;