import React from 'react'

const AppContext = React.createContext({
    user: {
        email: '',
    },
    companies: {
        id: 0,
        name: '',
        address: '',
        zip: 0,
        city: '',
        vatNumber: '',
        active: true
    },

    currentCompany: undefined,

    signIn: (companies) => {
    },

    setFilter: (gridName, name, value) => {
    },
    getFilter: (gridName, name, defaultValue) => {
    },
})

export default AppContext