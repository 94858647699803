import moment from "moment";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React, {useContext, useState} from 'react';
import AppContext from "../../../AppContext";
import {GridTemplates} from "../../../GridTemplates";
import ArticlepriceEditFormDialog from "./ArticlepriceEditFormDialog";

const ArticlepriceGrid = props => {
    const context = useContext(AppContext)
    const [modelToEdit, setModelToEdit] = useState();

    const isEditor = ['petra@nobius.se', 'jonas@xlnz.tech'].includes(context.user.email)

    return <div>
        <DataTable value={props.value} style={{width: '250px'}}
                   selectionMode={isEditor ? "single" : ""}
                   onSelectionChange={e => isEditor ? setModelToEdit(e.value) : undefined}>

            <Column field="startTime" header="Startdatum" className='alignLeft'
                    body={row => moment(row.startTime).format('yyyy-MM-DD')}/>

            <Column field="value" header="Belopp"
                    headerClassName='alignRight'
                    className='alignRight'
                    style={{width: '120px'}}
                    body={GridTemplates.currencyTwoDecimals}
            />
        </DataTable>

        {
            isEditor &&
            <Button icon="pi pi-plus" className="p-button-secondary" onClick={e => {
                e.preventDefault();
                setModelToEdit({
                    article: props.parentId ? props.parentId : 0,
                    startDate: moment().format('YYYY-MM-DD'),
                    value: 0,
                    newPrice: true
                });
            }}/>
        }

        {
            modelToEdit &&
            <ArticlepriceEditFormDialog visible={modelToEdit !== undefined}
                                        model={modelToEdit}
                                        onSave={savedModel => {
                                            console.log("modelToEditmodelToEdit", modelToEdit)
                                            if (modelToEdit.newPrice) {
                                                delete modelToEdit.newPrice;
                                                let newList = [...props.value];
                                                newList.push(savedModel);
                                                props.onChange(newList);
                                            }
                                            setModelToEdit(undefined);
                                        }}
                                        onRemove={removeModel => {
                                            let newList = [...props.value]
                                            newList.splice(
                                                props.value.findIndex(d => d.id === removeModel.id),
                                                1
                                            );
                                            props.onChange(newList);

                                            setModelToEdit(undefined);
                                        }}
                                        onHide={() => setModelToEdit(undefined)}/>
        }
    </div>
}

export default ArticlepriceGrid;
