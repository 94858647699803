import React from 'react';
import './Dashboard.scss'
import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

const RentedTable = props => {
    const value = props.value;

    return <Card className='assetfinancingDistributionCard' style={{width: '1200px'}}>
        <div className="Grid">
            <h1>Uthyrning</h1>
            <DataTable className="dataTable"
                       value={value.tableRow}
                       resizableColumns={true}
                       columnResizeMode="expand"
                       paginator={false}
            >
                <Column field='name' header='Namn' body={row => row.name + '\u00A0'}/>

                {
                    value.tableColumn.map((col, i) => <Column key={i} field='price' header={col.name}
                                                              body={row => {
                                                                  return row.value[i]
                                                              }}
                                                              style={{textAlign: 'right', width: '120px'}}/>)
                }
            </DataTable>
        </div>
    </Card>
}

export default RentedTable;
