import React, {useContext, useState, useEffect} from 'react';
import './Dashboard.scss'
import DashboardService from "./DashboardService";
import Spinner from "../../components/Spinner/Spinner";
import AssetfinancingDistribution from "./AssetfinancingDistribution";
import AppContext from "../../AppContext";
import moment from "moment";
import RentedTable from "./RentedTable";
import AssetfinancingDistributionFinancier from "./AssetfinancingDistributionFinancier";

const Dashboard = () => {
    const NAME = 'Dashboard'
    const context = useContext(AppContext);
    const [filter, setFilter] = useState({
        yearMonth: context.getFilter(NAME, 'yearMonth', moment().format('YYYY-MM')),
        includeCompanies: context.getFilter(NAME, 'includeCompanies', 0)
    });
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();

    const updateFilter = (name, value) => {
        context.setFilter(NAME, name, value)
        filter[name] = value;
        setFilter(Object.assign({}, filter));
    }


    useEffect(() => {
        setLoading(true);
        DashboardService.get({
            year: filter.yearMonth.split('-')[0],
            month: Number(filter.yearMonth.split('-')[1]) - 1,
            includeCompanies: filter.includeCompanies
        }).then(response => {
            setData(response);
            setLoading(false);
        })
    }, [filter.yearMonth, filter.includeCompanies])

    if (loading) return <Spinner/>
    return <div className="Dashboard">
        <div className="Grid">
            <div className="filter">
                <div>
                    <label className="label">Månad</label>
                    <input type="month" value={filter.yearMonth}
                           onChange={e => updateFilter('yearMonth', e.target.value)}/>
                </div>
                {
                    context.currentCompany.rental === 1 &&
                    <div>
                        <label className="label">Företag</label>
                        <select value={filter.includeCompanies}
                                onChange={e => updateFilter('includeCompanies', e.target.value)}>
                            <option value={0}>{context.currentCompany.name}</option>
                            <option value={1}>Hela gruppen</option>
                        </select>
                    </div>
                }
            </div>
        </div>

        <div className='cardContainer'>
            {
                data.assetfinancingDistribution &&
                <AssetfinancingDistribution yearMonth={filter.yearMonth} value={data.assetfinancingDistribution}/>
            }

            {
                context.currentCompany.rental === 1 &&
                <RentedTable value={data.rentedTable.table}/>
            }

            {
                data.assetfinancingDistributionFinancier &&
                <AssetfinancingDistributionFinancier value={data.assetfinancingDistributionFinancier}/>
            }

        </div>

    </div>
}

export default Dashboard;
