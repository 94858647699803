import React from 'react';
import './Dashboard.scss'
import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {GridTemplates} from "../../GridTemplates";

const AssetfinancingDistributionFinancier = props => {
    let value = props.value;
    if(!value.length) {
        value = [props.value];
    }

    return <Card style={{width: '550px'}}>
        <div className="Grid">
            <h1>Finansiärer</h1>
            <DataTable className="dataTable"
                       value={value}
                       resizableColumns={true}
                       columnResizeMode="expand"
                       paginator={false}
            >
                <Column field='financierString' header='Finansiär'/>
                <Column field='debt' header='Restskuld' body={GridTemplates.currency}
                        style={{textAlign: 'right', width: '110px'}}/>
                <Column field='paymentCurrentMonth' header='Amorterat denna månad' body={GridTemplates.currency}
                        style={{textAlign: 'right', width: '110px'}}/>
            </DataTable>
        </div>
    </Card>
}

export default AssetfinancingDistributionFinancier;
